import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    Object.defineProperty(AuthService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.register = function (form) {
        return this.http.post('register', form);
    };
    AuthService.prototype.login = function (form) {
        var _this = this;
        return this.http.post('login', form)
            .map(function (response) {
            localStorage.setItem('token', response.token);
            localStorage.setItem('currentUser', JSON.stringify(response.user));
            _this.currentUserSubject.next(response.user);
            return response;
        });
    };
    AuthService.prototype.getUser = function () {
        var _this = this;
        return this.http.get('user')
            .map(function (response) {
            localStorage.setItem('currentUser', JSON.stringify(response));
            _this.currentUserSubject.next(response);
            return response;
        });
    };
    AuthService.prototype.forgotPassword = function (form) {
        return this.http.post('password/email', form);
    };
    AuthService.prototype.logout = function () {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentTenant');
        this.currentUserSubject.next(null);
    };
    AuthService.prototype.isAuthenticated = function () {
        // here you can check if user is authenticated or not through his token 
        return true;
    };
    AuthService.prototype.activate = function (token) {
        return this.http.get('activate/' + token);
    };
    AuthService.prototype.resetPassword = function (form) {
        return this.http.post('reset-password', form);
    };
    AuthService.prototype.setUser = function (json) {
        localStorage.setItem('currentUser', JSON.stringify(json));
        this.currentUserSubject.next(json);
    };
    AuthService.prototype.updatePassword = function (form) {
        return this.http.post('user-password-update', form);
    };
    AuthService.prototype.delete = function () {
        return this.http.post('user/' + this.currentUserValue.id, {
            _method: "DELETE"
        });
    };
    AuthService.prototype.firstTimeChange = function (form) {
        var _this = this;
        return this.http.post('user/custom/change/password', form)
            .map(function (response) {
            localStorage.setItem('currentUser', JSON.stringify(response));
            _this.currentUserSubject.next(response);
            return response;
        });
    };
    AuthService.prototype.getUserByToken = function (token) {
        return this.http.post('temp-token', {
            token: token
        })
            .map(function (response) {
            return response;
        });
    };
    AuthService.prototype.magicLink = function (form) {
        var _this = this;
        return this.http.post('user/custom/password/update', form)
            .map(function (response) {
            localStorage.setItem('currentUser', JSON.stringify(response));
            _this.currentUserSubject.next(response);
            return response;
        });
    };
    return AuthService;
}());
export { AuthService };
