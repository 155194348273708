import { PasswordGuard } from './_guards/password.guard';
import { NoPermissionComponent } from './shared/no-permission/no-permission.component';
import { AuthGuard } from './_guards/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { ProductionGuard } from './_guards/production.guard';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'setting',
    loadChildren: './setting/setting.module#SettingModule',
    canActivate: [AuthGuard, PasswordGuard]
  },
  { path: '', component: FullLayoutComponent, data: { title: '' }, children: Full_ROUTES, canActivate: [AuthGuard, PasswordGuard, ProductionGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: '' }, children: CONTENT_ROUTES, canActivate: [AuthGuard, PasswordGuard] },
  {
    path: 'no-permission',
    component: NoPermissionComponent
  },
  {
    path: '404',
    component: PageNotFoundComponent
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
