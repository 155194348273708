import { EventEmitter } from '@angular/core';
import { CharacterModel } from './../../../_models/character.model';
import { CharacterService } from './../../../_services/character.service';
import { QueueService } from '../../../_services/queue.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SceneModel } from 'app/_models/scene.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-item-character',
  templateUrl: './assign-item-character.component.html',
  styleUrls: ['./assign-item-character.component.scss']
})
export class AssignItemCharacterComponent implements OnInit {
  public form: FormGroup;
  public formSubmitLoader: boolean;
  public characters: CharacterModel[];
  public scenes: SceneModel[];
  @Output() recordAdded = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public queueService: QueueService,
    public characterService: CharacterService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.form = this.fb.group({
      character: [null, [Validators.required]],
      scenes: [null, [Validators.required]],
      items: this.fb.array([])
    });

    this.characterService.getAllRecords()
      .subscribe(
        result => {
          this.characters = result;
        }
      );
  }

  characterSelected() {
    this.scenes = new Array();

    if (this.form.value.character) {
      this.characterService.getScenes(this.form.value.character)
        .subscribe(
          result => {
            this.scenes = result;
          }
        );
    }
  }

  onSubmit() {
    this.formSubmitLoader = true;
    let form = this.form.value;
    form.items = [...this.queueService.items];

    this.characterService.assignHeroProps(this.form.value.character, form)
      .subscribe(
        result => {
          const character = this.characters.find(item => item.id == this.form.value.character);
          let scene = "";
          for (const sceneId of this.form.value.scenes) {
            const _scene = this.scenes.find(item => item.id == sceneId);
            scene = scene + _scene.display_text + " ";
          }

          this.toastr.success("Success", "Items have been assigned to Character [" + character.display_text + "/" + scene + "]");
          this.recordAdded.emit();
          this.activeModal.close();
        },
        error => {
          this.formSubmitLoader = false;
        }
      );
  }
}
