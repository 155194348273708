/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./FileList.ngfactory";
import * as i2 from "./FileList";
import * as i3 from "../Services/FileStore.service";
import * as i4 from "@angular/common";
import * as i5 from "./FileDropZone.ngfactory";
import * as i6 from "../Services/FileParser.service";
import * as i7 from "./FileDropZone";
import * as i8 from "../Services/FileUpload.service";
import * as i9 from "./FileDroppa";
var styles_FileDroppa = ["\n        .file-droppa-container {\n            width: 400px;\n        }\n        .file-droppa-btns {\n         display: flex;\n          align-items: center;\n          justify-content: center;\n\n        }\n        .file-droppa-btn {\n              margin: 15px;\n              padding: 0;\n\n              overflow: hidden;\n\n              border-width: 0;\n              outline: none;\n              border-radius: 2px;\n              box-shadow: 0 1px 4px rgba(0, 0, 0, .6);\n\n              background-color: #2ecc71;\n              color: #ecf0f1;\n\n              transition: background-color .3s;\n              width: 140px;\n              text-align: center;\n              font-size: 12px;\n\n        }\n\n        .file-droppa-btn:hover{\n          background-color: #27ae60;\n        }\n\n        .file-droppa-btn span {\n          display: block;\n          padding: 12px 24px;\n        }\n\n        .file-droppa-btn.orange {\n          background-color: #e67e22;\n        }\n\n        .file-droppa-btn.orange:hover {\n          background-color: #d35400;\n        }\n\n        .file-droppa-btn.red {\n          background-color: #e74c3c;\n        }\n\n        .file-droppa-btn.red:hover{\n          background-color: #c0392b;\n        }\n        "];
var RenderType_FileDroppa = i0.ɵcrt({ encapsulation: 2, styles: styles_FileDroppa, data: {} });
export { RenderType_FileDroppa as RenderType_FileDroppa };
function View_FileDroppa_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fileList", [], null, null, null, i1.View_FileList_0, i1.RenderType_FileList)), i0.ɵdid(1, 49152, null, 0, i2.FileList, [i3.FilesStore], null, null)], null, null); }
function View_FileDroppa_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadAllFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadButtonTemplate; _ck(_v, 0, 0, currVal_0); }); }
function View_FileDroppa_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAllFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.removeButtonTemplate; _ck(_v, 0, 0, currVal_0); }); }
function View_FileDroppa_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "file-droppa-btns"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["uploadButtonArea", 1]], null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadAllFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileDroppa_3)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, [["removeButtonArea", 1]], null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAllFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileDroppa_4)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).children.length === 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = (i0.ɵnov(_v, 5).children.length === 0); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_FileDroppa_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "file-droppa-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "fileDropZone", [], null, [[null, "drop"], [null, "dragenter"], [null, "dragover"], [null, "dragleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("drop" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).drop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).dragenter($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).dragover($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).dragleave($event) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i5.View_FileDropZone_0, i5.RenderType_FileDropZone)), i0.ɵprd(512, null, i6.FileParser, i6.FileParser, []), i0.ɵdid(3, 49152, null, 0, i7.FileDropZone, [i3.FilesStore, i0.ElementRef, i6.FileParser], { multiple: [0, "multiple"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileDroppa_1)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileDroppa_2)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multiple; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.showFilesList; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.filesStore.iFiles.length; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dropZoneTemplate; _ck(_v, 4, 0, currVal_1); }); }
export function View_FileDroppa_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "fileDroppa", [], null, null, null, View_FileDroppa_0, RenderType_FileDroppa)), i0.ɵprd(512, null, i3.FilesStore, i3.FilesStore, []), i0.ɵprd(512, null, i8.FileUpload, i8.FileUpload, []), i0.ɵdid(3, 114688, null, 0, i9.FileDroppa, [i3.FilesStore, i8.FileUpload], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var FileDroppaNgFactory = i0.ɵccf("fileDroppa", i9.FileDroppa, View_FileDroppa_Host_0, { showFilesList: "showFilesList", autoUpload: "autoUpload", beforeRequest: "beforeRequest", url: "url", beforeFileUpload: "beforeFileUpload", beforeAddFile: "beforeAddFile", dropZoneTemplate: "dropZoneTemplate", uploadButtonTemplate: "uploadButtonTemplate", removeButtonTemplate: "removeButtonTemplate", multiple: "multiple" }, { filesUpdated: "filesUpdated", fileUploaded: "fileUploaded" }, ["*", "[upload-button]", "[remove-button]"]);
export { FileDroppaNgFactory as FileDroppaNgFactory };
