var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AttachmentModel } from 'app/_models/attachment.model';
import { GalleryModel } from './gallery.model';
import { BaseModel } from "./base.model";
import { SetSceneModel } from './set-scene.model';
export var SetTypes;
(function (SetTypes) {
    SetTypes["Exterior"] = "Exterior";
    SetTypes["Interior"] = "Interior";
})(SetTypes || (SetTypes = {}));
// ToDo: Standardise the object attributes required for the different drop downs and selects
export var setTypeOptions = [
    { value: SetTypes.Exterior, name: SetTypes.Exterior, id: SetTypes.Exterior, text: SetTypes.Exterior },
    { value: SetTypes.Interior, name: SetTypes.Interior, id: SetTypes.Interior, text: SetTypes.Interior },
];
var SetModel = /** @class */ (function (_super) {
    __extends(SetModel, _super);
    function SetModel(id, name, location, type, display_text, last_updated, pic, story_days, scene_numbers) {
        if (id === void 0) { id = null; }
        if (name === void 0) { name = null; }
        if (location === void 0) { location = null; }
        if (type === void 0) { type = null; }
        if (display_text === void 0) { display_text = null; }
        if (last_updated === void 0) { last_updated = null; }
        if (pic === void 0) { pic = null; }
        if (story_days === void 0) { story_days = null; }
        if (scene_numbers === void 0) { scene_numbers = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.name = name;
        _this.location = location;
        _this.type = type;
        _this.display_text = display_text;
        _this.last_updated = last_updated;
        _this.pic = pic;
        _this.story_days = story_days;
        _this.scene_numbers = scene_numbers;
        return _this;
    }
    SetModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Gallery
        if (result["set_galleries"]) {
            this.galleries = new Array();
            for (var _i = 0, _a = result["set_galleries"]; _i < _a.length; _i++) {
                var json = _a[_i];
                if (json["gallery"]) {
                    var gallery = new GalleryModel();
                    gallery.makeObjectFromJson(json["gallery"]);
                    this.galleries.push(gallery);
                }
            }
        }
        //Set scenes
        this.set_scenes = new Array();
        if (result["set_scenes"]) {
            for (var _b = 0, _c = result["set_scenes"]; _b < _c.length; _b++) {
                var json = _c[_b];
                var setScene = new SetSceneModel();
                setScene.makeObjectFromJson(json);
                this.set_scenes.push(setScene);
            }
        }
        // Attachments
        this.attachments = new Array();
        if (result["set_attachments"]) {
            for (var _d = 0, _e = result["set_attachments"]; _d < _e.length; _d++) {
                var json = _e[_d];
                if (json["attachment"]) {
                    var attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }
    };
    return SetModel;
}(BaseModel));
export { SetModel };
