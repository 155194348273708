import { AttachmentModel } from '../_models/attachment.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderModel, MultiVendorOrderModel } from '../_models/order.model';
import { Observable } from 'rxjs';

@Injectable()
export class OrderService {

    constructor(private http: HttpClient) {
    }

    get(id: number) {
        return this.http.get('order/' + id)
            .map(
                (response: any) => {
                    const record: OrderModel = new OrderModel();
                    record.makeObjectFromJson(response);
                    return record;
                }
            );
    }

    getV2(id: number) {
        return this.http.get('order/' + id)
            .map(
                (response: any) => {
                    return <OrderModel>response;
                }
            );
    }

    getMultiVendor(id: number) {
      return this.http.get('multi-vendor-order/' + id)
        .map(
          (response: any) => {
            return <MultiVendorOrderModel>response;
          }
        );
    }

    store(form: any): Observable<OrderModel> {
        return this.http.post('order', { order: form })
            .map(
                (response: any) => {
                    return <OrderModel>response;
                }
            );
    }

    storeMultiVendor(form: any): Observable<MultiVendorOrderModel> {
        return this.http.post('multi-vendor-order', { order: form })
            .map(
                (response: any) => {
                    return <MultiVendorOrderModel>response;
                }
            );
    }

    update(id: number, form: any) {
        return this.http.post('order/' + id, { order: form, _method: 'PUT' });
    }

    updateMultiVendor(id: number, form: any) {
        return this.http.post('multi-vendor-order/' + id, { order: form, _method: 'PUT' });
    }

    delete(id: number) {
        return this.http.post('order/' + id, { _method: 'DELETE' });
    }

    deleteMultiVendor(id: number) {
        return this.http.post('multi-vendor-order/' + id, { _method: 'DELETE' });
    }

    deleteAll(orders: any) {
        return this.http.post('order/delete/all', orders);
    }

    returnItems(orderId: number, form: any) {
        return this.http.post('order/' + orderId + '/return', form);
    }

    getAccountCodes() {
        return this.http.get('order/custom/get/account-codes')
            .map(
                (respose: any) => {
                    return respose;
                }
            );
    }

    getAdditionalAccountCodes() {
        return this.http.get('order/custom/get/additional-account-codes')
            .map(
                (respose: any) => {
                    return respose;
                }
            );
    }

    lastUpdateTime() {
        return this.http.get('orders/custom/last-update')
            .map(
                (response: any) => {
                    return response!.data;
                }
            );
    }

    getAssetNumber(id: number) {
        return this.http.post('order/custom/get/next-asset-number', {
            order_id: id
        }).map(
            (response: any) => {
                return response.asset_number;
            }
        );
    }

    generateItemReturnPdf(form) {
        return this.http.post('item/custom/item-return/pdf', form, { responseType: 'blob' })
            .map(
                (response: any) => {
                    const newBlob = new Blob([<any>response], { type: 'application/pdf' });
                    const blobUrl = URL.createObjectURL(newBlob);

                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);
                    iframe.contentWindow.print();

                    return response;
                }
            );

    }

    restoreOrder(id: number) {
        return this.http.get('order/custom/undo/delete/status/' + id);
    }

    markAsPrimary(attachment: AttachmentModel, item: any) {
        return this.http.post('item/custom/set-thumbnail-image', {
            item: {
                id: item.value.id
            },
            attachment: {
                id: attachment.id
            }
        })
    }

}
