import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NoteService = /** @class */ (function () {
    function NoteService(http) {
        this.http = http;
    }
    NoteService.prototype.store = function (form) {
        return this.http.post('note', form)
            .map(function (response) {
            return response;
        });
    };
    NoteService.prototype.delete = function (id) {
        return this.http.post('note/' + id, {
            _method: "DELETE"
        });
    };
    NoteService.prototype.update = function (id, form) {
        return this.http.post('note/' + id, form);
    };
    NoteService.prototype.get = function (type, typeId, roleId) {
        return this.http.post('note/custom/' + type + '/' + typeId, {
            role_id: roleId
        })
            .map(function (response) {
            return response;
        });
    };
    NoteService.ngInjectableDef = i0.defineInjectable({ factory: function NoteService_Factory() { return new NoteService(i0.inject(i1.HttpClient)); }, token: NoteService, providedIn: "root" });
    return NoteService;
}());
export { NoteService };
