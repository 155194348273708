var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CharacterChangeModel } from 'app/_models/character-change.model';
import { SceneModel } from 'app/_models/scene.model';
import { BaseModel } from "./base.model";
var CharacterChangeSceneModel = /** @class */ (function (_super) {
    __extends(CharacterChangeSceneModel, _super);
    function CharacterChangeSceneModel(id, production_id, character_change_id, scene_id) {
        if (id === void 0) { id = null; }
        if (production_id === void 0) { production_id = null; }
        if (character_change_id === void 0) { character_change_id = null; }
        if (scene_id === void 0) { scene_id = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.production_id = production_id;
        _this.character_change_id = character_change_id;
        _this.scene_id = scene_id;
        return _this;
    }
    CharacterChangeSceneModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Scene
        if (result["scene"]) {
            this.scene = new SceneModel();
            this.scene.makeObjectFromJson(result["scene"]);
        }
        //Character Change
        if (result["character_change"]) {
            this.character_change = new CharacterChangeModel();
            this.character_change.makeObjectFromJson(result["character_change"]);
        }
    };
    return CharacterChangeSceneModel;
}(BaseModel));
export { CharacterChangeSceneModel };
