import { BaseModel } from "./base.model";
import { StripboardColorModel } from "./stripboard-color.model";

export class StripboardModel extends BaseModel {
    public stripboard_colors: StripboardColorModel[];
    public recent_stripboard_color: StripboardColorModel;

    constructor(public id: number = null,
        public name: string = null,
        public is_episode_script: boolean = null,
        public script_name: string = null,
        public episode_number: number = null,
        public updated_at: string = null,
        public display_name: string = null,
        public display_text: string = null,
        public profile_text: string = null,
        public last_updated: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        //Stripboard colors
        this.stripboard_colors = new Array();
        if (result["stripboard_colors"]) {
            for (const json of result["stripboard_colors"]) {
                const record: StripboardColorModel = new StripboardColorModel();
                record.makeObjectFromJson(json);
                this.stripboard_colors.push(record);
            }
        }

        //Recent stripboard color
        if (result["recent_stripboard_color"]) {
            this.recent_stripboard_color = new StripboardColorModel();
            this.recent_stripboard_color.makeObjectFromJson(result["recent_stripboard_color"]);
        }
    }

    getRecentStripboardColorId() {
        if (this.recent_stripboard_color) {
            return this.recent_stripboard_color.id;
        }

        return null;
    }
}