import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { DepartmentModel } from 'app/_models/department.model';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {

    }

    update(form: any, id: number) {
        return this.http.post('user/' + id, form);
    }

    verifyUser() {
        return this.http.get('verify');
    }

    getRole() {
        return this.http.get('get-role')
        .map(
            (response: any) => {
                return <DepartmentModel[]>response;
            }
        );
    }
}
