var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
import { ItemModel } from './item.model';
var HeroPropModel = /** @class */ (function (_super) {
    __extends(HeroPropModel, _super);
    function HeroPropModel(id, production_id, is_established) {
        if (id === void 0) { id = null; }
        if (production_id === void 0) { production_id = null; }
        if (is_established === void 0) { is_established = false; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.production_id = production_id;
        _this.is_established = is_established;
        return _this;
    }
    HeroPropModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Item
        if (result["item"]) {
            this.item = new ItemModel();
            this.item.makeObjectFromJson(result["item"]);
        }
    };
    return HeroPropModel;
}(BaseModel));
export { HeroPropModel };
