import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentModel } from 'app/_models/attachment.model';


@Component({
  selector: 'app-light-box',
  templateUrl: './light-box.component.html',
  styleUrls: ['./light-box.component.scss']
})
export class LightBoxComponent implements OnInit {
  private _type: string = null;
  @Input('type')
  set type(value: string) {
    this._type = value;
  }

  get type() {
    return this._type;
  }


  private _filename: string = null;
  @Input('filename')
  set filename(value: string) {
    this._filename = value;
  }

  get filename() {
    return this._filename;
  }

  private _recordId: number = null;
  @Input('recordId')
  set recordId(value: number) {
    this._recordId = value;
  }

  get recordId() {
    return this._recordId;
  }
  public attachments: AttachmentModel[];
  public activeImage: AttachmentModel;

  constructor(public activeModal: NgbActiveModal,
    public http: HttpClient) {

  }

  ngOnInit() {
    this.http.get(this.type + '/custom/get/attachments/' + this.recordId)
      .map(
        (response: any) => {
          return <AttachmentModel[]>response;
        }
      )
      .subscribe(
        result => {
          this.attachments = result;

          if (this.filename) {
            this.activeImage = this.attachments.find(item => item.file_name === this.filename);
          } else {
            this.activeImage = this.attachments[0];
          }
        }
      );
  }

  getSelectedIndex() {
    return this.attachments.findIndex(item => item.id === this.activeImage.id) + 1;
  }

  nextClicked() {
    this.activeImage = this.attachments[this.getSelectedIndex()];
  }

  backClicked() {
    this.activeImage = this.attachments[this.getSelectedIndex() - 2];
  }
}
