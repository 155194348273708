<!-- Navbar (Header) Starts -->
<nav class="navbar navbar-expand-lg navbar-light bg-faded z-index">
    <div class="container-fluid navbar-header">
        <div class="navbar-left">
            <div class="navbar-brand">
                <img src="assets/img/boxset_logo_hor_colour.png" alt="Boxset" class="width-120 height-50">
            </div>
            <button class="navbar-toggler collapsed" type="button" *ngIf="isNavMenuAvailable === true"
                    (click)="toggleNavMenuCollapse()"
                    data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse" [ngbCollapse]="isNavMenuCollapsed" id="navbarNav" *ngIf="isNavMenuAvailable === true">
                <ul class="navbar-nav">
                    <li class="nav-item" *ngxPermissionsOnly="['Stripboard']">
                      <a class="nav-link" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }" [routerLink]="['/stripboard']">
                          Stripboard
                      </a>
                    </li>
                    <li class="nav-item" *ngxPermissionsOnly="['Order']">
                        <a class="nav-link" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }" [routerLink]="['/order']">
                            Orders
                        </a>
                    </li>
                    <li class="nav-item" *ngxPermissionsOnly="['Inventory']">
                        <a class="nav-link" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }" [routerLink]="['/inventory']">
                            Inventory
                        </a>
                    </li>
                    <li class="nav-item" *ngxPermissionsOnly="['Characters']">
                        <a class="nav-link" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }" [routerLink]="['/character']">
                            Characters
                        </a>
                    </li>
                    <li class="nav-item" *ngxPermissionsOnly="['Sets']">
                        <a class="nav-link" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }" [routerLink]="['/set']">
                            Sets
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="navbar-right">
            <div ngbDropdown placement="bottom-right">
                <a class="nav-link position-relative" id="user-actions" aria-label="User Settings" ngbDropdownToggle>
                    <i class="ft-user font-large-1 blue-grey darken-4"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="user-actions" class="p-0">
                    <a class="dropdown-item py-1" [routerLink]="['/setting']">Settings</a>
                    <a class="dropdown-item py-1" (click)="switchProductionClicked()">Switch Production</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="logoutClicked()">Logout</a>
                </div>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->
