import { BaseModel } from "./base.model";

export class MeasurementModel extends BaseModel {
    constructor(public id: number = null,
        public production_id: number = null,
        public measurement: string = null,
        public measurement_value: string = null,
        public unit_value: string = null,
        public element_type: string = null,
        public elements: string = null) {
        super();
    }
}