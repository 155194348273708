import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SettingService = /** @class */ (function () {
    function SettingService(http) {
        this.http = http;
    }
    SettingService.prototype.get = function (type) {
        return this.http.get('setting/' + type)
            .map(function (response) {
            return response;
        });
    };
    SettingService.prototype.store = function (form) {
        return this.http.post('setting', form);
    };
    SettingService.ngInjectableDef = i0.defineInjectable({ factory: function SettingService_Factory() { return new SettingService(i0.inject(i1.HttpClient)); }, token: SettingService, providedIn: "root" });
    return SettingService;
}());
export { SettingService };
