import { ActivatedRoute, Router } from "@angular/router";
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(router, route) {
        this.router = router;
        this.route = route;
        this.placement = 'bottom-right';
        this.isNavMenuCollapsed = true;
        this._isNavMenuAvailable = true;
        this.isNavMenuAvailable = !!localStorage.getItem('currentProduction');
    }
    Object.defineProperty(NavbarComponent.prototype, "isNavMenuAvailable", {
        get: function () {
            return this._isNavMenuAvailable;
        },
        set: function (value) {
            this._isNavMenuAvailable = value;
        },
        enumerable: true,
        configurable: true
    });
    NavbarComponent.prototype.toggleNavMenuCollapse = function () {
        this.isNavMenuCollapsed = !this.isNavMenuCollapsed;
    };
    NavbarComponent.prototype.switchProductionClicked = function () {
        localStorage.removeItem('currentProduction');
        this.router.navigate(['/production']);
    };
    NavbarComponent.prototype.logoutClicked = function () {
        localStorage.clear();
        this.router.navigate(['/auth']);
    };
    return NavbarComponent;
}());
export { NavbarComponent };
