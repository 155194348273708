var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { UserSettingService } from 'app/_services/user-setting.service';
var CommonUploadFileComponent = /** @class */ (function () {
    function CommonUploadFileComponent(activeModal, fb, userSettingService, http) {
        this.activeModal = activeModal;
        this.fb = fb;
        this.userSettingService = userSettingService;
        this.http = http;
        this.recordAdded = new EventEmitter();
        this.attachments = [];
        this.formSubmitLoader = false;
    }
    Object.defineProperty(CommonUploadFileComponent.prototype, "body", {
        get: function () {
            return this._body;
        },
        set: function (value) {
            this._body = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUploadFileComponent.prototype, "link", {
        get: function () {
            return this._link;
        },
        set: function (value) {
            this._link = value;
        },
        enumerable: true,
        configurable: true
    });
    CommonUploadFileComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group({
            attachments: this.fb.array([])
        });
    };
    CommonUploadFileComponent.prototype.addArrayForAttachment = function (attachment) {
        var records = this.form.get('attachments');
        records.push(this.fb.group({
            id: [attachment.id]
        }));
    };
    CommonUploadFileComponent.prototype.removeAttachment = function (attachment) {
        var records = this.form.get('attachments')['controls'];
        var index = records.findIndex(function (item) { return item.value.id === attachment.id; });
        if (index !== -1) {
            var attachments = this.form.get('attachments');
            attachments.removeAt(index);
        }
    };
    CommonUploadFileComponent.prototype.onSubmit = function (form) {
        var _this = this;
        this.formSubmitLoader = true;
        if (this.link) {
            // Post the uploaded file(s) to the url provided
            var postBody = __assign({}, this.form.value, this.body);
            this.http.post(this.link, postBody)
                .subscribe(function (result) {
                _this.recordAdded.emit();
                _this.activeModal.close();
            }, function (error) {
                _this.formSubmitLoader = false;
            });
        }
        else {
            // If no URL exists to post to, return the attachements to the parent with the emit event
            this.recordAdded.emit(this.attachments);
            this.activeModal.close();
        }
    };
    return CommonUploadFileComponent;
}());
export { CommonUploadFileComponent };
