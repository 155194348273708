import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var LightBoxComponent = /** @class */ (function () {
    function LightBoxComponent(activeModal, http) {
        this.activeModal = activeModal;
        this.http = http;
        this._type = null;
        this._filename = null;
        this._recordId = null;
    }
    Object.defineProperty(LightBoxComponent.prototype, "type", {
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightBoxComponent.prototype, "filename", {
        get: function () {
            return this._filename;
        },
        set: function (value) {
            this._filename = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightBoxComponent.prototype, "recordId", {
        get: function () {
            return this._recordId;
        },
        set: function (value) {
            this._recordId = value;
        },
        enumerable: true,
        configurable: true
    });
    LightBoxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.http.get(this.type + '/custom/get/attachments/' + this.recordId)
            .map(function (response) {
            return response;
        })
            .subscribe(function (result) {
            _this.attachments = result;
            if (_this.filename) {
                _this.activeImage = _this.attachments.find(function (item) { return item.file_name === _this.filename; });
            }
            else {
                _this.activeImage = _this.attachments[0];
            }
        });
    };
    LightBoxComponent.prototype.getSelectedIndex = function () {
        var _this = this;
        return this.attachments.findIndex(function (item) { return item.id === _this.activeImage.id; }) + 1;
    };
    LightBoxComponent.prototype.nextClicked = function () {
        this.activeImage = this.attachments[this.getSelectedIndex()];
    };
    LightBoxComponent.prototype.backClicked = function () {
        this.activeImage = this.attachments[this.getSelectedIndex() - 2];
    };
    return LightBoxComponent;
}());
export { LightBoxComponent };
