import { ItemQueueComponent } from './../inventory/item-queue/item-queue.component';
import { ItemDetailsComponent } from "./../inventory/_modals/item-details/item-details.component";
import { CommonCardComponent } from "./common-card/common-card.component";
import { LightBoxComponent } from "./light-box/light-box.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserSettingService } from "app/_services/user-setting.service";
import { DepartmentService } from "./../_services/department.service";
import { OrderDeliveryService } from "./../_services/order-delivery.service";
import { QueueService } from "./../_services/queue.service";
import { CommonUploadFileComponent } from "./common-upload-file/common-upload-file.component";
import { NotificationSidebarComponent } from "./notification-sidebar/notification-sidebar.component";
import { StripboardColorSceneService } from "./../_services/stripboard-color-scene.service";
import { SceneService } from "app/_services/scene.service";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { StripboardService } from "./../_services/stripboard.service";
import { RoleService } from "./../_services/role.service";
import { SetService } from "./../_services/set.service";
import { ProductionService } from "./../_services/production.service";
import { InvalidTooltipDirective } from "./../_directives/invalid-tooltip.directive";
import { DisplayDatePipe, DisplayDateTimePipe } from "./../_pipes/display-date.pipe";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { CharacterService } from "./../_services/character.service";
import { ItemService } from "./../_services/item.service";
import { OrderService } from "./../_services/order.service";
import { CharacterChangeService } from "./../_services/character-change.service";
import { CharacterChangeContinuityService } from "./../_services/character-change-continuity.service";
import { GalleryService } from "./../_services/gallery.service";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FileDroppa } from './file-droppa';
import { StripboardColorService } from 'app/_services/stripboard-color.service';
import { TagInputModule } from 'ngx-chips';
import { AgmCoreModule } from '@agm/core';
import { SceneNoteService } from 'app/_services/scene-note.service';
import { CommonCounterComponent } from './common-counter/common-counter.component';
import { SetContinuityService } from 'app/_services/set-continuity.service';
import { SettingService } from 'app/_services/setting.service';
import { MatchColumnsComponent } from './upload-csv/match-columns/match-columns.component';
import { UploadCsvComponent } from './upload-csv/upload-csv/upload-csv.component';
import { NotesComponent } from './notes/notes/notes.component';
import { NoteComponent } from './notes/note/note.component';
import { NoteService } from 'app/_services/note.service';
import { CurrencyService } from 'app/_services/currency.service';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TwoDecimalNumberDirective } from 'app/_directives/two-decimal-number.directive';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgSelectExtensionComponent } from './ng-select-extension/ng-select-extension.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { EmptyIndexComponent } from './empty-index/empty-index.component';
import { IndexTitleComponent } from './index/index-title/index-title.component';
import {IndexActionsComponent} from './index/index-actions/index-actions.component';
import {CreatePresetFormComponent} from './index/index-actions/_components/create-preset-form/create-preset-form.component';
import {IndexFilterComponent} from './index/index-filter/index-filter.component';
import { GridViewComponent } from './views/grid-view/grid-view.component';
import { ListViewComponent } from './views/list-view/list-view.component';
import { GridThumbnailComponent } from './grid-thumbnail/grid-thumbnail.component';
import { IndexService } from '../_services/index.service';
import { DepartmentDropdownComponent } from './department-dropdown/department-dropdown.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    NgbModule,
    DisplayDatePipe,
    DisplayDateTimePipe,
    ReactiveFormsModule,
    FormsModule,
    InvalidTooltipDirective,
    NgSelectModule,
    UiSwitchModule,
    NgxDatatableModule,
    FileUploadComponent,
    FileDroppa,
    TagInputModule,
    AgmCoreModule,
    NotificationSidebarComponent,
    CommonCounterComponent,
    CommonUploadFileComponent,
    NotesComponent,
    NoteComponent,
    TreeViewComponent,
    NgxPermissionsModule,
    DeviceDetectorModule,
    TableModule,
    CheckboxModule,
    NgxPaginationModule,
    TwoDecimalNumberDirective,
    SelectButtonModule,
    LightBoxComponent,
    CommonCardComponent,
    RadioButtonModule,
    NgSelectExtensionComponent,
    EmptyIndexComponent,
    IndexTitleComponent,
    IndexActionsComponent,
    CreatePresetFormComponent,
    IndexFilterComponent,
    GridThumbnailComponent,
    GridViewComponent,
    ListViewComponent,
    DepartmentDropdownComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    UiSwitchModule,
    NgxDatatableModule,
    FileDroppa,
    TagInputModule,
    AgmCoreModule,
    NgxPermissionsModule.forChild(),
    TableModule,
    CheckboxModule,
    NgxPaginationModule,
    SelectButtonModule,
    RadioButtonModule,
    InfiniteScrollModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    DisplayDatePipe,
    DisplayDateTimePipe,
    InvalidTooltipDirective,
    FileUploadComponent,
    NotificationSidebarComponent,
    CommonCounterComponent,
    CommonUploadFileComponent,
    MatchColumnsComponent,
    UploadCsvComponent,
    NotesComponent,
    NoteComponent,
    GridViewComponent,
    ListViewComponent,
    TreeViewComponent,
    NoPermissionComponent,
    PageNotFoundComponent,
    TwoDecimalNumberDirective,
    LightBoxComponent,
    CommonCardComponent,
    NgSelectExtensionComponent,
    ItemDetailsComponent,
    EmptyIndexComponent,
    IndexTitleComponent,
    IndexActionsComponent,
    CreatePresetFormComponent,
    IndexFilterComponent,
    GridThumbnailComponent,
    ItemQueueComponent,
    DepartmentDropdownComponent
  ],
  providers: [
    ProductionService,
    SetService,
    RoleService,
    StripboardService,
    StripboardColorService,
    SceneService,
    CharacterService,
    ItemService,
    OrderService,
    CharacterChangeService,
    CharacterChangeContinuityService,
    GalleryService,
    StripboardColorSceneService,
    SceneNoteService,
    SetContinuityService,
    // QueueService,
    OrderDeliveryService,
    DepartmentService,
    UserSettingService,
    SettingService,
    NoteService,
    CurrencyService,
    IndexService
  ],
  entryComponents: [
    CommonUploadFileComponent,
    MatchColumnsComponent,
    UploadCsvComponent,
    LightBoxComponent,
    CommonCardComponent,
    ItemDetailsComponent,
    IndexTitleComponent,
    IndexActionsComponent,
    CreatePresetFormComponent,
    IndexFilterComponent,
    ItemQueueComponent
  ]
})
export class SharedModule { }
