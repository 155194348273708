import { CharacterChangeSceneModel } from './character-change-scene.model';
import { CharacterChangeItemModel } from './character-change-item.model';
import { GalleryModel } from './gallery.model';
import { CharacterModel } from './character.model';
import { AttachmentModel } from './attachment.model';
import { SceneModel } from './scene.model';
import {BaseModel} from "./base.model";

export class CharacterChangeModel extends BaseModel {
    public scenes: SceneModel[];
    public attachments: AttachmentModel[];
    public character: CharacterModel;
    public galleries: GalleryModel[];
    public items: CharacterChangeItemModel[]; 
    public character_change_scenes: CharacterChangeSceneModel[];
    
    constructor(public id: number = null,
                public story_days: any = null,
                public name: string = null,
                public pic: string = null,
                public type: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        //Scene
        this.scenes = new Array();
        this.character_change_scenes = new Array();
        if(result["character_change_scenes"]){
            for(const json of result["character_change_scenes"]) {               
                if(json){
                    const characterChangeScene = new CharacterChangeSceneModel();
                    characterChangeScene.makeObjectFromJson(json);
                    this.character_change_scenes.push(characterChangeScene);
                    this.scenes.push(characterChangeScene.scene); 
                }                
            }
        }

        //Gallery
        if(result["character_change_galleries"]){
            this.galleries = new Array();
            for(const json of result["character_change_galleries"]){
                if(json["gallery"]){
                    const gallery = new GalleryModel();
                    gallery.makeObjectFromJson(json["gallery"]);
                    this.galleries.push(gallery);
                }                
            }
        }

        //Character
        if(result["character"]){
            this.character = new CharacterModel();
            this.character.makeObjectFromJson(result["character"]);
        }

        // Attachments
        this.attachments = new Array();
        if (result["character_change_attachments"]) {
            for (const json of result["character_change_attachments"]) {
                if (json["attachment"]) {
                    const attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }

        //Character Change Item
        this.items = new Array();
        if(result["character_change_items"]){
            for(const json of result["character_change_items"]) {
                if(json["item"]){
                    const item = new CharacterChangeItemModel();
                    item.makeObjectFromJson(json);
                    this.items.push(item);
                }                
            }
        }
    }

    getStoryDay() {
        if(this.story_days && this.story_days[0]['story_day']){
            return this.story_days[0]['story_day'];
        }

        return '';
    }
}