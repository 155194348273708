import {BaseModel} from "./base.model";

export class RoleModel extends BaseModel {
    public permissions: any[];
    
    constructor(public id: number = null,
                public name: string = null,
                public slug: string = null,
                public account_number: string = null,
                public collaborator_count: number = null,
                public guest_count: number = null,
                public moderator_count: number = null) {
        super();
    }
}
