import { CharacterAttachmentModel } from './character-attachment.model';
import { HeroPropModel } from './hero-prop.model';
import { CharacterChangeModel } from './character-change.model';
import { MeasurementModel } from './measurement.model';
import { BaseModel } from "./base.model";
import { SceneCharacterModel } from './scene-character.model';

export class CharacterModel extends BaseModel {
    public measurements: MeasurementModel[];
    public changes: CharacterChangeModel[];
    public hero_pros: HeroPropModel[];
    public scene_characters: SceneCharacterModel[];
    public character_attachments: CharacterAttachmentModel[];

    constructor(public id: number = null,
        public production_id: number = null,
        public character_name: string = null,
        public character_number: string = null,
        public type: string = null,
        public actor_name: string = null,
        public measurement_type: string = null,
        public pic: string = null,
        public display_text: string = null,
        public scenes_display_text: string = null,
        public story_days: string = null) {
        super();
    }

    makeObjectFromJson(result: any[]) {
        super.makeObjectFromJson(result);

        //Measurements
        if (result["measurements"]) {
            this.measurements = new Array();
            for (const json of result["measurements"]) {
                const measurement = new MeasurementModel();
                measurement.makeObjectFromJson(json);
                this.measurements.push(measurement);
            }
        }

        //Change
        this.changes = new Array();
        if (result["changes"]) {
            for (const json of result["changes"]) {
                const change = new CharacterChangeModel();
                change.makeObjectFromJson(json);
                this.changes.push(change);
            }
        }

        //Hero props
        this.hero_pros = new Array();
        if (result["hero_props"]) {
            for (const json of result["hero_props"]) {
                if (json["item"]) {
                    const item = new HeroPropModel();
                    item.makeObjectFromJson(json);
                    this.hero_pros.push(item);
                }
            }
        }
    }
}