import { FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSettingService } from 'app/_services/user-setting.service';

@Component({
  selector: 'app-match-columns',
  templateUrl: './match-columns.component.html',
  styleUrls: ['./match-columns.component.scss']
})
export class MatchColumnsComponent implements OnInit {
  public form: FormGroup;
  public _columns: Array<any>;
  set columns(value: any) {
    this._columns = value;
  }
  get columns() {
    return this._columns;
  }

  public _section: string;
  set section(value: string) {
    this._section = value;
  }
  get section() {
    return this._section;
  }

  public _dataLink: string;
  set dataLink(value: string) {
    this._dataLink = value;
  }
  get dataLink() {
    return this._dataLink;
  }

  public _submitLink: string;
  set submitLink(value: string) {
    this._submitLink = value;
  }
  get submitLink() {
    return this._submitLink;
  }

  public rows: any[];
  public formSubmitLoader: boolean;

  public _postBody: any;
  set postBody(value: any) {
    this._postBody = value;
  }
  get postBody() {
    return this._postBody;
  }
  public columnsSet: Array<any> = new Array();
  public columnsSelectedSet: Array<any> = new Array();
  public rowsToImport: Array<any>;
  public rowsImportForm: FormGroup;
  public selectedCities;

  constructor(public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public http: HttpClient,
    public userSettingService: UserSettingService) { }

  ngOnInit() {
    this.form = this.fb.group({
      attachment: this.fb.group({
        id: [null, [Validators.required]]
      })
    });

    this.rowsImportForm = this.fb.group({
      rows_import: this.fb.array([])
    });

    return this.http.get(this.dataLink)
      .subscribe(
        result => {
          result = <any>result;
          this.rows = <Array<any>>(<any>result).rows;
          this.rowsToImport = <Array<any>>(<any>result).columns;

          this.makeData();
          this.makeRowData();
        }
      );
  }

  makeRowData() {
    const records = this.rowsImportForm.get('rows_import') as FormArray;

    for (const row of this.rowsToImport) {
      const record = this.fb.group({
        label: [row.Label, [Validators.required]],
        columns: this.fb.array([])
      });

      const _columns = record.get('columns') as FormArray;
      for (const __row of row.Rows) {
        _columns.push(this.fb.group({
          label: [__row],
          is_selected: [true]
        }));
      }

      records.push(record);
    }
  }

  makeData() {
    const length = this.columns.length;
    let i = 0;

    while (i < length) {
      this.columnsSet[i] = [...this.columns];

      if (this.rows && this.rows[0] && this.rows[0][this.columns[i].text] !== undefined) {
        this.columnsSelectedSet[i] = { ...this.columns[i] };
      } else {
        this.columnsSelectedSet[i] = null;
      }

      i++;
    }
  }

  importClicked() {
    this.formSubmitLoader = true;

    const columns: Array<any> = new Array();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columnsSelectedSet[i] && this.columnsSelectedSet[i].text) {
        columns.push({
          id: this.columns[i].id,
          column: this.columnsSelectedSet[i].text
        });
      }
    }

    let postBody = { ...this.postBody, ...{ columns: columns } };
    postBody = { ...postBody, ...this.rowsImportForm.value };

    this.http.post(this.submitLink, postBody)
      .subscribe(
        result => {
          this.activeModal.close();
        },
        error => {
          this.formSubmitLoader = false;
        }
      );
  }

  columnSelected(col: any, _index: number) {
    // for(let i = 0; i < this.columnsSelectedSet.length; i++){
    //   console.log(this.columnsSelectedSet[i]);
    //   let columns = [...this.columns];
    //   for(let _column of columns){
    //     _column = {..._column, ...{disabled: true}};
    //   }

    //   console.log(columns);
    // }
  }
}
