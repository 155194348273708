import { PermissionService } from './../_services/permission.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductionService } from 'app/_services/production.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionGuard implements CanActivate {
  constructor(private productionService: ProductionService,
    private router: Router,
    public permissionService: PermissionService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.permissionService.permissions) {
      this.router.navigate(['production/permissions'], {
        queryParams: {
          redirect: state.url
        }
      });
      return false;
    }

    if (this.productionService.currentProductionValue) {
      return true;
    }

    this.router.navigate(['production']);
    return false;
  }
}
