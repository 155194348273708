import { Component, AfterViewChecked, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {

    public placement = 'bottom-right'
    public isNavMenuCollapsed = true;
    public _isNavMenuAvailable = true;

    @Input('isNavMenuAvailable')

    set isNavMenuAvailable(value: boolean) {
        this._isNavMenuAvailable = value;
    }

    get isNavMenuAvailable() {
        return this._isNavMenuAvailable;
    }

    constructor(public router: Router,
        private route: ActivatedRoute) {
        this.isNavMenuAvailable = !!localStorage.getItem('currentProduction');
    }

    toggleNavMenuCollapse() {
      this.isNavMenuCollapsed = !this.isNavMenuCollapsed
    }

    switchProductionClicked() {
        localStorage.removeItem('currentProduction');
        this.router.navigate(['/production']);
    }

    logoutClicked() {
        localStorage.clear();
        this.router.navigate(['/auth']);
    }
}
