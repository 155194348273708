<div class="index-title row justify-content-between align-items-center">
    <h1>{{title}}</h1>
    <div class="d-flex align-items-center">
        <p>Last Updated: {{ !lastUpdateTime ? '&#8230;' : lastUpdateTime | displayDateTime }}</p>
        <ng-container *ngIf="hasPageActions">
            <div ngbDropdown placement="bottom-right">
                <button class="btn btn-sm btn-outline-secondary mb-0" id="index-title__actions-dropdown" attr.aria-label="{{title}} Actions" ngbDropdownToggle>
                    <i class="ft-more-horizontal"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="index-title__actions-dropdown">
                    <ng-content select="[index-title-options]"></ng-content>
                </div>
            </div>
        </ng-container>
    </div>
</div>
