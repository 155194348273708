import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SetSceneModel } from 'app/_models/set-scene.model';

@Injectable({
  providedIn: 'root'
})
export class SetContinuityService {

  constructor(public http: HttpClient) { }

  getRecordsForSelect2(records: SetSceneModel[]) {
    const items: any[] = new Array();
    for (const record of records) {
      items.push({
        id: record.id,
        text: record.scene.scene_number
      });
    }
    return items;
  }
}
