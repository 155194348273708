import {BaseModel} from "./base.model";
import {AttachmentModel} from "./attachment.model";

export class OrderDeliveryNoteModel extends BaseModel {
    public attachment: AttachmentModel;

    constructor(public id: number = null,
                public order_id: number = null,
                public attachment_id: number = null) {
        super();
    }

    makeObjectFromJson(result) {
        super.makeObjectFromJson(result);

        // Attachment
        if (result["attachment"]) {
            this.attachment = new AttachmentModel();
            this.attachment.makeObjectFromJson(result["attachment"]);
        }
    }
}