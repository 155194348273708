import { FormArray } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { AttachmentModel } from './../../../_models/attachment.model';
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddPhotoComponent } from './../add-photo/add-photo.component';
import { EditPhotoComponent } from './../edit-photo/edit-photo.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output } from '@angular/core';
import { ItemService } from 'app/_services/item.service';
import { ItemModel } from 'app/_models/item.model';
import { QueueService } from 'app/_services/queue.service';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {
  @Output() recordAdded = new EventEmitter<any>();

  public activeImage: string;
  private _itemId: number;
  @Input('itemId')
  set itemId(value: number) {
    this._itemId = value;
    this.getItem();
  }

  get itemId() {
    return this._itemId;
  }

  public item: ItemModel;
  public attachments: AttachmentModel[];
  public form: FormGroup;

  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public itemService: ItemService,
    public http: HttpClient,
    public queueService: QueueService,
    public fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      attachments: this.fb.array([])
    });
  }

  getItem() {
    this.itemService.get(this.itemId)
      .subscribe(
        result => {
          this.item = result;
          this.activeImage = this.item.pic;
          // if (this.item['attachments'] && this.item['attachments'].length > 0) {
          //   this.activeImage = this.item['attachments'][0].file_path;
          // } else {
          // }
        }
      );
  }

  deleteItemModal(id: number) {
    this.itemService.delete(id)
      .subscribe(
        result => {
          this.recordAdded.emit();
          this.activeModal.close();
        }
      );
  }

  editPhotoModal() {
    const modalRef = this.modalService.open(EditPhotoComponent,
      {
        windowClass: "modal-md modal-xxl small-modal"
      });
  }

  uploadPhotoModal() {
    const modalRef = this.modalService.open(AddPhotoComponent,
      {
        windowClass: "modal-md modal-xxl small-modal"
      });
  }

  deleteTag(id: number) {
    this.itemService.deleteTag(id)
      .subscribe(
        result => {
          this.getItem();
        }
      );
  }

  getSelectedAttachments() {
    return this.item.attachments.filter(item => item.is_selected === true);
  }

  deleteAttachments() {
    return this.http.post('attachment/custom/delete', { attachments: this.getSelectedAttachments() })
      .subscribe(
        result => {
          this.getItem();
        }
      );
  }

  showDeleteAll() {
    if (this.getSelectedAttachments().length > 0) {
      return true;
    }
    return false;
  }

  addArrayForAttachment(attachment: AttachmentModel) {
    const records = this.form.get('attachments') as FormArray;

    records.push(this.fb.group({
      id: [attachment.id]
    }));

    this.itemService.addAttachment(attachment, this.item.id)
      .subscribe(
        result => {
          this.recordAdded.emit();
          this.item.attachments.push(attachment);
        }
      );
  }

  removeAttachment(attachment: AttachmentModel) {
    const records = this.form.get('attachments')['controls'];

    const index = records.findIndex(item => item.value.id === attachment.id);
    if (index !== -1) {
      const attachments = this.form.get('attachments') as FormArray;
      attachments.removeAt(index);
    }
  }

  printLabels() {
    this.itemService.printLabels(this.item.order_id, this.item.id)
      .subscribe(
        result => {
          var newBlob = new Blob([<any>result], { type: "application/pdf" });

          const url = window.URL.createObjectURL(newBlob);
          window.open(url);
        }
      );
  }

  setThumbnail(attachment: AttachmentModel) {
    return this.http.post('item/custom/set-thumbnail-image', {
      item: {
        id: this.item.id
      },
      attachment: {
        id: attachment.id
      }
    }).subscribe(
      result => {
        this.recordAdded.emit();
      }
    )
  }

  toggleLabelPrint() {
    this.http.get('item/custom/toggle/label/generated/' + this.item.id)
      .subscribe(
        result => {

        }
      );
  }
}
