import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingModel } from 'app/_models/setting.model';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(public http: HttpClient) { }

  get(type: string) {
    return this.http.get('setting/' + type)
      .map(
        (response: any) => {
          return <SettingModel>response;
        }
      );
  }

  store(form: any) {
    return this.http.post('setting', form);
  }
}
