import { CharacterChangeModel } from './character-change.model';
import { AttachmentModel } from './attachment.model';
import { SetModel } from './set.model';
import { CharacterModel } from './character.model';
import { BaseModel } from "./base.model";
import { NoteModel } from './note.model';
import { CharacterChangeSceneModel } from './character-change-scene.model';

export class SceneModel extends BaseModel {
    public characters: CharacterModel[];
    public set: SetModel;
    public attachments: AttachmentModel[];
    public notes: NoteModel[];
    public character_change_scenes: CharacterChangeSceneModel[];

    constructor(public id: number = null,
        public set_id: number = null,
        public scene_number: string = null,
        public story_day: string = null,
        public day: string = null,
        public scene_length: string = null,
        public scene_summary: string = null,
        public location: string = null,
        public type: string = null,
        public shoot_start_date: any = null,
        public shoot_end_date: any = null,
        public updated_at: string = null,
        public last_updated: string = null,
        public name: string = null,
        public display_text: string = null,
        public edit_display_header: string = null,
        public display_story_day: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        //Characters
        this.characters = new Array();
        if (result["scene_characters"]) {
            for (const json of result["scene_characters"]) {
                const record = new CharacterModel();
                if (json["character"]) {
                    record.makeObjectFromJson(json["character"]);
                    this.characters.push(record);
                }
            }
        }

        //Set
        this.set = new SetModel();
        if (result["set"]) {
            this.set.makeObjectFromJson(result["set"]);
        }

        //Attachments
        this.attachments = new Array();
        if (result["scene_attachments"]) {
            for (const json of result["scene_attachments"]) {
                if (json["attachment"]) {
                    const attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }

        //Character changes
        this.character_change_scenes = new Array();
        if (result["character_change_scenes"]) {
            for (const json of result["character_change_scenes"]) {
                if (json["character_change"]) {
                    const characterChangeScene: CharacterChangeSceneModel = new CharacterChangeSceneModel();
                    characterChangeScene.makeObjectFromJson(json);
                    this.character_change_scenes.push(characterChangeScene);
                }
            }
        }

        // //Notes
        // this.notes = new Array();
        // const note = new NoteModel();
        // note.id = 1;
        // note.note = 'kjashdkjashd';
        // note.created_at_display = '01.02.19 at 12.24pm';
        // this.notes.push(note);
    }
}
