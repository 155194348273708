var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
import { RoleModel } from "./role.model";
var ProductionModel = /** @class */ (function (_super) {
    __extends(ProductionModel, _super);
    function ProductionModel(id, uuid, user_id, name, is_closed, stripboard_count, user_count, set_count, character_count, type, is_multi_factor_required, company, studio, city, state, street, country, postcode_code, address_line_1, address_line_2) {
        if (id === void 0) { id = null; }
        if (uuid === void 0) { uuid = null; }
        if (user_id === void 0) { user_id = null; }
        if (name === void 0) { name = null; }
        if (is_closed === void 0) { is_closed = null; }
        if (stripboard_count === void 0) { stripboard_count = null; }
        if (user_count === void 0) { user_count = null; }
        if (set_count === void 0) { set_count = null; }
        if (character_count === void 0) { character_count = null; }
        if (type === void 0) { type = null; }
        if (is_multi_factor_required === void 0) { is_multi_factor_required = null; }
        if (company === void 0) { company = null; }
        if (studio === void 0) { studio = null; }
        if (city === void 0) { city = null; }
        if (state === void 0) { state = null; }
        if (street === void 0) { street = null; }
        if (country === void 0) { country = null; }
        if (postcode_code === void 0) { postcode_code = null; }
        if (address_line_1 === void 0) { address_line_1 = null; }
        if (address_line_2 === void 0) { address_line_2 = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.uuid = uuid;
        _this.user_id = user_id;
        _this.name = name;
        _this.is_closed = is_closed;
        _this.stripboard_count = stripboard_count;
        _this.user_count = user_count;
        _this.set_count = set_count;
        _this.character_count = character_count;
        _this.type = type;
        _this.is_multi_factor_required = is_multi_factor_required;
        _this.company = company;
        _this.studio = studio;
        _this.city = city;
        _this.state = state;
        _this.street = street;
        _this.country = country;
        _this.postcode_code = postcode_code;
        _this.address_line_1 = address_line_1;
        _this.address_line_2 = address_line_2;
        return _this;
    }
    ProductionModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        this.roles = new Array();
        if (result["roles"]) {
            for (var _i = 0, _a = result["roles"]; _i < _a.length; _i++) {
                var json = _a[_i];
                var role = new RoleModel();
                role.makeObjectFromJson(json);
                this.roles.push(role);
            }
        }
    };
    return ProductionModel;
}(BaseModel));
export { ProductionModel };
