import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CurrencyService = /** @class */ (function () {
    function CurrencyService(http) {
        this.http = http;
    }
    CurrencyService.prototype.getAllRecords = function () {
        return this.http.get('production/custom/currency')
            .map(function (response) {
            return response;
        });
    };
    CurrencyService.ngInjectableDef = i0.defineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(i0.inject(i1.HttpClient)); }, token: CurrencyService, providedIn: "root" });
    return CurrencyService;
}());
export { CurrencyService };
