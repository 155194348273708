import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { CharacterChangeModel } from 'app/_models/character-change.model';
import { Observable } from 'rxjs';

@Injectable()
export class CharacterChangeService {

  @Output() addNewChange = new EventEmitter<any>();
  @Output() uploadPhotoToGallery = new EventEmitter<any>();
  @Output() uploadContinuity = new EventEmitter<any>();

  constructor(public http: HttpClient) { }

  getAllRecords(): Observable<CharacterChangeModel[]> {
    return this.http.get('change')
      .map(
        (response: any) => {
          return <CharacterChangeModel[]>response;
        }
      );
  }

  store(form: any) {
    return this.http.post('change', {
      character_change: form
    });
  }

  update(form: any, changeId: number) {
    return this.http.post('change/' + changeId, {
      character_change: form,
      _method: "PUT"
    });
  }

  delete(characterChangeId: number) {
    return this.http.post('change/' + characterChangeId, {
      _method: "DELETE"
    })
  }

  get(id: number) {
    return this.http.get('change/' + id)
      .map(
        (response: any) => {
          const characterChange = new CharacterChangeModel();
          characterChange.makeObjectFromJson(response);
          return characterChange;
        }
      );
  }

  assignItems(characterChangeId: number, form: any) {
    return this.http.post('change/assign-item/' + characterChangeId, {
      items: form
    }).map(
      (response: any) => {
        const record: CharacterChangeModel = new CharacterChangeModel();
        record.makeObjectFromJson(response);
        return record;
      }
    );
  }

  removeItem(characterChangeId: number, itemId: any) {
    return this.http.post('change/remove-item/' + characterChangeId, {
      itemId: itemId
    });
  }

  markAsEstablished(characterChangeId, form: any) {
    return this.http.post('change/mark-as-established/' + characterChangeId, form);
  }

  removeHeroProp(characterChangeId, form: any) {
    return this.http.post('change/remove-item/' + characterChangeId, form);
  }

  deleteAll(form: any) {
    return this.http.post('change/delete/selected', form);
  }
}
