var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
var MeasurementModel = /** @class */ (function (_super) {
    __extends(MeasurementModel, _super);
    function MeasurementModel(id, production_id, measurement, measurement_value, unit_value, element_type, elements) {
        if (id === void 0) { id = null; }
        if (production_id === void 0) { production_id = null; }
        if (measurement === void 0) { measurement = null; }
        if (measurement_value === void 0) { measurement_value = null; }
        if (unit_value === void 0) { unit_value = null; }
        if (element_type === void 0) { element_type = null; }
        if (elements === void 0) { elements = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.production_id = production_id;
        _this.measurement = measurement;
        _this.measurement_value = measurement_value;
        _this.unit_value = unit_value;
        _this.element_type = element_type;
        _this.elements = elements;
        return _this;
    }
    return MeasurementModel;
}(BaseModel));
export { MeasurementModel };
