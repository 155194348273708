import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class GalleryService {

  constructor(public http: HttpClient) { }

  store(form: any, galleryId: any) {
    return this.http.post('gallery/' + galleryId, form);
  }

  deleteSelected(form: any) {
    return this.http.post('gallery/delete/selected', form);
  }
}
