var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSettingService } from 'app/_services/user-setting.service';
var MatchColumnsComponent = /** @class */ (function () {
    function MatchColumnsComponent(activeModal, fb, http, userSettingService) {
        this.activeModal = activeModal;
        this.fb = fb;
        this.http = http;
        this.userSettingService = userSettingService;
        this.columnsSet = new Array();
        this.columnsSelectedSet = new Array();
    }
    Object.defineProperty(MatchColumnsComponent.prototype, "columns", {
        get: function () {
            return this._columns;
        },
        set: function (value) {
            this._columns = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatchColumnsComponent.prototype, "section", {
        get: function () {
            return this._section;
        },
        set: function (value) {
            this._section = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatchColumnsComponent.prototype, "dataLink", {
        get: function () {
            return this._dataLink;
        },
        set: function (value) {
            this._dataLink = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatchColumnsComponent.prototype, "submitLink", {
        get: function () {
            return this._submitLink;
        },
        set: function (value) {
            this._submitLink = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatchColumnsComponent.prototype, "postBody", {
        get: function () {
            return this._postBody;
        },
        set: function (value) {
            this._postBody = value;
        },
        enumerable: true,
        configurable: true
    });
    MatchColumnsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            attachment: this.fb.group({
                id: [null, [Validators.required]]
            })
        });
        this.rowsImportForm = this.fb.group({
            rows_import: this.fb.array([])
        });
        return this.http.get(this.dataLink)
            .subscribe(function (result) {
            result = result;
            _this.rows = result.rows;
            _this.rowsToImport = result.columns;
            _this.makeData();
            _this.makeRowData();
        });
    };
    MatchColumnsComponent.prototype.makeRowData = function () {
        var records = this.rowsImportForm.get('rows_import');
        for (var _i = 0, _a = this.rowsToImport; _i < _a.length; _i++) {
            var row = _a[_i];
            var record = this.fb.group({
                label: [row.Label, [Validators.required]],
                columns: this.fb.array([])
            });
            var _columns = record.get('columns');
            for (var _b = 0, _c = row.Rows; _b < _c.length; _b++) {
                var __row = _c[_b];
                _columns.push(this.fb.group({
                    label: [__row],
                    is_selected: [true]
                }));
            }
            records.push(record);
        }
    };
    MatchColumnsComponent.prototype.makeData = function () {
        var length = this.columns.length;
        var i = 0;
        while (i < length) {
            this.columnsSet[i] = this.columns.slice();
            if (this.rows && this.rows[0] && this.rows[0][this.columns[i].text] !== undefined) {
                this.columnsSelectedSet[i] = __assign({}, this.columns[i]);
            }
            else {
                this.columnsSelectedSet[i] = null;
            }
            i++;
        }
    };
    MatchColumnsComponent.prototype.importClicked = function () {
        var _this = this;
        this.formSubmitLoader = true;
        var columns = new Array();
        for (var i = 0; i < this.columns.length; i++) {
            if (this.columnsSelectedSet[i] && this.columnsSelectedSet[i].text) {
                columns.push({
                    id: this.columns[i].id,
                    column: this.columnsSelectedSet[i].text
                });
            }
        }
        var postBody = __assign({}, this.postBody, { columns: columns });
        postBody = __assign({}, postBody, this.rowsImportForm.value);
        this.http.post(this.submitLink, postBody)
            .subscribe(function (result) {
            _this.activeModal.close();
        }, function (error) {
            _this.formSubmitLoader = false;
        });
    };
    MatchColumnsComponent.prototype.columnSelected = function (col, _index) {
        // for(let i = 0; i < this.columnsSelectedSet.length; i++){
        //   console.log(this.columnsSelectedSet[i]);
        //   let columns = [...this.columns];
        //   for(let _column of columns){
        //     _column = {..._column, ...{disabled: true}};
        //   }
        //   console.log(columns);
        // }
    };
    return MatchColumnsComponent;
}());
export { MatchColumnsComponent };
