import { FormArray } from '@angular/forms';
import { EventEmitter, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AttachmentModel } from '../../_models/attachment.model';
var FileUploadComponent = /** @class */ (function () {
    function FileUploadComponent() {
        this.uploadInProgress = false;
        this.filesAdded = new EventEmitter();
        this.filesRemoved = new EventEmitter();
        this.makeAsPrimary = new EventEmitter();
        this._hideImages = false;
        this._type = null;
        this.attachments = new Array();
        this.url = environment.attachmentUploadUrl;
    }
    Object.defineProperty(FileUploadComponent.prototype, "existingAttachments", {
        get: function () {
            return this.attachments;
        },
        set: function (value) {
            this.attachments = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploadComponent.prototype, "hideImages", {
        get: function () {
            return this._hideImages;
        },
        set: function (value) {
            this._hideImages = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploadComponent.prototype, "form", {
        set: function (value) {
            this._form = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploadComponent.prototype, "type", {
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
            if (value === 'Item')
                this.attachments = new Array();
        },
        enumerable: true,
        configurable: true
    });
    FileUploadComponent.prototype.ngOnInit = function () {
        if ((this.type == 'Item' || this.type == 'Vendor') && this._form)
            this.attachments = this._form.value;
    };
    FileUploadComponent.prototype.beforeRequest = function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer' + localStorage.getItem('token'));
        xhr.setRequestHeader('uuid', JSON.parse(localStorage.getItem('currentProduction')).uuid);
    };
    // @Output binding, set uploadInProgress to true to display loading icon
    FileUploadComponent.prototype.filesUpdated = function (files) {
        this.uploadInProgress = true;
    };
    // @Output binding, handle successful upload
    FileUploadComponent.prototype.fileUploaded = function (event) {
        this.uploadInProgress = false;
        var attachment = new AttachmentModel();
        if (event[1]) {
            attachment.makeObjectFromJson(JSON.parse(event[1]));
            if (this.attachments === undefined) {
                this.attachments = new Array();
            }
            this.attachments.push(attachment);
            this.filesAdded.emit(attachment);
        }
    };
    FileUploadComponent.prototype.fileRemoved = function (attachment) {
        var index = this.attachments.findIndex(function (item) { return item.id === attachment.id; });
        if (index !== -1) {
            this.attachments.splice(index, 1);
            this.filesRemoved.emit(attachment);
        }
    };
    FileUploadComponent.prototype.beforeFileUpload = function (formData) {
        return formData;
    };
    FileUploadComponent.prototype.getTemplate = function () {
        if (this.type === 'Order' || this.type === 'Vendor' || this.type === 'Item') {
            return '<div class=" xt-form-btn"><button class="btn btn-primary text-center font-medium-1">Choose a file to upload</button></div>';
        }
        else {
            return ('<div class="xt-upload-container">\n' +
                '                <div class="xt-upload-logo-icon">\n' +
                '                    <i class="ft-upload text-bold-600"></i>\n' +
                '                </div>\n' +
                '            </div>');
        }
    };
    FileUploadComponent.prototype.downloadFile = function (attachment) {
        window.open(attachment.download_path, '_blank' // <- This is what makes it open in a new window.
        );
    };
    return FileUploadComponent;
}());
export { FileUploadComponent };
