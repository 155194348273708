/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./light-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./light-box.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common/http";
var styles_LightBoxComponent = [i0.styles];
var RenderType_LightBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LightBoxComponent, data: {} });
export { RenderType_LightBoxComponent as RenderType_LightBoxComponent };
function View_LightBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-bold-400"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeImage.file_name; _ck(_v, 1, 0, currVal_0); }); }
function View_LightBoxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Random slide"], ["class", "xt-lightbox-img img-fluid"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeImage.file_path; _ck(_v, 0, 0, currVal_0); }); }
function View_LightBoxComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg bg-dark text-white mb-0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-left"]], null, null, null, null, null))], null, null); }
function View_LightBoxComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg bg-dark text-white mb-0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-right"]], null, null, null, null, null))], null, null); }
function View_LightBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "modal-body text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "picsum-img-wrapper pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightBoxComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "fixed-bottom col-6 pb-1 mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "btn-group bg-dark align-items-center rounded"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightBoxComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "text-white p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " of ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightBoxComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeImage; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.getSelectedIndex() > 1); _ck(_v, 8, 0, currVal_1); var currVal_4 = (_co.attachments.length !== _co.getSelectedIndex()); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getSelectedIndex(); var currVal_3 = _co.attachments.length; _ck(_v, 10, 0, currVal_2, currVal_3); }); }
export function View_LightBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal-header xt-model-fixed-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "h6", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-left mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-md-8 text-center pt-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightBoxComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-md-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary mb-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightBoxComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeImage; _ck(_v, 11, 0, currVal_0); var currVal_1 = _co.attachments; _ck(_v, 16, 0, currVal_1); }, null); }
export function View_LightBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-light-box", [], null, null, null, View_LightBoxComponent_0, RenderType_LightBoxComponent)), i1.ɵdid(1, 114688, null, 0, i3.LightBoxComponent, [i4.NgbActiveModal, i5.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LightBoxComponentNgFactory = i1.ɵccf("app-light-box", i3.LightBoxComponent, View_LightBoxComponent_Host_0, { type: "type", filename: "filename", recordId: "recordId" }, {}, []);
export { LightBoxComponentNgFactory as LightBoxComponentNgFactory };
