<!-- START Notification Sidebar -->
<aside id="queue-sidebar" class="notification-sidebar d-none d-sm-none d-md-block " [class.open]="queueService.open">
  <div class="row mx-0 align-items  ">
    <div class="col-md-8 px-4 pt-2 ">
      <h5 class="mt-1 mb-3 text-bold-400 text-dark">Your Queue</h5>
    </div>
    <div class="col-md-4 text-right mt-1">
      <a (click)="queueService.open = false">
        <i class="ft-x text-muted  fa-3x"></i>
      </a>
    </div>
    <div class="col-md-10 mx-auto border-bottom mt-1"></div>
  </div>
  <div class="side-nav notification-sidebar-content xt-sidebar-content">
    <div class="row mx-0">
      <div class="col-md-12 mt-1 px-4">
        <div>
          <div class="collection border-none">
            <div class="xt-sidebar-queue border-bottom my-2" *ngFor="let item of queueService.items; let i = index">
              <div class="media mb-3">
                <a>
                  <img alt="96x96" class="media-object d-flex mr-3 width-100 height-100" src="{{ item.pic }}">
                </a>
                <div class="media-body">
                  <div class="clearfix">
                    <h6 class=" mt-1 mb-0 mr-auto float-left" [innerHTML]="item.name"></h6>
                    <button class="empty-button float-right text-muted font-medium-3" aria-label="Remove" (click)="removeItem(i)">
                      <i class="ft-x fa-2x text-light" aria-hidden="true"></i>
                    </button>
                  </div>
                  <p class="text-muted font-small-2 mb-1" [innerHTML]="item.transaction_number"></p>
                  <div class="d-flex">
                    <label class="mr-1 d-block">Qty:</label>
                    <app-common-counter [quantity]="item.max_wrapper_quantity"
                      (quantityUpdated)="item.inventory_status_quantity = $event"></app-common-counter>
                  </div>

                </div>
              </div>
            </div>


            <div class="text-right border-bottom">
              <p class="font-small-3 text-bold-400 mb-2 text-center">
                <a class="text-muted" (click)="clearQueue()">Clear Queue</a>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row footer mx-0">
      <div class="col-md-12 mb-4 p-2">
        <div ngbDropdown placement="top" class="display-inline-block">
          <button class="btn btn-flat btn-outline-primary btn-lg mr-1" id="dropdownBasic1" ngbDropdownToggle>Assign
            To</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" type="button" (click)="assignToSetClicked()">Set</button>
            <button class="dropdown-item" type="button" (click)="assignToCharacterClicked()">Character</button>
            <button class="dropdown-item" type="button" (click)="assignToChangeClicked('Change')">Change</button>
            <button class="dropdown-item" type="button" (click)="assignToChangeClicked('Look')">Look</button>
          </div>
        </div>
        <button class="btn btn-primary btn-lg" type="button" (click)="wrapItemsClicked()">Wrap Items</button>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar -->
