/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coming-soon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./coming-soon.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ComingSoonComponent = [i0.styles];
var RenderType_ComingSoonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComingSoonComponent, data: {} });
export { RenderType_ComingSoonComponent as RenderType_ComingSoonComponent };
export function View_ComingSoonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header pt-0 no-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Coming Soon"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We are working on this and will be published very soon"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-footer pb-0 no-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary mr-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
export function View_ComingSoonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-coming-soon", [], null, null, null, View_ComingSoonComponent_0, RenderType_ComingSoonComponent)), i1.ɵdid(1, 114688, null, 0, i2.ComingSoonComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComingSoonComponentNgFactory = i1.ɵccf("app-coming-soon", i2.ComingSoonComponent, View_ComingSoonComponent_Host_0, {}, {}, []);
export { ComingSoonComponentNgFactory as ComingSoonComponentNgFactory };
