var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CharacterChangeSceneModel } from './character-change-scene.model';
import { CharacterChangeItemModel } from './character-change-item.model';
import { GalleryModel } from './gallery.model';
import { CharacterModel } from './character.model';
import { AttachmentModel } from './attachment.model';
import { BaseModel } from "./base.model";
var CharacterChangeModel = /** @class */ (function (_super) {
    __extends(CharacterChangeModel, _super);
    function CharacterChangeModel(id, story_days, name, pic, type) {
        if (id === void 0) { id = null; }
        if (story_days === void 0) { story_days = null; }
        if (name === void 0) { name = null; }
        if (pic === void 0) { pic = null; }
        if (type === void 0) { type = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.story_days = story_days;
        _this.name = name;
        _this.pic = pic;
        _this.type = type;
        return _this;
    }
    CharacterChangeModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Scene
        this.scenes = new Array();
        this.character_change_scenes = new Array();
        if (result["character_change_scenes"]) {
            for (var _i = 0, _a = result["character_change_scenes"]; _i < _a.length; _i++) {
                var json = _a[_i];
                if (json) {
                    var characterChangeScene = new CharacterChangeSceneModel();
                    characterChangeScene.makeObjectFromJson(json);
                    this.character_change_scenes.push(characterChangeScene);
                    this.scenes.push(characterChangeScene.scene);
                }
            }
        }
        //Gallery
        if (result["character_change_galleries"]) {
            this.galleries = new Array();
            for (var _b = 0, _c = result["character_change_galleries"]; _b < _c.length; _b++) {
                var json = _c[_b];
                if (json["gallery"]) {
                    var gallery = new GalleryModel();
                    gallery.makeObjectFromJson(json["gallery"]);
                    this.galleries.push(gallery);
                }
            }
        }
        //Character
        if (result["character"]) {
            this.character = new CharacterModel();
            this.character.makeObjectFromJson(result["character"]);
        }
        // Attachments
        this.attachments = new Array();
        if (result["character_change_attachments"]) {
            for (var _d = 0, _e = result["character_change_attachments"]; _d < _e.length; _d++) {
                var json = _e[_d];
                if (json["attachment"]) {
                    var attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }
        //Character Change Item
        this.items = new Array();
        if (result["character_change_items"]) {
            for (var _f = 0, _g = result["character_change_items"]; _f < _g.length; _f++) {
                var json = _g[_f];
                if (json["item"]) {
                    var item = new CharacterChangeItemModel();
                    item.makeObjectFromJson(json);
                    this.items.push(item);
                }
            }
        }
    };
    CharacterChangeModel.prototype.getStoryDay = function () {
        if (this.story_days && this.story_days[0]['story_day']) {
            return this.story_days[0]['story_day'];
        }
        return '';
    };
    return CharacterChangeModel;
}(BaseModel));
export { CharacterChangeModel };
