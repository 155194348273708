/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-queue.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/common-counter/common-counter.component.ngfactory";
import * as i3 from "../../shared/common-counter/common-counter.component";
import * as i4 from "@angular/common";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./item-queue.component";
import * as i7 from "../../_services/queue.service";
var styles_ItemQueueComponent = [i0.styles];
var RenderType_ItemQueueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemQueueComponent, data: {} });
export { RenderType_ItemQueueComponent as RenderType_ItemQueueComponent };
function View_ItemQueueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "xt-sidebar-queue border-bottom my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "media mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "96x96"], ["class", "media-object d-flex mr-3 width-100 height-100"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "media-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "h6", [["class", " mt-1 mb-0 mr-auto float-left"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["aria-label", "Remove"], ["class", "empty-button float-right text-muted font-medium-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeItem(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "ft-x fa-2x text-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "p", [["class", "text-muted font-small-2 mb-1"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["class", "mr-1 d-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Qty:"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-common-counter", [], null, [[null, "quantityUpdated"]], function (_v, en, $event) { var ad = true; if (("quantityUpdated" === en)) {
        var pd_0 = ((_v.context.$implicit.inventory_status_quantity = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CommonCounterComponent_0, i2.RenderType_CommonCounterComponent)), i1.ɵdid(14, 114688, null, 0, i3.CommonCounterComponent, [], { quantity: [0, "quantity"] }, { quantityUpdated: "quantityUpdated" })], function (_ck, _v) { var currVal_3 = _v.context.$implicit.max_wrapper_quantity; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.pic, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.transaction_number; _ck(_v, 9, 0, currVal_2); }); }
export function View_ItemQueueComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 43, "aside", [["class", "notification-sidebar d-none d-sm-none d-md-block "], ["id", "queue-sidebar"]], [[2, "open", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row mx-0 align-items  "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-8 px-4 pt-2 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "mt-1 mb-3 text-bold-400 text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Queue"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-4 text-right mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.queueService.open = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "ft-x text-muted  fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-md-10 mx-auto border-bottom mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 34, "div", [["class", "side-nav notification-sidebar-content xt-sidebar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 9, "div", [["class", "row mx-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "col-md-12 mt-1 px-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "collection border-none"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemQueueComponent_1)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "text-right border-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "p", [["class", "font-small-3 text-bold-400 mb-2 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "a", [["class", "text-muted"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearQueue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Clear Queue"])), (_l()(), i1.ɵeld(20, 0, null, null, 23, "div", [["class", "row footer mx-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 22, "div", [["class", "col-md-12 mb-4 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 19, "div", [["class", "display-inline-block"], ["ngbDropdown", ""], ["placement", "top"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(23, 737280, null, 3, i5.NgbDropdown, [i1.ChangeDetectorRef, i5.NgbDropdownConfig, i4.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _menuElement: 0 }), i1.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(27, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-flat btn-outline-primary btn-lg mr-1 dropdown-toggle"], ["id", "dropdownBasic1"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 28).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 28).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 28).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 28).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 16384, null, 0, i5.NgbDropdownToggle, [i5.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i5.NgbDropdownAnchor, null, [i5.NgbDropdownToggle]), (_l()(), i1.ɵted(-1, null, ["Assign To"])), (_l()(), i1.ɵeld(31, 0, [[2, 0]], null, 10, "div", [["aria-labelledby", "dropdownBasic1"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 32).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 32).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 32).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 32).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 32).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 16384, [[1, 4]], 1, i5.NgbDropdownMenu, [i5.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "dropdown-item"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignToSetClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Set"])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "button", [["class", "dropdown-item"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignToCharacterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Character"])), (_l()(), i1.ɵeld(38, 0, null, null, 1, "button", [["class", "dropdown-item"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignToChangeClicked("Change") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Change"])), (_l()(), i1.ɵeld(40, 0, null, null, 1, "button", [["class", "dropdown-item"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignToChangeClicked("Look") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Look"])), (_l()(), i1.ɵeld(42, 0, null, null, 1, "button", [["class", "btn btn-primary btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.wrapItemsClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Wrap Items"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.queueService.items; _ck(_v, 15, 0, currVal_1); var currVal_3 = "top"; _ck(_v, 23, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.queueService.open; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 23).isOpen(); _ck(_v, 22, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 28).dropdown.isOpen(); _ck(_v, 27, 0, currVal_4); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 32).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 32).placement; _ck(_v, 31, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_ItemQueueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item-queue", [], null, null, null, View_ItemQueueComponent_0, RenderType_ItemQueueComponent)), i1.ɵdid(1, 114688, null, 0, i6.ItemQueueComponent, [i7.QueueService, i5.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemQueueComponentNgFactory = i1.ɵccf("app-item-queue", i6.ItemQueueComponent, View_ItemQueueComponent_Host_0, {}, { recordAdded: "recordAdded" }, []);
export { ItemQueueComponentNgFactory as ItemQueueComponentNgFactory };
