import { StripboardColorSceneModel } from './../_models/stripboard-color-scene.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class StripboardColorSceneService {

  constructor(public http: HttpClient) { }

  get(id: number) {
    return this.http.get("stripboard-color-scene/" + id)
      .map(
        (response: any) => {
          const record: StripboardColorSceneModel = new StripboardColorSceneModel();
          record.makeObjectFromJson(response);
          return record;
        }
      );
  }
}
