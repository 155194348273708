var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AttachmentModel } from './attachment.model';
import { SetModel } from './set.model';
import { CharacterModel } from './character.model';
import { BaseModel } from "./base.model";
import { CharacterChangeSceneModel } from './character-change-scene.model';
var SceneModel = /** @class */ (function (_super) {
    __extends(SceneModel, _super);
    function SceneModel(id, set_id, scene_number, story_day, day, scene_length, scene_summary, location, type, shoot_start_date, shoot_end_date, updated_at, last_updated, name, display_text, edit_display_header, display_story_day) {
        if (id === void 0) { id = null; }
        if (set_id === void 0) { set_id = null; }
        if (scene_number === void 0) { scene_number = null; }
        if (story_day === void 0) { story_day = null; }
        if (day === void 0) { day = null; }
        if (scene_length === void 0) { scene_length = null; }
        if (scene_summary === void 0) { scene_summary = null; }
        if (location === void 0) { location = null; }
        if (type === void 0) { type = null; }
        if (shoot_start_date === void 0) { shoot_start_date = null; }
        if (shoot_end_date === void 0) { shoot_end_date = null; }
        if (updated_at === void 0) { updated_at = null; }
        if (last_updated === void 0) { last_updated = null; }
        if (name === void 0) { name = null; }
        if (display_text === void 0) { display_text = null; }
        if (edit_display_header === void 0) { edit_display_header = null; }
        if (display_story_day === void 0) { display_story_day = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.set_id = set_id;
        _this.scene_number = scene_number;
        _this.story_day = story_day;
        _this.day = day;
        _this.scene_length = scene_length;
        _this.scene_summary = scene_summary;
        _this.location = location;
        _this.type = type;
        _this.shoot_start_date = shoot_start_date;
        _this.shoot_end_date = shoot_end_date;
        _this.updated_at = updated_at;
        _this.last_updated = last_updated;
        _this.name = name;
        _this.display_text = display_text;
        _this.edit_display_header = edit_display_header;
        _this.display_story_day = display_story_day;
        return _this;
    }
    SceneModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Characters
        this.characters = new Array();
        if (result["scene_characters"]) {
            for (var _i = 0, _a = result["scene_characters"]; _i < _a.length; _i++) {
                var json = _a[_i];
                var record = new CharacterModel();
                if (json["character"]) {
                    record.makeObjectFromJson(json["character"]);
                    this.characters.push(record);
                }
            }
        }
        //Set
        this.set = new SetModel();
        if (result["set"]) {
            this.set.makeObjectFromJson(result["set"]);
        }
        //Attachments
        this.attachments = new Array();
        if (result["scene_attachments"]) {
            for (var _b = 0, _c = result["scene_attachments"]; _b < _c.length; _b++) {
                var json = _c[_b];
                if (json["attachment"]) {
                    var attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }
        //Character changes
        this.character_change_scenes = new Array();
        if (result["character_change_scenes"]) {
            for (var _d = 0, _e = result["character_change_scenes"]; _d < _e.length; _d++) {
                var json = _e[_d];
                if (json["character_change"]) {
                    var characterChangeScene = new CharacterChangeSceneModel();
                    characterChangeScene.makeObjectFromJson(json);
                    this.character_change_scenes.push(characterChangeScene);
                }
            }
        }
        // //Notes
        // this.notes = new Array();
        // const note = new NoteModel();
        // note.id = 1;
        // note.note = 'kjashdkjashd';
        // note.created_at_display = '01.02.19 at 12.24pm';
        // this.notes.push(note);
    };
    return SceneModel;
}(BaseModel));
export { SceneModel };
