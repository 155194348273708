import { SceneModel } from 'app/_models/scene.model';
import { CharacterChangeModel } from '../_models/character-change.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CharacterModel } from '../_models/character.model';
import { Injectable } from '@angular/core';

@Injectable()
export class CharacterService {

  constructor(public http: HttpClient) { }

  get(id: number) {
    return this.http.get("character/" + id)
      .map(
        (response: any) => {
          return <CharacterModel>response;
        }
      );
  }

  getChanges(id: number, type: string): Observable<CharacterChangeModel[]> {
    return this.http.get('character/' + id + '/get-changes/' + type)
      .map(
        (response: any) => {
          return <CharacterChangeModel[]>response["changes"];
        }
      );
  }

  getScenes(id: number): Observable<SceneModel[]> {
    return this.http.get('character/' + id + '/get-scenes')
      .map(
        (response: any) => {
          return <SceneModel[]>response;
        }
      );
  }

  getAllRecords() {
    return this.http.get("all/character")
      .map(
        (response: any) => {
          const records: CharacterModel[] = [];
          for (const json of response) {
            const record: CharacterModel = new CharacterModel();
            record.makeObjectFromJson(json);
            records.push(record);
          }
          return records;
        }
      );
  }

  getCharactersWithChanges(form: any) {
    return this.http.post("character/get-characters", form)
      .map(
        (response: any) => {
          const records: CharacterModel[] = [];
          for (const json of response) {
            const record: CharacterModel = new CharacterModel();
            record.makeObjectFromJson(json);
            records.push(record);
          }
          return records;
        }
      );
  }

  store(form: any) {
    return this.http.post("character", { character: form });
  }

  update(id: number, form: any) {
    return this.http.post("character/" + id, { character: form, _method: "PUT" })
      .map(
        (response: any) => {
          const record = new CharacterModel();
          record.makeObjectFromJson(response);
          return record;
        }
      );
  }

  getRecordsForSelect2Items(records: CharacterModel[]) {
    const result: any[] = new Array();
    for (const record of records) {
      result.push(
        {
          id: record.id,
          text: record.display_text,
          pic: record.pic
        }
      );
    }
    return result;
  }

  delete(id: number) {
    return this.http.post("character/" + id, {
      _method: "DELETE"
    })
  }

  assignHeroProps(characterId: number, form: any) {
    return this.http.post('character/' + characterId + '/assign-hero-prop', form);
  }

  markAsEstablished(characterId, form: any) {
    return this.http.post('character/mark-as-established/' + characterId, form);
  }

  deleteHeroProps(form: any, characterId: number) {
    return this.http.post('character/' + characterId + '/delete-hero-props', form)
  }

  getLastUpdateTime() {
    return this.http.get('character/custom/last-update').map((response: any) => {
      if (response) return <string>response.data
      else return null
    });
  }
}
