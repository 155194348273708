var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { MatchColumnsComponent } from './../match-columns/match-columns.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSettingService } from 'app/_services/user-setting.service';
var UploadCsvComponent = /** @class */ (function () {
    function UploadCsvComponent(activeModal, modalService, userSettingService, fb, http) {
        this.activeModal = activeModal;
        this.modalService = modalService;
        this.userSettingService = userSettingService;
        this.fb = fb;
        this.http = http;
        this._section = '';
    }
    Object.defineProperty(UploadCsvComponent.prototype, "columns", {
        get: function () {
            return this._columns;
        },
        set: function (value) {
            this._columns = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadCsvComponent.prototype, "section", {
        get: function () {
            return this._section;
        },
        set: function (value) {
            this._section = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadCsvComponent.prototype, "dataLink", {
        get: function () {
            return this._dataLink;
        },
        set: function (value) {
            this._dataLink = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadCsvComponent.prototype, "submitLink", {
        get: function () {
            return this._submitLink;
        },
        set: function (value) {
            this._submitLink = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadCsvComponent.prototype, "postBody", {
        get: function () {
            return this._postBody;
        },
        set: function (value) {
            this._postBody = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadCsvComponent.prototype, "useSmallModal", {
        get: function () {
            return this._useSmallModal;
        },
        set: function (value) {
            this._useSmallModal = value;
        },
        enumerable: true,
        configurable: true
    });
    UploadCsvComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group({
            attachments: this.fb.array([])
        });
    };
    UploadCsvComponent.prototype.matchUploadData = function () {
        var postBody = __assign({}, this.postBody, {
            attachment: {
                id: this.form.value.attachments[0].id
            }
        });
        var modalRef = this.modalService.open(MatchColumnsComponent, {
            windowClass: "modal-md modal-xxl xt-full-screen-modal"
        }).componentInstance;
        modalRef.columns = this.columns;
        modalRef.dataLink = this.dataLink + this.form.value.attachments[0].id;
        modalRef.submitLink = this.submitLink;
        modalRef.postBody = postBody;
        modalRef.section = this.section;
        this.activeModal.close();
    };
    UploadCsvComponent.prototype.addArrayForAttachment = function (attachment) {
        var _this = this;
        var records = this.form.get('attachments');
        records.push(this.fb.group({
            id: [attachment.id]
        }));
        this.http.get(this.dataLink + attachment.id)
            .subscribe(function (result) {
        }, function (error) {
            _this.attachments = new Array();
            while (records.length !== 0) {
                records.removeAt(0);
            }
        });
    };
    UploadCsvComponent.prototype.removeAttachment = function (attachment) {
        var records = this.form.get('attachments')['controls'];
        var index = records.findIndex(function (item) { return item.value.id === attachment.id; });
        if (index !== -1) {
            var attachments = this.form.get('attachments');
            attachments.removeAt(index);
        }
    };
    return UploadCsvComponent;
}());
export { UploadCsvComponent };
