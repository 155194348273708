import { Router } from '@angular/router';
import { AuthService } from './../_services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/auth.service";
import * as i2 from "@angular/router";
var PasswordGuard = /** @class */ (function () {
    function PasswordGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    PasswordGuard.prototype.canActivate = function (next, state) {
        var currentUser = this.authService.currentUserValue;
        if (currentUser.change_password !== true) {
            this.router.navigate(['auth/change-password'], { queryParams: { returnUrl: state.url } });
        }
        else {
            return true;
        }
    };
    PasswordGuard.ngInjectableDef = i0.defineInjectable({ factory: function PasswordGuard_Factory() { return new PasswordGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: PasswordGuard, providedIn: "root" });
    return PasswordGuard;
}());
export { PasswordGuard };
