import { CharacterChangeModel } from 'app/_models/character-change.model';
import { SceneModel } from 'app/_models/scene.model';
import { BaseModel } from "./base.model";

export class CharacterChangeSceneModel extends BaseModel {
    public scene: SceneModel;
    public character_change: CharacterChangeModel;

    constructor(public id: number = null,
        public production_id: number = null,
        public character_change_id: number = null,
        public scene_id: number = null) {
        super();
    }

    makeObjectFromJson(result: any[]) {
        super.makeObjectFromJson(result);

        //Scene
        if(result["scene"]){
            this.scene = new SceneModel();
            this.scene.makeObjectFromJson(result["scene"]);
        }

        //Character Change
        if(result["character_change"]){
            this.character_change = new CharacterChangeModel();
            this.character_change.makeObjectFromJson(result["character_change"]);
        }
    }
}