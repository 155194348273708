import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class OrderDeliveryService {

    constructor(public http: HttpClient) {
    }

    store(orderId: number, form: any) {
        return this.http.post("order/" + orderId + "/delivery-note", {order_delivery_notes: form});
    }

}
