/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./common-upload-file.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../file-upload/file-upload.component.ngfactory";
import * as i5 from "../file-upload/file-upload.component";
import * as i6 from "./common-upload-file.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../_services/user-setting.service";
import * as i9 from "@angular/common/http";
var styles_CommonUploadFileComponent = [i0.styles];
var RenderType_CommonUploadFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommonUploadFileComponent, data: {} });
export { RenderType_CommonUploadFileComponent as RenderType_CommonUploadFileComponent };
function View_CommonUploadFileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
export function View_CommonUploadFileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit(_co.form) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-header pt-0 no-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonUploadFileComponent_1)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-file-upload", [], null, [[null, "filesAdded"], [null, "filesRemoved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesAdded" === en)) {
        var pd_0 = (_co.addArrayForAttachment($event) !== false);
        ad = (pd_0 && ad);
    } if (("filesRemoved" === en)) {
        var pd_1 = (_co.removeAttachment($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_FileUploadComponent_0, i4.RenderType_FileUploadComponent)), i1.ɵdid(13, 114688, null, 0, i5.FileUploadComponent, [], { existingAttachments: [0, "existingAttachments"] }, { filesAdded: "filesAdded", filesRemoved: "filesRemoved" }), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["class", "modal-footer pb-0 no-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary mr-1"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(19, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_9 = _co.description; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.attachments; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (_co.title || "Upload files"); _ck(_v, 8, 0, currVal_8); var currVal_11 = _co.formSubmitLoader; _ck(_v, 15, 0, currVal_11); var currVal_12 = (_co.formSubmitLoader || _co.form.invalid); _ck(_v, 17, 0, currVal_12); var currVal_13 = (_co.formSubmitLoader ? _co.userSettingService.getSpinnerClass() : "fa fa-check-square-o"); _ck(_v, 18, 0, currVal_13); var currVal_14 = (_co.uploadActionLabel || "Add Photos"); _ck(_v, 19, 0, currVal_14); }); }
export function View_CommonUploadFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-common-upload-file", [], null, null, null, View_CommonUploadFileComponent_0, RenderType_CommonUploadFileComponent)), i1.ɵdid(1, 114688, null, 0, i6.CommonUploadFileComponent, [i7.NgbActiveModal, i2.FormBuilder, i8.UserSettingService, i9.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommonUploadFileComponentNgFactory = i1.ɵccf("app-common-upload-file", i6.CommonUploadFileComponent, View_CommonUploadFileComponent_Host_0, { title: "title", description: "description", uploadActionLabel: "uploadActionLabel" }, { recordAdded: "recordAdded" }, []);
export { CommonUploadFileComponentNgFactory as CommonUploadFileComponentNgFactory };
