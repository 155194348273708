import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { AttachmentModel } from '../../_models/attachment.model';
import { Component, OnInit, Output, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { UserSettingService } from 'app/_services/user-setting.service';

@Component({
  selector: 'app-common-upload-file',
  templateUrl: './common-upload-file.component.html',
  styleUrls: ['./common-upload-file.component.scss']
})

export class CommonUploadFileComponent implements OnInit {

  @Input() public title: string
  @Input() public description: string
  @Input() public uploadActionLabel: string

  public attachments: AttachmentModel[];
  public form: FormGroup;
  public formSubmitLoader: boolean;

  public _body: any;
  set body(value: any) {
    this._body = value;
  }
  get body() {
    return this._body;
  }

  private _link: string;
  set link(value: string) {
    this._link = value;
  }

  get link() {
    return this._link;
  }

  @Output() recordAdded = new EventEmitter<any>();
  constructor(public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public userSettingService: UserSettingService,
    public http: HttpClient) {
    this.attachments = [];
    this.formSubmitLoader = false;
  }

  ngOnInit() {
    this.form = this.fb.group({
      attachments: this.fb.array([])
    });
  }

  addArrayForAttachment(attachment: AttachmentModel) {
    const records = this.form.get('attachments') as FormArray;

    records.push(this.fb.group({
      id: [attachment.id]
    }));
  }

  removeAttachment(attachment: AttachmentModel) {
    const records = this.form.get('attachments')['controls'];

    const index = records.findIndex(item => item.value.id === attachment.id);
    if (index !== -1) {
      const attachments = this.form.get('attachments') as FormArray;
      attachments.removeAt(index);
    }
  }

  onSubmit(form: FormGroup) {
    this.formSubmitLoader = true;

    if (this.link) {
      // Post the uploaded file(s) to the url provided
      const postBody = { ...this.form.value, ...this.body };
      this.http.post(this.link, postBody)
        .subscribe(
          result => {
            this.recordAdded.emit();
            this.activeModal.close();
          },
          error => {
            this.formSubmitLoader = false;
          }
        );
    } else {
      // If no URL exists to post to, return the attachements to the parent with the emit event
      this.recordAdded.emit(this.attachments);
      this.activeModal.close();
    }
  }
}
