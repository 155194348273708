var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as i0 from "@angular/core";
var QueueService = /** @class */ (function () {
    function QueueService() {
        this.items = new Array();
        this.open = false;
    }
    QueueService.prototype.push = function (item) {
        if (this.items.findIndex(function (_item) { return _item.id === item.id; }) === -1) {
            this.items.push(item);
        }
        this.open = true;
    };
    QueueService.prototype.addToQueue = function (row) {
        var item = __assign({}, row);
        this.push(item);
    };
    QueueService.prototype.clearQueue = function () {
        this.items = [];
        this.open = false;
    };
    QueueService.ngInjectableDef = i0.defineInjectable({ factory: function QueueService_Factory() { return new QueueService(); }, token: QueueService, providedIn: "root" });
    return QueueService;
}());
export { QueueService };
