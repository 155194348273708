var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
var RoleModel = /** @class */ (function (_super) {
    __extends(RoleModel, _super);
    function RoleModel(id, name, slug, account_number, collaborator_count, guest_count, moderator_count) {
        if (id === void 0) { id = null; }
        if (name === void 0) { name = null; }
        if (slug === void 0) { slug = null; }
        if (account_number === void 0) { account_number = null; }
        if (collaborator_count === void 0) { collaborator_count = null; }
        if (guest_count === void 0) { guest_count = null; }
        if (moderator_count === void 0) { moderator_count = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.name = name;
        _this.slug = slug;
        _this.account_number = account_number;
        _this.collaborator_count = collaborator_count;
        _this.guest_count = guest_count;
        _this.moderator_count = moderator_count;
        return _this;
    }
    return RoleModel;
}(BaseModel));
export { RoleModel };
