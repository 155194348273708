<fileDroppa [url]="url" [autoUpload]="true" [beforeRequest]="beforeRequest" [beforeFileUpload]="beforeFileUpload"
    (fileUploaded)="fileUploaded($event)" (filesUpdated)="filesUpdated($event)" [showFilesList]="false"
    class="xt-file-upload  xt-form-span" [dropZoneTemplate]="getTemplate()">
    <div class="xt-upload-photo" *ngIf="hideImages === false">

        <div *ngIf="uploadInProgress" class="text-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div>

        <div class="upload__order-files" *ngIf="type === 'Order' || type === 'Vendor'">
            <div *ngFor="let attachment of attachments">
                <div class="xt-attach-span mb-1">
                    <div style="width:240px !important" class="d-flex  align-items-center px-1">
                        <div style="width: 180px !important;" class="d-flex align-items-center">
                            <i class="ft-paperclip mr-1" aria-hidden="true" style="width: 20px !important;"></i>
                            <div [innerHTML]="attachment.file_name" style="width: 160px !important;"
                                class="text-truncate"></div>
                        </div>
                        <div class="text-right " style="width: 60px !important;">
                            <div ngbDropdown placement="bottom-right" class="d-inline-block">
                                <button class="btn btn-sm mb-0 p-0 text-secondary" type="button"
                                    style="background: transparent;" [attr.id]="'dropdownBasic' + attachment.id"
                                    ngbDropdownToggle><i class="ft-more-horizontal" aria-hidden="true"></i>
                                </button>
                                <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownBasic' + attachment.id"
                                    class="bg-white">
                                    <button ngbDropdownItem type="button"
                                        (click)="downloadFile(attachment)">Download</button>
                                    <button ngbDropdownItem type="button"
                                        (click)="fileRemoved(attachment)">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="upload__item-files row my-drop-width" *ngIf="type === 'Item' ">
            <div class="col-4 px-0" *ngFor="let attachment of attachments">
                <div *ngIf="type === 'Item'">
                    <div class="row px-2 xt-img-add">
                        <img [src]="attachment.file_path" alt="" style="width:125px !important; height:125px !important"
                            class="img-thumbnail p-1 border-0 ">
                        <div ngbDropdown class=" xt-img-btn">
                            <button class="btn btn-light" [attr.id]="'dropdownBasic' + attachment.id" type="button"
                                ngbDropdownToggle><i class="ft-more-horizontal"></i> </button>
                            <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownBasic' + attachment.id">
                                <button ngbDropdownItem type="button"
                                    (click)="downloadFile(attachment)">Download</button>
                                <button ngbDropdownItem type="button" (click)="makeAsPrimary.emit(attachment)">Mark As
                                    Primary</button>
                                <div class="dropdown-divider"></div>
                                <button ngbDropdownItem type="button" (click)="fileRemoved(attachment)">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="upload__files" *ngIf="type === null">
            <div class="photo-establis-container" *ngFor="let attachment of attachments">
                <div class="display-inline-block mr-2">
                    <div class="xt-establis-photo">
                        <button (click)="downloadFile(attachment)" aria-label="Download attachment file">
                            <img [src]="attachment.thumbnail" />
                        </button>
                    </div>
                </div>
                <div class="display-inline-block">
                    <div class="xt-upload-img-link" [innerHTML]="attachment.name"></div>
                    <div class="xt-uploade-complete">
                        Upload complete
                    </div>
                    <button class="xt-upload-close" aria-label="Remove attachment" (click)="fileRemoved(attachment)">
                      <i class="fa fa-times " aria-hidden="true" ></i>
                    </button>
                </div>
            </div>
        </div>


    </div>
</fileDroppa>
