import { CurrencyModel } from './../_models/currency.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(public http: HttpClient) { }

  getAllRecords() {
    return this.http.get('production/custom/currency')
      .map(
        (response: any) => {
          return <CurrencyModel[]>response;
        }
      );
  }
}
