import { CharacterChangeModel } from './character-change.model';
import { MeasurementModel } from './measurement.model';
import { BaseModel } from "./base.model";
import { ItemModel } from './item.model';

export class HeroPropModel extends BaseModel {
    public item: ItemModel;

    constructor(public id: number = null,
        public production_id: number = null,
        public is_established: boolean = false) {
        super();
    }

    makeObjectFromJson(result: any[]) {
        super.makeObjectFromJson(result);

        //Item
        if(result["item"]){
            this.item = new ItemModel();
            this.item.makeObjectFromJson(result["item"]);
        }
    }
}