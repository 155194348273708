<div class="index-filter" [formGroup]="filterForm">
    <div class="index-filter__fields">
        <ng-template [ngIf]="!presetColumn && !filter.field">
            <select class="index-filter__column-select" name="filterColumnSelect" (change)="selectColumnToFilter($event)">
                <option value="" disabled>Select column</option>
                <ng-container *ngFor="let col of listColumns; let i = index">
                    <option *ngIf="col.filterType" [value]="i">{{ col.header }}</option>
                </ng-container>
            </select>
        </ng-template>
        <ng-template [ngIf]="presetColumn || filter.field">
          <h5>{{presetColumn?.header || selectedColumn?.header}}</h5>
        </ng-template>
        <div class="index-filter__config" *ngIf="selectedColumn">
            <ng-container [ngSwitch]="selectedColumn.filterType">
                <ng-container *ngSwitchCase="'number'">
                    <input *ngIf="selectedColumn.resolve" type="number" min="0" [value]="filter?.filter" [formControlName]="resolveToLast(selectedColumn.field)" placeholder="{{selectedColumn.header}}" (change)="filterUpdate($event.target.value, resolveToLast(selectedColumn.field))" />
                    <input *ngIf="!selectedColumn.resolve" type="number" min="0" [value]="filter?.filter" [formControlName]="selectedColumn.field" placeholder="{{selectedColumn.header}}" (change)="filterUpdate($event.target.value, selectedColumn.field)" />
                </ng-container>
                <ng-container *ngSwitchCase="'string'">
                    <input *ngIf="selectedColumn.resolve" type="text" [value]="filter?.filter" [formControlName]="resolveToLast(selectedColumn.field)" placeholder="{{selectedColumn.header}}" (change)="filterUpdate($event.target.value, resolveToLast(selectedColumn.field))" />
                    <input *ngIf="!selectedColumn.resolve" type="text" [value]="filter?.filter" [formControlName]="selectedColumn.field" placeholder="{{selectedColumn.header}}" (change)="filterUpdate($event.target.value, selectedColumn.field)" />
                </ng-container>

                <ng-container *ngSwitchCase="'dateRange'">
                    <div class="index-filter__date-fields">
                        <div class="input-group">
                            <input class="form-control xt-no-border" ngbDatepicker #fromDate="ngbDatepicker"
                                   [(ngModel)]="dateObject.from" [ngModelOptions]="{standalone: true}"
                                   ngbTooltip="" placement="bottom-left" triggers="manual"
                                   appInvalidTooltip>
                            <button class="empty-button xt-custom-datepicker" aria-label="Open datepicker to select 'from' date" (click)="fromDate.toggle()">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        <div class="input-group">
                            <input class="form-control xt-no-border" ngbDatepicker #toDate="ngbDatepicker"
                                   [(ngModel)]="dateObject.to" [ngModelOptions]="{standalone: true}"
                                   ngbTooltip="" placement="bottom-right" triggers="manual"
                                   appInvalidTooltip>
                            <button class="empty-button xt-custom-datepicker" aria-label="Open datepicker to select 'to' date" (click)="toDate.toggle()">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary" (click)="filterByDate(selectedColumn.field)">Apply</button>
                </ng-container>

                <ng-container *ngSwitchCase="'cost'">
                    <div class="index-filter__cost-fields">
                        <input type="number" placeholder="Min Cost"
                               [value]="filter?.filter?.cost_min" (change)="populateCostObject($event.target.value, 'cost_min')">
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        <input type="number" placeholder="Max Cost"
                               [value]="filter?.filter?.cost_max" (change)="populateCostObject($event.target.value, 'cost_max')">
                    </div>
                    <div ngbDropdown class="d-inline-block index-filter__cost-currency" *ngIf="filterOptions?.currencies">
                        <button class="btn btn-outline-primary" ngbDropdownToggle>
                            {{filter?.filter?.currency || 'Currency'}}
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                        <div ngbDropdownMenu>
                            <button ngbDropdownItem *ngFor="let currency of filterOptions.currencies" type="button"
                                    (click)="populateCostObject(currency.currency_code, 'currency')">
                                {{ currency.currency_code }}
                            </button>
                        </div>
                    </div>
                    <button type="button" class="btn btn-outline-primary" (click)="filterByCost(selectedColumn.field)">Apply</button>
                </ng-container>

                <ng-container *ngSwitchCase="'select'">
                  <ng-container *ngIf="filterOptions && filterOptions[selectedColumn.field]">
                    <select name="{{selectedColumn.field}}-filter" id="{{selectedColumn.field}}-filter" [value]="filter?.filter" (change)="filterUpdate($event.target.value, selectedColumn.field)">
                      <option value="" disabled>Select {{selectedColumn.header}}</option>
                      <ng-container *ngFor="let opt of filterOptions[selectedColumn.field]; let i = index">
                        <option value="{{opt.value || opt.name || opt}}">{{ opt.name || opt}}</option>
                      </ng-container>
                    </select>
                  </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <button class="index-filter__remove" aria-label="Clear filter" (click)="removeCurrentFilter()"><i class="fa fa-times-circle" aria-hidden="true"></i></button>
</div>
