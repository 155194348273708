import { StripboardModel } from './stripboard.model';
import { BaseModel } from "./base.model";

export class StripboardColorModel extends BaseModel {
    public stripboard: StripboardModel;

    constructor(public id: number = null,
        public color: string = null,
        public issue_date: any = null,
        public display_issue_date: any = null,
        public can_add: boolean = null,
        public stripboard_id: number = null,
        public display_text: string = null) {
        super();
    }
}