import {Injectable} from '@angular/core';

@Injectable()
export class UserSettingService {

    constructor() {
    }

    getSpinnerClass() {
        return 'fa fa-spinner fa-spin';
    }

    getTabJustification() {
        return 'start';
    }
    /* Datatable buttons start*/
    getDatatableButtonsWithoutArrow() {
        return "btn btn-primary btn-flat mb-0";
    }
    getPrimaryButtons() {
        return "btn btn-primary btn-raised btn-sm";
    }
    getDatatableButtons() {
        return "btn btn-primary btn-flat dropdown-toggle mb-0";
    }
    /* Datatable buttons end*/
}
