import { WebsiteUserModel } from './website-user.model';
import { BaseModel } from "./base.model";
import { RoleModel } from "./role.model";
import { EmailModel } from './email.model';

export class ProductionModel extends BaseModel {
    public roles: RoleModel[];
    public website_users: WebsiteUserModel[];
    public emails: EmailModel[];
    
    constructor(public id: number = null,
        public uuid: string = null,
        public user_id: number = null,
        public name: string = null,
        public is_closed: boolean = null,
        public stripboard_count: number = null,
        public user_count: number = null,
        public set_count: number = null,
        public character_count: number = null,
        public type: string = null,
        public is_multi_factor_required: boolean = null,
        public company: string = null,
        public studio: string = null,
        public city: string = null,
        public state: string = null,
        public street: string = null,
        public country: string = null,
        public postcode_code: string = null,
        public address_line_1: string = null,
        public address_line_2: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        this.roles = new Array();
        if (result["roles"]) {
            for (const json of result["roles"]) {
                const role = new RoleModel();
                role.makeObjectFromJson(json);
                this.roles.push(role);
            }
        }
    }
}
