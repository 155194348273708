var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
var UserModel = /** @class */ (function (_super) {
    __extends(UserModel, _super);
    function UserModel(id, role, name, email, password, google2fa_enable, account_blocked, google_connected, change_password) {
        if (id === void 0) { id = null; }
        if (role === void 0) { role = null; }
        if (name === void 0) { name = null; }
        if (email === void 0) { email = null; }
        if (password === void 0) { password = null; }
        if (google2fa_enable === void 0) { google2fa_enable = null; }
        if (account_blocked === void 0) { account_blocked = null; }
        if (google_connected === void 0) { google_connected = null; }
        if (change_password === void 0) { change_password = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.role = role;
        _this.name = name;
        _this.email = email;
        _this.password = password;
        _this.google2fa_enable = google2fa_enable;
        _this.account_blocked = account_blocked;
        _this.google_connected = google_connected;
        _this.change_password = change_password;
        return _this;
    }
    return UserModel;
}(BaseModel));
export { UserModel };
