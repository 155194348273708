var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UserModel } from './user.model';
import { BaseModel } from "./base.model";
var AttachmentModel = /** @class */ (function (_super) {
    __extends(AttachmentModel, _super);
    function AttachmentModel(id, file_path, file_name, created_by, created_at, is_selected, type, thumbnail, download_path) {
        if (id === void 0) { id = null; }
        if (file_path === void 0) { file_path = null; }
        if (file_name === void 0) { file_name = null; }
        if (created_by === void 0) { created_by = null; }
        if (created_at === void 0) { created_at = null; }
        if (is_selected === void 0) { is_selected = false; }
        if (type === void 0) { type = null; }
        if (thumbnail === void 0) { thumbnail = null; }
        if (download_path === void 0) { download_path = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.file_path = file_path;
        _this.file_name = file_name;
        _this.created_by = created_by;
        _this.created_at = created_at;
        _this.is_selected = is_selected;
        _this.type = type;
        _this.thumbnail = thumbnail;
        _this.download_path = download_path;
        return _this;
    }
    AttachmentModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //User
        if (result["user"]) {
            this.user = new UserModel();
            this.user.makeObjectFromJson(result["user"]);
        }
    };
    return AttachmentModel;
}(BaseModel));
export { AttachmentModel };
