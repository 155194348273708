/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../Services/FileParser.service";
import * as i2 from "./FileDropZone";
import * as i3 from "../Services/FileStore.service";
var styles_FileDropZone = ["\n        .file_dropZone_internal {\n            border: 3px dashed #DDD;\n            border-radius:10px;\n            padding:10px;\n            width:400px;\n            height:200px;\n            color:#CCC;\n            text-align:center;\n            display:table-cell;\n            vertical-align:middle;\n            cursor:pointer;\n        }\n    "];
var RenderType_FileDropZone = i0.ɵcrt({ encapsulation: 2, styles: styles_FileDropZone, data: {} });
export { RenderType_FileDropZone as RenderType_FileDropZone };
export function View_FileDropZone_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_FileDropZone_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "fileDropZone", [], null, [[null, "drop"], [null, "dragenter"], [null, "dragover"], [null, "dragleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("drop" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).drop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).dragenter($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2).dragover($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2).dragleave($event) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, View_FileDropZone_0, RenderType_FileDropZone)), i0.ɵprd(512, null, i1.FileParser, i1.FileParser, []), i0.ɵdid(2, 49152, null, 0, i2.FileDropZone, [i3.FilesStore, i0.ElementRef, i1.FileParser], null, null)], null, null); }
var FileDropZoneNgFactory = i0.ɵccf("fileDropZone, [fileDropZone]", i2.FileDropZone, View_FileDropZone_Host_0, { multiple: "multiple" }, {}, ["*"]);
export { FileDropZoneNgFactory as FileDropZoneNgFactory };
