import { UserModel } from './../_models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'

@Injectable()
export class AuthService {
  public currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  register(form: any) {
    return this.http.post('register', form);
  }

  login(form: any) {
    return this.http.post('login', form)
      .map(
        (response: any) => {
          localStorage.setItem('token', response.token);
          localStorage.setItem('currentUser', JSON.stringify(response.user));
          this.currentUserSubject.next(response.user);
          return response;
        }
      );
  }

  getUser(): Observable<UserModel> {
    return this.http.get('user')
      .map(
        (response: any) => {
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.currentUserSubject.next(response);
          return <UserModel>response;
        }
      );
  }

  forgotPassword(form: any) {
    return this.http.post('password/email', form);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentTenant');
    this.currentUserSubject.next(null);
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    return true;
  }

  activate(token: string) {
    return this.http.get('activate/' + token);
  }

  resetPassword(form: any) {
    return this.http.post('reset-password', form);
  }

  setUser(json) {
    localStorage.setItem('currentUser', JSON.stringify(json));
    this.currentUserSubject.next(json);
  }

  updatePassword(form: any) {
    return this.http.post('user-password-update', form);
  }

  delete() {
    return this.http.post('user/' + this.currentUserValue.id, {
      _method: "DELETE"
    });
  }

  firstTimeChange(form: any) {
    return this.http.post('user/custom/change/password', form)
      .map(
        (response: any) => {
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.currentUserSubject.next(response);
          return response;
        }
      );
  }

  getUserByToken(token: any): Observable<UserModel> {
    return this.http.post('temp-token', {
      token: token
    })
      .map(
        (response: any) => {
          return <UserModel>response;
        }
      );
  }

  magicLink(form: any) {
    return this.http.post('user/custom/password/update', form)
      .map(
        (response: any) => {
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.currentUserSubject.next(response);
          return response;
        }
      );
  }
}
