var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
var StripboardColorModel = /** @class */ (function (_super) {
    __extends(StripboardColorModel, _super);
    function StripboardColorModel(id, color, issue_date, display_issue_date, can_add, stripboard_id, display_text) {
        if (id === void 0) { id = null; }
        if (color === void 0) { color = null; }
        if (issue_date === void 0) { issue_date = null; }
        if (display_issue_date === void 0) { display_issue_date = null; }
        if (can_add === void 0) { can_add = null; }
        if (stripboard_id === void 0) { stripboard_id = null; }
        if (display_text === void 0) { display_text = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.color = color;
        _this.issue_date = issue_date;
        _this.display_issue_date = display_issue_date;
        _this.can_add = can_add;
        _this.stripboard_id = stripboard_id;
        _this.display_text = display_text;
        return _this;
    }
    return StripboardColorModel;
}(BaseModel));
export { StripboardColorModel };
