import { EventEmitter } from '@angular/core';
import { WrapItemsComponent } from './../_modals/wrap-items/wrap-items.component';
import { SelectSetComponent } from './../_modals/select-set/select-set.component';
import { AssignItemCharacterComponent } from './../_modals/assign-item-character/assign-item-character.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueueService } from './../../_services/queue.service';
import { Component, OnInit, Output } from '@angular/core';
import { SelectChangeComponent } from '../_modals/select-change/select-change.component';

@Component({
  selector: 'app-item-queue',
  templateUrl: './item-queue.component.html',
  styleUrls: ['./item-queue.component.scss']
})
export class ItemQueueComponent implements OnInit {
  @Output() recordAdded = new EventEmitter<any>();

  constructor(public queueService: QueueService,
    private modalService: NgbModal) { }

  ngOnInit() {}

  assignToCharacterClicked() {
    const modalRef = this.modalService.open(AssignItemCharacterComponent,
      {
        windowClass: "modal-md modal-xxl small-modal"
      }).componentInstance;

    modalRef.recordAdded.subscribe(
      result => {}
    );
  }

  assignToSetClicked() {
    const modalRef = this.modalService.open(SelectSetComponent,
      {
        windowClass: "modal-md modal-xxl small-modal"
      }).componentInstance;

    modalRef.recordAdded.subscribe(
      result => {
        this.queueService.clearQueue();
      }
    );
  }

  assignToChangeClicked(type: string) {
    const modalRef = this.modalService.open(SelectChangeComponent,
      {
        windowClass: "modal-md modal-xxl small-modal"
      }).componentInstance;

    modalRef.type = type;
    modalRef.recordAdded.subscribe(
      result => {
        this.queueService.clearQueue();
      }
    );
  }

  wrapItemsClicked() {
    const modalRef = this.modalService.open(WrapItemsComponent,
      {
        windowClass: "modal-md modal-xxl xt-full-screen-modal"
      }).componentInstance;

    modalRef.recordAdded.subscribe(
      result => {
        this.clearQueue();
        this.recordAdded.emit();
      }
    );
  }

  clearQueue() {
    this.queueService.items = new Array();
  }

  removeItem(_index: number) {
    this.queueService.items.splice(_index, 1);
  }
}
