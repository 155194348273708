<div class="d-flex justify-content-center align-items-center no-permission">
    <div class="text-center">
       <div class="mb-4"><img src="../assets/img/elements/oh-no.png" width="80%"></div>
        <h4 class="font-weight-bold ">You don't have permission to view this page</h4>
        <small class="text-muted font-weight-normal">
            Sorry,it appears you do not have the necessary permission to view the page
        </small>
        <div class="mt-3">
            <button class="btn btn-primary text-white" type="button" (click)="router.navigate(['/production'])">Go Back</button>
        </div>
    </div>
</div>