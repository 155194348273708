var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AttachmentModel } from './attachment.model';
import { BaseModel } from "./base.model";
var GalleryModel = /** @class */ (function (_super) {
    __extends(GalleryModel, _super);
    function GalleryModel(id, name) {
        if (id === void 0) { id = null; }
        if (name === void 0) { name = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.name = name;
        return _this;
    }
    GalleryModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Attachments
        this.attachments = new Array();
        if (result["gallery_attachments"]) {
            for (var _i = 0, _a = result["gallery_attachments"]; _i < _a.length; _i++) {
                var json = _a[_i];
                if (json["attachment"]) {
                    var attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }
    };
    return GalleryModel;
}(BaseModel));
export { GalleryModel };
