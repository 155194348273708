import { AssetTypeModel } from './../_models/asset-type.model';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { ProductionModel } from "../_models/production.model";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrencyModel } from 'app/_models/currency.model';
@Injectable()
export class ProductionService {
    public loading: boolean;
    public loadingProductionDetails: boolean;
    public loadingMessages: string[];

    public currentProductionSubject: BehaviorSubject<ProductionModel>;
    public currentProduction: Observable<ProductionModel>;

    constructor(private http: HttpClient,
        public authService: AuthService,
        public router: Router,
        public route: ActivatedRoute) {
        this.currentProductionSubject = new BehaviorSubject<ProductionModel>(JSON.parse(localStorage.getItem('currentProduction')));
        this.currentProduction = this.currentProductionSubject.asObservable();
    }

    public get currentProductionValue(): ProductionModel {
        return this.currentProductionSubject.value;
    }

    public get production(): ProductionModel {
        return this.currentProductionSubject.value;
    }

    select(production: ProductionModel) {
        localStorage.setItem("production", "" + production.id);
        this.get(production.uuid)
            .subscribe(
                result => {
                    const production: ProductionModel = new ProductionModel();
                    production.makeObjectFromJson(result);
                    this.currentProductionSubject.next(result);
                }
            );
    }

    get(uuid: string) {
        return this.http.get("production/" + uuid)
            .map(
                (response: any) => {
                    const record: ProductionModel = new ProductionModel();
                    record.makeObjectFromJson(response);
                    return record;
                }
            );
    }

    store(form: any) {
        return this.http.post("production", { production: form })
            .map(
                (response: any) => {
                    localStorage.setItem('currentProduction', JSON.stringify(response));
                    const production: ProductionModel = new ProductionModel();
                    production.makeObjectFromJson(response);
                    this.currentProductionSubject.next(response);

                    if (production.is_multi_factor_required) {
                        if (this.authService.currentUserValue.google2fa_enable !== true) {
                            this.router.navigate(['multi-factor'], { relativeTo: this.route.parent });
                        } else {
                            this.router.navigate(['stripboard']);
                        }
                    } else {
                        this.router.navigate(['stripboard']);
                    }

                    return production;
                }
            );
    }

    update(form: any) {
        return this.http.post("production/" + this.currentProductionValue.uuid, form);
    }

    delete(id: number) {
        return this.http.post("production/" + id, { _method: "DELETE" });
    }

    getAllRecords(): Observable<ProductionModel[]> {
        this.loadingMessages = ['Loading Account Details.'];
        this.loading = true;
        return this.http.get("production")
            .map(
                (response: any) => {
                    this.loading = false;
                    return <ProductionModel[]>response;
                }
            );
    }

    getRecordsForSelect2Items(records: ProductionModel[]) {
        const productions: any[] = new Array();
        for (const record of records) {
            productions.push(
                {
                    id: record.id,
                    text: record.name
                }
            );
        }
        return productions;
    }

    getCountries() {
        return ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua &amp; Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas"
            , "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia &amp; Herzegovina", "Botswana", "Brazil", "British Virgin Islands"
            , "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica"
            , "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea"
            , "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana"
            , "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India"
            , "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia"
            , "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania"
            , "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia"
            , "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal"
            , "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre &amp; Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles"
            , "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea", "Spain", "Sri Lanka", "St Kitts &amp; Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan"
            , "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad &amp; Tobago", "Tunisia"
            , "Turkey", "Turkmenistan", "Turks &amp; Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay"
            , "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"];
    }

    getDefaultRoles() {

    }

    increaseCount(parameter: string) {
        let production = localStorage.getItem('currentProduction');

        if (production) {
            const productionObject = JSON.parse(production);
            productionObject[parameter + '_count'] += productionObject[parameter + '_count'];
            localStorage.setItem('currentProduction', JSON.stringify(productionObject));
        }
    }

    leaveProduction() {
        return this.http.get('production-leave')
            .map(
                (response: any) => {
                    localStorage.removeItem('currentProduction');
                }
            );
    }

    deleteProduction() {
        return this.http.get('production/' + this.currentProductionValue.uuid)
            .map(
                (response: any) => {
                    localStorage.removeItem('currentProduction');
                }
            )
    }

    addCurrency(currencyCode: string) {
        return this.http.post('production/custom/currency', {
            currencies: [
                { currency_code: currencyCode }
            ]
        });
    }

    updateCurrency(form) {
        return this.http.post('production/custom/currency', form);
    }

    addAssetType(form) {
        return this.http.post('production/custom/asset-type', form);
    }

    deleteCurrency(currency: string) {
        return this.http.post('production/custom/currency/' + currency, {
            _method: "DELETE"
        });
    }

    deleteAssetType(id: number) {
        return this.http.post('production/custom/asset-type/' + id, { _method: "DELETE" })
    }

    getCurrencies() {
        return this.http.get('production/custom/currency')
            .map(
                (response: any) => {
                    return <CurrencyModel[]>response;
                }
            );
    }

    getAssets() {
        return this.http.get('production/custom/asset-type')
            .map(
                (response: any) => {
                    return <AssetTypeModel[]>response;
                }
            );
    }

    getStorageLocations() {
        return this.http.get('production/custom/storage-location')
            .map(
                (response: any) => {
                    return <AssetTypeModel[]>response;
                }
            );
    }

    getMultiFactor() {
        return this.http.get('production/custom/multi-factor');
    }

    updateMultiFactor(form) {
        return this.http.post('production/custom/multi-factor', {
            is_multi_factor_required: form
        });
    }

    closeProduction() {
        return this.http.post('production/custom/closed', {
            is_closed: true
        });
    }
}
