var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RoleModel } from './role.model';
import { BaseModel } from './base.model';
import { NoteModel } from './note.model';
import { TagModel } from './tag.model';
import { CharacterModel } from './character.model';
import { SceneModel } from './scene.model';
import { SetModel } from './set.model';
import { AttachmentModel } from './attachment.model';
export var InventoryStatus;
(function (InventoryStatus) {
    InventoryStatus["Active"] = "Active";
    InventoryStatus["Wrapped"] = "Wrapped";
})(InventoryStatus || (InventoryStatus = {}));
// ToDo: Standardise the object attributes required for the different drop downs and selects
export var inventoryStatusOptions = [
    { value: InventoryStatus.Active, name: InventoryStatus.Active, id: InventoryStatus.Active, text: InventoryStatus.Active },
    { value: InventoryStatus.Wrapped, name: InventoryStatus.Wrapped, id: InventoryStatus.Wrapped, text: InventoryStatus.Wrapped },
];
var ItemModel = /** @class */ (function (_super) {
    __extends(ItemModel, _super);
    function ItemModel(id, created_at, name, size, location, transaction_number, doc_number, unit_cost_value, is_established, asset_number, studio_ref, type, unit_cost_currency, asset_type, stock_number, quantity, replacement_value, replacement_value_currency, status, pic, thumbnail, max_check_in, checked_in, max_return, order_item_status, wrapped_quantity, max_wrapped_quantity, max_wrapper_quantity, inventory_status, inventory_status_quantity, account_code, additional_account_code, vendor, vendor_reference, note, order_method, item_type, line_number, order_by, order_id, returned, combine_account_code, is_print_generated) {
        if (id === void 0) { id = null; }
        if (created_at === void 0) { created_at = null; }
        if (name === void 0) { name = null; }
        if (size === void 0) { size = null; }
        if (location === void 0) { location = null; }
        if (transaction_number === void 0) { transaction_number = null; }
        if (doc_number === void 0) { doc_number = null; }
        if (unit_cost_value === void 0) { unit_cost_value = null; }
        if (is_established === void 0) { is_established = false; }
        if (asset_number === void 0) { asset_number = null; }
        if (studio_ref === void 0) { studio_ref = null; }
        if (type === void 0) { type = null; }
        if (unit_cost_currency === void 0) { unit_cost_currency = null; }
        if (asset_type === void 0) { asset_type = null; }
        if (stock_number === void 0) { stock_number = null; }
        if (quantity === void 0) { quantity = null; }
        if (replacement_value === void 0) { replacement_value = null; }
        if (replacement_value_currency === void 0) { replacement_value_currency = null; }
        if (status === void 0) { status = null; }
        if (pic === void 0) { pic = null; }
        if (thumbnail === void 0) { thumbnail = null; }
        if (max_check_in === void 0) { max_check_in = null; }
        if (checked_in === void 0) { checked_in = null; }
        if (max_return === void 0) { max_return = null; }
        if (order_item_status === void 0) { order_item_status = null; }
        if (wrapped_quantity === void 0) { wrapped_quantity = null; }
        if (max_wrapped_quantity === void 0) { max_wrapped_quantity = null; }
        if (max_wrapper_quantity === void 0) { max_wrapper_quantity = null; }
        if (inventory_status === void 0) { inventory_status = null; }
        if (inventory_status_quantity === void 0) { inventory_status_quantity = null; }
        if (account_code === void 0) { account_code = null; }
        if (additional_account_code === void 0) { additional_account_code = null; }
        if (vendor === void 0) { vendor = null; }
        if (vendor_reference === void 0) { vendor_reference = null; }
        if (note === void 0) { note = null; }
        if (order_method === void 0) { order_method = null; }
        if (item_type === void 0) { item_type = null; }
        if (line_number === void 0) { line_number = null; }
        if (order_by === void 0) { order_by = null; }
        if (order_id === void 0) { order_id = null; }
        if (returned === void 0) { returned = null; }
        if (combine_account_code === void 0) { combine_account_code = null; }
        if (is_print_generated === void 0) { is_print_generated = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.created_at = created_at;
        _this.name = name;
        _this.size = size;
        _this.location = location;
        _this.transaction_number = transaction_number;
        _this.doc_number = doc_number;
        _this.unit_cost_value = unit_cost_value;
        _this.is_established = is_established;
        _this.asset_number = asset_number;
        _this.studio_ref = studio_ref;
        _this.type = type;
        _this.unit_cost_currency = unit_cost_currency;
        _this.asset_type = asset_type;
        _this.stock_number = stock_number;
        _this.quantity = quantity;
        _this.replacement_value = replacement_value;
        _this.replacement_value_currency = replacement_value_currency;
        _this.status = status;
        _this.pic = pic;
        _this.thumbnail = thumbnail;
        _this.max_check_in = max_check_in;
        _this.checked_in = checked_in;
        _this.max_return = max_return;
        _this.order_item_status = order_item_status;
        _this.wrapped_quantity = wrapped_quantity;
        _this.max_wrapped_quantity = max_wrapped_quantity;
        _this.max_wrapper_quantity = max_wrapper_quantity;
        _this.inventory_status = inventory_status;
        _this.inventory_status_quantity = inventory_status_quantity;
        _this.account_code = account_code;
        _this.additional_account_code = additional_account_code;
        _this.vendor = vendor;
        _this.vendor_reference = vendor_reference;
        _this.note = note;
        _this.order_method = order_method;
        _this.item_type = item_type;
        _this.line_number = line_number;
        _this.order_by = order_by;
        _this.order_id = order_id;
        _this.returned = returned;
        _this.combine_account_code = combine_account_code;
        _this.is_print_generated = is_print_generated;
        return _this;
    }
    ItemModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        if (result['notes']) {
            this.notes = new Array();
            for (var _i = 0, _a = result['notes']; _i < _a.length; _i++) {
                var note = _a[_i];
                var record = new NoteModel();
                record.makeObjectFromJson(note);
                this.notes.push(record);
            }
        }
        if (result['department']) {
            this.role = new RoleModel();
            this.role.makeObjectFromJson(result['department']);
        }
        if (result['tags']) {
            this.tags = new Array();
            for (var _b = 0, _c = result['tags']; _b < _c.length; _b++) {
                var tag = _c[_b];
                var record = new TagModel();
                record.makeObjectFromJson(tag);
                this.tags.push(record);
            }
        }
        if (result['item_attachments']) {
            this.attachments = new Array();
            for (var _d = 0, _e = result['item_attachments']; _d < _e.length; _d++) {
                var itemAttachment = _e[_d];
                if (itemAttachment['attachment']) {
                    var attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(itemAttachment['attachment']);
                    this.attachments.push(attachment);
                }
            }
        }
        if (result['characters']) {
            this.characters = new Array();
            for (var _f = 0, _g = result['characters']; _f < _g.length; _f++) {
                var character = _g[_f];
                var record = new CharacterModel();
                record.makeObjectFromJson(character);
                this.characters.push(record);
            }
        }
        if (result['scenes']) {
            this.scenes = new Array();
            for (var _h = 0, _j = result['scenes']; _h < _j.length; _h++) {
                var scene = _j[_h];
                var record = new SceneModel();
                record.makeObjectFromJson(scene);
                this.scenes.push(record);
            }
        }
        if (result['sets']) {
            this.scenes = new Array();
            for (var _k = 0, _l = result['sets']; _k < _l.length; _k++) {
                var set = _l[_k];
                var record = new SetModel();
                record.makeObjectFromJson(set);
                this.sets.push(record);
            }
        }
    };
    ItemModel.prototype.getStatus = function () {
        if (this.order_item_status) {
            return this.order_item_status;
        }
        else {
            return 'AwaitingDelivery';
        }
    };
    return ItemModel;
}(BaseModel));
export { ItemModel };
