/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./File.ngfactory";
import * as i2 from "./File";
import * as i3 from "@angular/common";
import * as i4 from "./FileList";
import * as i5 from "../Services/FileStore.service";
var styles_FileList = [".file-list[_ngcontent-%COMP%] {\n            width: 430px;\n            margin-bottom: 5px;\n            display: flex;\n            flex-flow: wrap;\n            justify-content: flex-start;\n         }"];
var RenderType_FileList = i0.ɵcrt({ encapsulation: 0, styles: styles_FileList, data: {} });
export { RenderType_FileList as RenderType_FileList };
function View_FileList_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fileItem", [], null, [[null, "removeFile"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeFile" === en)) {
        var pd_0 = (_co.removeFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_File_0, i1.RenderType_File)), i0.ɵdid(1, 1097728, null, 0, i2.File, [], { file: [0, "file"], percentage: [1, "percentage"], loadingSuccessful: [2, "loadingSuccessful"], responseMessage: [3, "responseMessage"] }, { removeFile: "removeFile" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit.File; var currVal_1 = _v.context.$implicit.percentage; var currVal_2 = _v.context.$implicit.loadingSuccessful; var currVal_3 = _v.context.$implicit.responseMessage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_FileList_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "file-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileList_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filesStore.iFiles; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FileList_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fileList", [], null, null, null, View_FileList_0, RenderType_FileList)), i0.ɵdid(1, 49152, null, 0, i4.FileList, [i5.FilesStore], null, null)], null, null); }
var FileListNgFactory = i0.ɵccf("fileList, [fileList]", i4.FileList, View_FileList_Host_0, {}, {}, []);
export { FileListNgFactory as FileListNgFactory };
