<div class="modal-header xt-model-fixed-header">
  <div class="full-width">
    <div class="row">
      <div class="col-md-2">
        <h6 class="modal-title">
          <a (click)="activeModal.dismiss('Close click')">
            <i class="fa fa-angle-left mr-1" aria-hidden="true"></i>
            <span>Back</span>
          </a>
        </h6>
      </div>
      <div class="col-md-8 text-center pt-1">
        <div *ngIf="activeImage" class="text-bold-400">{{ activeImage.file_name }}</div>
      </div>
      <div class="col-md-2 text-right">
        <!-- <span>Last Updated on </span> -->
        <button class="btn btn-outline-secondary mb-0" (click)="activeModal.dismiss()"><i class="fa fa-trash"
            aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-body text-center" *ngIf="attachments">

  <div class="picsum-img-wrapper pt-2">
    <img *ngIf="activeImage" class="xt-lightbox-img img-fluid" [src]="activeImage.file_path" alt="Random slide">
  </div>

  <div class="pt-5"></div>
  <div class="fixed-bottom col-6 pb-1 mx-auto">
    <div class="btn-group bg-dark align-items-center rounded" role="group">
      <button *ngIf="getSelectedIndex() > 1" (click)="backClicked()" type="button"
        class="btn btn-lg bg-dark text-white mb-0"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
      <span class="text-white p-2">{{ getSelectedIndex() }} of {{ attachments.length }}</span>
      <button *ngIf="attachments.length !== getSelectedIndex()" (click)="nextClicked()" type="button"
        class="btn btn-lg bg-dark text-white mb-0"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
    </div>
  </div>
</div>