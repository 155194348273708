import { Injectable } from '@angular/core';
import { ItemModel } from 'app/_models/item.model';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  public items: ItemModel[] = new Array();
  public open: boolean = false;

  constructor() {

  }

  push(item: ItemModel) {
    if (this.items.findIndex(_item => _item.id === item.id) === -1) {
      this.items.push(item);
    }

    this.open = true;
  }

  addToQueue(row: ItemModel) {
    const item = <ItemModel>{ ...row };
    this.push(item);
  }

  clearQueue() {
    this.items = [...[]];
    this.open = false;
  }
}
