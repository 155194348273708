import { BaseModel } from "./base.model";

export class UserModel extends BaseModel {

    constructor(public id: number = null,
        public role: string = null,
        public name: string = null,
        public email: string = null,
        public password: string = null,
        public google2fa_enable: boolean = null,
        public account_blocked: boolean = null,
        public google_connected: boolean = null,
        public change_password: boolean = null) {
        super();
    }
}
