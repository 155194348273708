<div class="d-flex justify-content-center align-items-center no-permission">
    <div class="text-center">
        <div class="mb-4"><img src="../assets/img/elements/404.png" width="50%"></div>
        <h4 class="font-weight-bold ">CUT! We can't find the page you are looking for...</h4>
        <small class="text-muted font-weight-normal">
            Sorry, the page you requested does not exist.
        </small>
        <div class="mt-3">
            <button class="btn btn-primary text-white" type="button" (click)="router.navigate(['/production'])">Go
                Back</button>
        </div>
    </div>
</div>