var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './base.model';
import { ItemModel } from './item.model';
import { OrderDeliveryNoteModel } from './order-delivery-note.model';
import { AttachmentModel } from './attachment.model';
import { DepartmentModel } from './department.model';
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["CheckedIn"] = "CheckedIn";
    OrderStatus["PartiallyCheckedIn"] = "PartiallyCheckedIn";
    OrderStatus["Returned"] = "Returned";
    OrderStatus["PartiallyReturned"] = "PartiallyReturned";
    OrderStatus["ReturnOverdue"] = "ReturnOverdue";
    OrderStatus["AwaitingDelivery"] = "AwaitingDelivery";
    OrderStatus["Deleted"] = "Deleted";
})(OrderStatus || (OrderStatus = {}));
export var orderStatusOptions = [
    { value: OrderStatus.CheckedIn, name: 'Checked In' },
    { value: OrderStatus.PartiallyCheckedIn, name: 'Partially Checked In' },
    { value: OrderStatus.Returned, name: 'Returned' },
    { value: OrderStatus.PartiallyReturned, name: 'Partially Returned' },
    { value: OrderStatus.ReturnOverdue, name: 'Return Overdue' },
    { value: OrderStatus.AwaitingDelivery, name: 'Awaiting Delivery' },
    { value: OrderStatus.Deleted, name: 'Deleted' },
];
export var OrderMethods;
(function (OrderMethods) {
    OrderMethods["PurchaseOrder"] = "PurchaseOrder";
    OrderMethods["PettyCash"] = "PettyCash";
    OrderMethods["CreditCard"] = "CreditCard";
    OrderMethods["FOC"] = "FOC";
})(OrderMethods || (OrderMethods = {}));
export var OrderMethodsReadable;
(function (OrderMethodsReadable) {
    OrderMethodsReadable["PurchaseOrder"] = "Purchase Order";
    OrderMethodsReadable["PettyCash"] = "Petty Cash";
    OrderMethodsReadable["CreditCard"] = "Credit Card";
    OrderMethodsReadable["FOC"] = "FOC";
})(OrderMethodsReadable || (OrderMethodsReadable = {}));
// ToDo: Standardise the object attributes required for the different drop downs and selects
export var orderMethodOptions = [
    { value: OrderMethods.PurchaseOrder, name: 'Purchase Order', id: OrderMethods.PurchaseOrder, text: 'Purchase Order' },
    { value: OrderMethods.PettyCash, name: 'Petty Cash', id: OrderMethods.PettyCash, text: 'Petty Cash' },
    { value: OrderMethods.CreditCard, name: 'Credit Card', id: OrderMethods.CreditCard, text: 'Credit Card' }
];
// ToDo: Standardise the object attributes required for the different drop downs and selects
export var orderTypeOptions = [
    { value: 'Purchase', name: 'Purchase' },
    { value: 'Rental', name: 'Rental' }
];
/**
 * The parent object for multi-vendor orders
 *
 */
var MultiVendorOrderModel = /** @class */ (function (_super) {
    __extends(MultiVendorOrderModel, _super);
    function MultiVendorOrderModel(id, doc_number, ordered_by, credit_card_id, order_date, delivery_date, type, currency_code, total_amount, department_id) {
        if (id === void 0) { id = null; }
        if (doc_number === void 0) { doc_number = null; }
        if (ordered_by === void 0) { ordered_by = null; }
        if (credit_card_id === void 0) { credit_card_id = null; }
        if (order_date === void 0) { order_date = null; }
        if (delivery_date === void 0) { delivery_date = null; }
        if (type === void 0) { type = null; }
        if (currency_code === void 0) { currency_code = null; }
        if (total_amount === void 0) { total_amount = null; }
        if (department_id === void 0) { department_id = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.doc_number = doc_number;
        _this.ordered_by = ordered_by;
        _this.credit_card_id = credit_card_id;
        _this.order_date = order_date;
        _this.delivery_date = delivery_date;
        _this.type = type;
        _this.currency_code = currency_code;
        _this.total_amount = total_amount;
        _this.department_id = department_id;
        return _this;
    }
    MultiVendorOrderModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        // Attachments
        this.attachments = new Array();
        if (result['order_attachments']) {
            for (var _i = 0, _a = result['order_attachments']; _i < _a.length; _i++) {
                var json = _a[_i];
                if (json['attachment']) {
                    var attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json['attachment']);
                    this.attachments.push(attachment);
                }
            }
        }
        // Department
        if (result['department']) {
            this.department = new DepartmentModel();
            this.department.makeObjectFromJson(result['department']);
        }
    };
    return MultiVendorOrderModel;
}(BaseModel));
export { MultiVendorOrderModel };
var OrderModel = /** @class */ (function (_super) {
    __extends(OrderModel, _super);
    function OrderModel(id, multi_vendor_order_id, production_id, doc_number, vendor, vendor_reference_number, order_by, credit_card_id, order_date, delivery_date, rental_start_date, rental_return_date, account_code, additional_account_code, type, status, currency_code, order_total, additional_costs, sub_total, asset_type, order_type, vat, notes) {
        if (id === void 0) { id = null; }
        if (multi_vendor_order_id === void 0) { multi_vendor_order_id = null; }
        if (production_id === void 0) { production_id = null; }
        if (doc_number === void 0) { doc_number = null; }
        if (vendor === void 0) { vendor = null; }
        if (vendor_reference_number === void 0) { vendor_reference_number = null; }
        if (order_by === void 0) { order_by = null; }
        if (credit_card_id === void 0) { credit_card_id = null; }
        if (order_date === void 0) { order_date = null; }
        if (delivery_date === void 0) { delivery_date = null; }
        if (rental_start_date === void 0) { rental_start_date = null; }
        if (rental_return_date === void 0) { rental_return_date = null; }
        if (account_code === void 0) { account_code = null; }
        if (additional_account_code === void 0) { additional_account_code = null; }
        if (type === void 0) { type = null; }
        if (status === void 0) { status = null; }
        if (currency_code === void 0) { currency_code = null; }
        if (order_total === void 0) { order_total = null; }
        if (additional_costs === void 0) { additional_costs = null; }
        if (sub_total === void 0) { sub_total = null; }
        if (asset_type === void 0) { asset_type = null; }
        if (order_type === void 0) { order_type = null; }
        if (vat === void 0) { vat = null; }
        if (notes === void 0) { notes = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.multi_vendor_order_id = multi_vendor_order_id;
        _this.production_id = production_id;
        _this.doc_number = doc_number;
        _this.vendor = vendor;
        _this.vendor_reference_number = vendor_reference_number;
        _this.order_by = order_by;
        _this.credit_card_id = credit_card_id;
        _this.order_date = order_date;
        _this.delivery_date = delivery_date;
        _this.rental_start_date = rental_start_date;
        _this.rental_return_date = rental_return_date;
        _this.account_code = account_code;
        _this.additional_account_code = additional_account_code;
        _this.type = type;
        _this.status = status;
        _this.currency_code = currency_code;
        _this.order_total = order_total;
        _this.additional_costs = additional_costs;
        _this.sub_total = sub_total;
        _this.asset_type = asset_type;
        _this.order_type = order_type;
        _this.vat = vat;
        _this.notes = notes;
        return _this;
    }
    OrderModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        // Items
        this.items = new Array();
        if (result['items']) {
            for (var _i = 0, _a = result['items']; _i < _a.length; _i++) {
                var json = _a[_i];
                var item = new ItemModel();
                item.makeObjectFromJson(json);
                this.items.push(item);
            }
        }
        // Order delivery notes
        this.order_delivery_notes = new Array();
        if (result['order_delivery_notes']) {
            for (var _b = 0, _c = result['order_delivery_notes']; _b < _c.length; _b++) {
                var json = _c[_b];
                var record = new OrderDeliveryNoteModel();
                record.makeObjectFromJson(json);
                this.order_delivery_notes.push(record);
            }
        }
        // Attachments
        this.attachments = new Array();
        if (result['order_attachments']) {
            for (var _d = 0, _e = result['order_attachments']; _d < _e.length; _d++) {
                var json = _e[_d];
                if (json['attachment']) {
                    var attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json['attachment']);
                    this.attachments.push(attachment);
                }
            }
        }
        // Department
        if (result['department']) {
            this.department = new DepartmentModel();
            this.department.makeObjectFromJson(result['department']);
        }
    };
    return OrderModel;
}(BaseModel));
export { OrderModel };
