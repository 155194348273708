import { ProductionService } from './production.service';
import { StripboardColorModel } from './../_models/stripboard-color.model';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { StripboardModel } from "../_models/stripboard.model";

@Injectable()
export class StripboardService {
    public stripboardSelected: number;
    public stripboardColorSelected: number;

    constructor(public http: HttpClient,
        public productionService: ProductionService) {
        this.stripboardSelected = null;
        this.stripboardColorSelected = null;
    }

    get(id: number) {
        return this.http.get("stripboard/" + id)
            .map(
                (response: any) => {
                    const record: StripboardModel = new StripboardModel();
                    record.makeObjectFromJson(response);
                    return record;
                }
            );
    }

    store(form: any) {
        return this.http.post("stripboard", { stripboard: form })
            .map(
                (response: any) => {
                    this.productionService.increaseCount('stripboard');
                    const record: StripboardModel = new StripboardModel();
                    record.makeObjectFromJson(response);
                    return record;
                }
            );
    }

    update(id: number, form: any) {
        return this.http.post("stripboard/" + id, { stripboard: form, _method: "PUT" });
    }

    delete(id: number) {
        return this.http.post("stripboard/" + id, { _method: "DELETE" })
            .map(
                (response: any) => {
                    this.stripboardSelected = null;
                    this.stripboardColorSelected = null;
                    return response;
                }
            );
    }

    getAllRecords() {
        return this.http.get("stripboard")
            .map(
                (response: any) => {
                    const records: StripboardModel[] = new Array();
                    for (const json of response) {
                        const record: StripboardModel = new StripboardModel();
                        record.makeObjectFromJson(json);
                        records.push(record);
                    }
                    return records;
                }
            );
    }

    getRecordsForSelect2Items(records: StripboardModel[]) {
        const items: any[] = new Array();
        for (const record of records) {
            items.push(
                {
                    id: record.id,
                    text: record.display_name
                }
            );
        }
        return items;
    }
}
