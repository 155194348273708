import { CharacterChangeModel } from 'app/_models/character-change.model';
import { SceneModel } from 'app/_models/scene.model';
import { BaseModel } from "./base.model";
import { SetModel } from './set.model';

export class SetSceneModel extends BaseModel {
    public scene: SceneModel;
    public set: SetModel;

    constructor(public id: number = null,
        public production_id: number = null,
        public set_id: number = null,
        public scene_id: number = null) {
        super();
    }

    makeObjectFromJson(result: any[]) {
        super.makeObjectFromJson(result);

        //Scene
        if (result["scene"]) {
            this.scene = new SceneModel();
            this.scene.makeObjectFromJson(result["scene"]);
        }

        //Character Change
        if (result["set"]) {
            this.set = new SetModel();
            this.set.makeObjectFromJson(result["set"]);
        }
    }
}