import { CharacterChangeService } from './../../../_services/character-change.service';
import { CharacterService } from './../../../_services/character.service';
import { QueueService } from './../../../_services/queue.service';
import { FormBuilder, Validators } from '@angular/forms';
import { OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSettingService } from 'app/_services/user-setting.service';
import { ToastrService } from 'ngx-toastr';
var SelectChangeComponent = /** @class */ (function () {
    function SelectChangeComponent(fb, toastr, activeModal, userSettingService, characterService, characterChangeService, queueService) {
        this.fb = fb;
        this.toastr = toastr;
        this.activeModal = activeModal;
        this.userSettingService = userSettingService;
        this.characterService = characterService;
        this.characterChangeService = characterChangeService;
        this.queueService = queueService;
        this.recordAdded = new EventEmitter();
        this.types = [
            { id: 'Look' },
            { id: 'Change' }
        ];
    }
    Object.defineProperty(SelectChangeComponent.prototype, "type", {
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
        },
        enumerable: true,
        configurable: true
    });
    SelectChangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.characterService.getCharactersWithChanges({ type: this.type })
            .subscribe(function (result) {
            _this.characters = result;
        });
        this.form = this.fb.group({
            character: this.fb.group({
                id: [null, [Validators.required]]
            }),
            character_change: this.fb.group({
                id: [null]
            }),
            items: this.fb.array([])
        });
    };
    SelectChangeComponent.prototype.typeSelected = function () {
        var _this = this;
        this.characters = null;
        this.characterService.getCharactersWithChanges({ type: this.form.value.type })
            .subscribe(function (result) {
            _this.characters = result;
        });
    };
    SelectChangeComponent.prototype.characterSelected = function () {
        var _this = this;
        this.characterService.getChanges(this.form.value.character.id, this.type)
            .subscribe(function (result) {
            _this.characterChanges = result;
        });
    };
    SelectChangeComponent.prototype.onSubmit = function () {
        var _this = this;
        this.formSubmitLoader = true;
        var form = this.form.value;
        form.items = this.queueService.items.slice();
        this.characterChangeService.assignItems(this.form.value.character_change.id, this.queueService.items.slice())
            .subscribe(function (result) {
            var character = _this.characters.find(function (item) { return item.id == _this.form.value.character.id; });
            var change = _this.characterChanges.find(function (item) { return item.id == _this.form.value.character_change.id; });
            _this.toastr.success("Success", "Items have been assigned to Character - " + character.display_text + " and " + _this.type + " " + change.name);
            _this.recordAdded.emit();
            _this.activeModal.close();
        }, function (error) {
            _this.formSubmitLoader = false;
        });
    };
    return SelectChangeComponent;
}());
export { SelectChangeComponent };
