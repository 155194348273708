import { HttpClient } from '@angular/common/http';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import * as i0 from "@angular/core";
import * as i1 from "ngx-permissions";
import * as i2 from "@angular/common/http";
var PermissionService = /** @class */ (function () {
    function PermissionService(ngxPermissionsService, ngxRolesService, http) {
        this.ngxPermissionsService = ngxPermissionsService;
        this.ngxRolesService = ngxRolesService;
        this.http = http;
        // this.permissions = [
        //   "Stripboard",
        //   "Inventory",
        //   "Order",
        //   "Set",
        //   "Character",
        //   "ManageUser",
        //   "ProductionAdmin"
        // ];
        // this.ngxPermissionsService.loadPermissions(this.permissions);
    }
    PermissionService.prototype.getPermission = function () {
        var _this = this;
        return this.http.get('permission')
            .map(function (response) {
            _this.permissions = response.permissions.slice();
            _this.ngxPermissionsService.flushPermissions();
            _this.ngxPermissionsService.loadPermissions(_this.permissions);
            return response;
        });
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.NgxPermissionsService), i0.inject(i1.NgxRolesService), i0.inject(i2.HttpClient)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
