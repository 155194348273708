<div class="container" style="width: 806px;">
  <div class="row">
  <div class="col-md-4">
    <div class="card border">
        <img class="card-img-top mx-auto d-block" src="./../../../assets/img/photos/dd.png" alt="Card image cap">
        <div class="card-body p-1 border-top">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">Some quick example \</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
        <div class="card border">
            <img class="card-img-top mx-auto d-block" src="./../../../assets/img/photos/ff.png" alt="Card image cap">
            <div class="card-body p-1 border-top">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">Some quick example \</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
            <div class="card border">
                <img class="card-img-top mx-auto d-block" src="./../../../assets/img/photos/dd.png" alt="Card image cap">
                <div class="card-body p-1 border-top">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">Some quick example \</p>
                </div>
              </div>
            </div>
      </div>
      <app-light-box></app-light-box>
</div>
