import { FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AttachmentModel } from '../../_models/attachment.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  public attachments: AttachmentModel[];
  public uploadInProgress = false
  @Output() filesAdded = new EventEmitter<any>();
  @Output() filesRemoved = new EventEmitter<any>();
  @Output() makeAsPrimary = new EventEmitter<any>();

  @Input('existingAttachments')
  set existingAttachments(value: AttachmentModel[]) {
    this.attachments = value;
  }

  get existingAttachments() {
    return this.attachments;
  }

  public _hideImages: boolean = false;
  @Input('hideImages')
  set hideImages(value: boolean) {
    this._hideImages = value;
  }

  get hideImages() {
    return this._hideImages;
  }

  public _form: FormArray;
  @Input('form')
  set form(value: FormArray) {
    this._form = value;
  }


  public _type: string = null;
  @Input('type')
  set type(value: string) {
    this._type = value;

    if (value === 'Item')
      this.attachments = new Array();
  }

  get type() {
    return this._type;
  }

  public url: string;

  constructor() {
    this.attachments = new Array();
    this.url = environment.attachmentUploadUrl;
  }

  ngOnInit() {
    if ((this.type == 'Item' || this.type == 'Vendor') && this._form)
      this.attachments = this._form.value;
  }

  beforeRequest(xhr) {
    xhr.setRequestHeader(
      'Authorization',
      'Bearer' + localStorage.getItem('token')
    );
    xhr.setRequestHeader(
      'uuid',
      JSON.parse(localStorage.getItem('currentProduction')).uuid
    );
  }

  // @Output binding, set uploadInProgress to true to display loading icon
  filesUpdated(files) {
    this.uploadInProgress = true
  }

  // @Output binding, handle successful upload
  fileUploaded(event) {
    this.uploadInProgress = false
    const attachment = new AttachmentModel();
    if (event[1]) {
      attachment.makeObjectFromJson(JSON.parse(event[1]));

      if (this.attachments === undefined) {
        this.attachments = new Array();
      }

      this.attachments.push(attachment);

      this.filesAdded.emit(attachment);
    }
  }

  fileRemoved(attachment: AttachmentModel) {
    const index = this.attachments.findIndex(item => item.id === attachment.id);

    if (index !== -1) {
      this.attachments.splice(index, 1);
      this.filesRemoved.emit(attachment);
    }
  }

  beforeFileUpload(formData) {
    return formData;
  }

  getTemplate() {
    if (this.type === 'Order' || this.type === 'Vendor' || this.type === 'Item') {
      return '<div class=" xt-form-btn"><button class="btn btn-primary text-center font-medium-1">Choose a file to upload</button></div>';
    } else {
      return (
        '<div class="xt-upload-container">\n' +
        '                <div class="xt-upload-logo-icon">\n' +
        '                    <i class="ft-upload text-bold-600"></i>\n' +
        '                </div>\n' +
        '            </div>'
      );
    }
  }

  downloadFile(attachment: AttachmentModel) {
    window.open(
      attachment.download_path,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
}
