<div class="xt-fullwidth-mid-modal">
  <div class="modal-header xt-model-fixed-header">
    <div class="full-width">
      <div class="row">
        <div class="col-md-2">
          <h4 class="modal-title">
            <a class="btn xt-primary" (click)="activeModal.close('Close click')">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              <span>Data Upload</span>
            </a>
          </h4>
        </div>
        <div class="col-md-8 text-center">
          <div class="mt-1 text-bold-400">Import {{ section }}
          </div>
        </div>
        <div class="col-md-2 text-right">
          <button class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body container p-3">
    <div class="row">
      <div class="col-md-6 mx-auto">
        <h4 class="mt-3 text-center ">Time to match your data to the columns</h4>
        <p class="xt-dialogue-intron  p-2">
          Let’s match your data to the [section] columns. Once you have chosen a column name
          click the “Save” button to confirm your selection.
        </p>
      </div>
    </div>
    <form [formGroup]="rowsImportForm">
      <div formArrayName="rows_import" class="row mt-4">
        <div class="col-md-3" *ngFor="let rowToImport of rowsImportForm.get('rows_import')['controls']; let i = index"
          [class.offset-md-3]="i%2 === 0" [formGroupName]="i">
          <div class="xt-data-columns mb-3" *ngIf="columnsSet && columnsSet[i]">
            <div class="px-2 py-3">
              <h6 class="text-dark" [innerHTML]="rowToImport.value.label"></h6>
            </div>
            <div class="xt-upload-data-list" formArrayName="columns">
              <p *ngFor="let row of rowToImport.value.columns; let _i = index" [formGroupName]="_i">
                <p-checkbox binary="true" [label]="row.label" formControlName="is_selected"></p-checkbox>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row mt-4">
      <div class="col-md-3" *ngFor="let column of columns; let i = index" [class.offset-md-3]="i%2 === 0">
        <div class="xt-data-columns mb-3" *ngIf="columnsSet && columnsSet[i]">
          <div class="px-2 py-3">
            <h6 class="text-dark" [innerHTML]="column.id"></h6>
            <ng-select [(ngModel)]="columnsSelectedSet[i]" [items]="columnsSet[i]" [bindValue]="id" bindLabel="text"
              (change)="columnSelected($event, i)">
            </ng-select>
          </div>
          <div class="xt-upload-data-list">
            <p *ngFor="let row of rows"><span
                *ngIf="this.columnsSelectedSet[i] && this.columnsSelectedSet[i].text">{{ row[this.columnsSelectedSet[i].text] }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="xt-dialogue-btn row">
      <div class="col-md-3"></div>
      <button type="button" class="btn xt-blue-btn-lg col-md-2 my-4 ml-2" (click)="importClicked()">
        <i [class]="formSubmitLoader ? userSettingService.getSpinnerClass() : 'fa fa-check-square-o'"></i>
        Import items <i class="ft-chevron-right"></i>
      </button>
    </div>
  </div>
</div>