<div class="p-4">
  <div class="modal-header pt-0 no-border">
    <h5 class="modal-title">Coming Soon</h5>
  </div>
  <div class="modal-body">
    <p class="text-muted">We are working on this and will be published very soon</p>
  </div>
  <div class="modal-footer pb-0 no-border">
    <button type="button" class="btn btn-outline-secondary mr-1"
      (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>