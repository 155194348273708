var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
var TagModel = /** @class */ (function (_super) {
    __extends(TagModel, _super);
    function TagModel(id, tag, display, value) {
        if (id === void 0) { id = null; }
        if (tag === void 0) { tag = null; }
        if (display === void 0) { display = null; }
        if (value === void 0) { value = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.tag = tag;
        _this.display = display;
        _this.value = value;
        return _this;
    }
    return TagModel;
}(BaseModel));
export { TagModel };
