import { WrapItemConfirmComponent } from './../wrap-item-confirm/wrap-item-confirm.component';
import { Validators } from '@angular/forms';
import { getTestBed } from '@angular/core/testing';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QueueService } from 'app/_services/queue.service';
import { ItemService } from 'app/_services/item.service';

@Component({
  selector: 'app-wrap-items',
  templateUrl: './wrap-items.component.html',
  styleUrls: ['./wrap-items.component.scss']
})
export class WrapItemsComponent implements OnInit {
  public form: FormGroup;
  public formSubmitLoader: boolean;
  @Output() recordAdded = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public queueService: QueueService,
    private modalService: NgbModal,
    public itemService: ItemService) { }

  ngOnInit() {
    this.form = this.fb.group({
      pallet_number: [null, [Validators.required]],
      box_number: [null, [Validators.required]]
    });
  }

  onSubmit() {
    this.formSubmitLoader = false;
    let form = this.form.value;
    form.items = [...this.queueService.items];

    this.itemService.wrapItems(form)
      .subscribe(
        result => {
          const modalRef = this.modalService.open(WrapItemConfirmComponent,
            {
              windowClass: "modal-md modal-xxl xt-full-screen-modal"
            }).componentInstance;
          this.recordAdded.emit();
          this.activeModal.close();
        },
        error => {
          this.formSubmitLoader = false;
        }
      );
  }
}
