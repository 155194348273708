import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NoteModel } from 'app/_models/note.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(public http: HttpClient) { }

  store(form: any) {
    return this.http.post('note', form)
      .map(
        (response: any) => {
          return <NoteModel>response;
        }
      );
  }

  delete(id) {
    return this.http.post('note/' + id, {
      _method: "DELETE"
    });
  }

  update(id: number, form: any) {
    return this.http.post('note/' + id, form);
  }

  get(type: string, typeId: number, roleId: number): Observable<NoteModel[]> {
    return this.http.post('note/custom/' + type + '/' + typeId, {
      role_id: roleId
    })
      .map(
        (response: any) => {
          return <NoteModel[]>response;
        }
      );
  }
}
