export class BaseModel {
    constructor() {

    }

    makeObjectFromJson(result: any) {
        for (const item of Object.getOwnPropertyNames(this)) {
            this[item] = result[item];
        }
    }
}