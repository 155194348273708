import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-index-title',
  templateUrl: './index-title.component.html',
  styleUrls: ['./index-title.component.scss']
})

export class IndexTitleComponent implements OnInit {

  /**
   * Page title to be displayed
   */
  @Input() title: string

  /**
   * Last time the current page data was updated
   */
  @Input() lastUpdateTime?: string

  /**
   * String to be checked against permissions service for page actions
   */
  @Input() permissionsString: string

  /**
   *  Flag whether actions are available on this page
   */
  @Input() hasPageActions = false

  constructor() { }

  ngOnInit() {
  }

}
