import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'displayDate'
})
export class DisplayDatePipe implements PipeTransform {

    constructor(public datePipe: DatePipe) {}

    transform(value: any, args?: any): any {
        if (value) {
            if (typeof value === 'string') {
                const dateObject = new Date(value)
                return this.datePipe.transform(dateObject, 'dd MMM yyyy');
            }
            value = this.datePipe.transform(new Date(value.year, (value.month - 1), value.day), 'dd MMM yyyy');
        }

        return value;
    }
}

@Pipe({
  name: 'displayDateTime'
})
export class DisplayDateTimePipe implements PipeTransform {

  constructor(public datePipe: DatePipe) {}

  transform(value: any, args?: any): any {
    if (value && typeof value === 'string') {
        value = this.datePipe.transform(new Date(value), 'dd MMM yyyy, HH:mm');
    }
    return value;
  }

}
