import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SetContinuityService = /** @class */ (function () {
    function SetContinuityService(http) {
        this.http = http;
    }
    SetContinuityService.prototype.getRecordsForSelect2 = function (records) {
        var items = new Array();
        for (var _i = 0, records_1 = records; _i < records_1.length; _i++) {
            var record = records_1[_i];
            items.push({
                id: record.id,
                text: record.scene.scene_number
            });
        }
        return items;
    };
    SetContinuityService.ngInjectableDef = i0.defineInjectable({ factory: function SetContinuityService_Factory() { return new SetContinuityService(i0.inject(i1.HttpClient)); }, token: SetContinuityService, providedIn: "root" });
    return SetContinuityService;
}());
export { SetContinuityService };
