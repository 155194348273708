var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
var DepartmentModel = /** @class */ (function (_super) {
    __extends(DepartmentModel, _super);
    function DepartmentModel(id, name, slug) {
        if (id === void 0) { id = null; }
        if (name === void 0) { name = null; }
        if (slug === void 0) { slug = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.name = name;
        _this.slug = slug;
        return _this;
    }
    return DepartmentModel;
}(BaseModel));
export { DepartmentModel };
