import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SetModel } from '../_models/set.model';
import { ProductionService } from './production.service';
import { Observable } from 'rxjs';
import { SceneModel } from 'app/_models/scene.model';

@Injectable()
export class SetService {

    constructor(public http: HttpClient,
        public productionService: ProductionService) {
    }

    getAllRecords() {
        return this.http.get('set')
            .map(
                (response: any) => {
                    const records: SetModel[] = [];
                    for (const json of response) {
                        const record: SetModel = new SetModel();
                        record.makeObjectFromJson(json);
                        records.push(record);
                    }
                    return records;
                }
            );
    }

    get(id: number) {
        return this.http.get('set/' + id)
            .map(
                (response: any) => {
                    const record: SetModel = new SetModel();
                    record.makeObjectFromJson(response);
                    return record;
                }
            );
    }

    store(form: any) {
        return this.http.post('set', { set: form })
            .map(
                (response: any) => {
                    if (this.productionService.currentProductionValue.set_count <= 0) {
                        this.productionService.currentProductionValue.set_count = 1;
                    }
                    return response;
                }
            );
    }

    update(id: number, form: any) {
        return this.http.post('set/' + id, { set: form, _method: 'PUT' });
    }

    delete(id: number) {
        return this.http.post('set/' + id, { _method: 'DELETE' });
    }

    getRecordsForSelect2Items(records: SetModel[]) {
        const sets: any[] = [];
        for (const record of records) {
            sets.push(
                {
                    id: record.id,
                    text: record.name
                }
            );
        }
        return sets;
    }

    assignItems(setId: number, form: any) {
        return this.http.post('set/assign-item/' + setId, {
            items: form
        });
    }

    getLastUpdateTime(): Observable<string> {
        return this.http.get('set/custom/last-update')
            .map(
                (response: any) => {
                  if (response) return <string>response.data
                  else return null
                }
            );
    }

    getScenesForSet(setId: number) {
        return this.http.get('scene/custom/get/set-scenes/' + setId)
            .map(
                (response: any) => {
                    return <SceneModel[]>response;
                }
            )
    }
}
