import { StripboardColorModel } from './stripboard-color.model';
import { SceneModel } from './scene.model';
import { BaseModel } from "./base.model";
import { StripboardModel } from './stripboard.model';

export class StripboardColorSceneModel extends BaseModel {
    public scene: SceneModel;
    public stripboard_color: StripboardColorModel;

    constructor(public id: number = null,
        public is_omitted: boolean = null,
        public display_scene_number: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        //Scene
        if (result["scene"]) {
            this.scene = new SceneModel();
            this.scene.makeObjectFromJson(result["scene"]);
        }

        //Stripboard Color
        if (result["stripboard_color"]) {
            this.stripboard_color = new StripboardColorModel();
            this.stripboard_color.makeObjectFromJson(result["stripboard_color"]);

            if (result["stripboard_color"]["stripboard"]) {
                this.stripboard_color.stripboard = new StripboardModel();
                this.stripboard_color.stripboard.makeObjectFromJson(result["stripboard_color"]["stripboard"]);
            }
        }
    }
}