<div class="index-actions row justify-content-between align-items-center" [formGroup]="searchForm">
    <div class="index-actions__left d-flex align-items-center">
        <div class="index-actions__preset-menu" ngbDropdown autoClose="outside">
            <button ngbDropdownToggle class="menu-link">
                View <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu>
                <h4 class="dropdown-header">Select a view</h4>
                <div class="dropdown-item" *ngFor="let preset of indexService.availablePresets; let i = index">
                    <button class="index-actions__preset-select btn" (click)="triggerSwitchCurrentPreset(i)">
                        <ng-container *ngIf="preset===indexService.currentPreset">
                            <i class="fa fa-check"></i>
                        </ng-container>
                        {{preset.name}}
                    </button>
                    <ng-container *ngIf="preset.isDefault && preset.modified">
                      <button class="index-actions__preset-action btn" (click)="triggerResetDefaultPreset()" attr.aria-label="Reset '{{preset.name}}'"><i class="fa fa-undo"></i></button>
                    </ng-container>
                    <ng-container *ngIf="!preset.isDefault">
                      <button class="index-actions__preset-action btn" (click)="triggerDeletePreset(i)" attr.aria-label="Delete '{{preset.name}}'"><i class="fa fa-trash-o"></i></button>
                    </ng-container>
                </div>
                <div class="dropdown-divider"></div>
                <div ngbDropdown #createPresetDropdown="ngbDropdown" autoClose="outside" placement="bottom-right">
                    <button ngbDropdownToggle class="dropdown-item">
                        <i class="ft-plus"></i>Add a view
                    </button>
                    <div ngbDropdownMenu>
                        <app-create-preset-form (returnNewPreset)="createNewPreset($event); createPresetDropdown.close();"
                                                (close)="createPresetDropdown.close();">
                        </app-create-preset-form>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!hasSingleViewType" ngbDropdown placement="bottom-left">
            <button class="btn btn-sm btn-outline-secondary mb-0" id="index-actions__view-type" aria-label="Select view mode" ngbDropdownToggle>
                <i [class]="getViewModeClass()" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="index-actions__view-type">
                <h4 class="dropdown-header">Layout</h4>
                <button class="dropdown-item" (click)="updateViewMode('List')">
                    List<i class="fa fa-list-ul pull-right"></i>
                </button>
                <button class="dropdown-item" (click)="updateViewMode('Grid')">
                    Grid<i class="fa fa-th-large pull-right"></i>
                </button>
            </div>
        </div>
        <div *ngIf="hasParentViewType" ngbDropdown placement="bottom-left">
          <button ngbDropdownToggle class="menu-link" id="index-actions__parent-view-type">
            {{indexService.relativeView}} <i class="fa fa-angle-down" aria-hidden="true"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="index-actions__parent-view-type">
            <h4 class="dropdown-header">Switch</h4>
            <button class="dropdown-item" (click)="updateRelativeView('Parent')">
              Parent
            </button>
            <button class="dropdown-item" (click)="updateRelativeView('Child')">
              Child
            </button>
          </div>
        </div>
    </div>
    <div class="index-actions__right d-flex align-items-center">
        <div ngbDropdown [autoClose]="'outside'" [placement]="'bottom-left'" *ngIf="!hasNoFilters">
            <button ngbDropdownToggle class="menu-link">
                Filters <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
            <div class="index-actions__filter" ngbDropdownMenu>
                <div class="index-actions__filter-title">
                    <div class="dropdown-header">
                      <h4 class="dropdown-header__inner" ngbTooltip="{{filterTooltipText}}" placement="bottom">Filters <i class="fa fa-info-circle" aria-hidden="true"></i></h4>
                      <button class="dropdown-item" (click)="clearAllFilter()">
                          Clear All
                      </button>
                    </div>
                </div>
                <app-index-filter *ngFor="let filter of indexService.filters; let i = index"
                                  [filter]="filter"
                                  [index]="i"
                                  [listColumns]="listColumns"
                                  [filterOptions]="filterOptions"
                                  [filterForm]="searchForm">
                </app-index-filter>
                <div class="index-actions__additional-filters">
                  <ng-content select="[index-actions-additional-filters]"></ng-content>
                </div>
                <p class="index-actions__filter-empty" *ngIf="!indexService.filters.length && !indexService.preDefinedFilters.length">
                    No filters have been set
                </p>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item" (click)="addEmptyFilter()">
                  <i class="ft-plus"></i> Add Filter
                </button>

            </div>
        </div>
        <div [autoClose]="'outside'" ngbDropdown *ngIf="this.indexService.viewMode === 'List'">
            <button ngbDropdownToggle class="menu-link">
                Columns
                <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
            <div class="index-actions__columns" ngbDropdownMenu>
                <h4 class="dropdown-header">Show/Hide Columns</h4>
                <ng-container *ngFor="let col of listColumns">
                    <div *ngIf="!col.alwaysVisible" class="dropdown-item dropdown-item--flex">
                        {{col.header||col.field}}
                        <ui-switch size="small" color="#4A90E2" [checked]="!indexService.hiddenColumns[col.field]" (change)="updateColumnDisplay($event, col)"></ui-switch>
                    </div>
                </ng-container>
            </div>

        </div>
        <div class="position-relative has-icon-left index-actions__search" >
            <input type="text" class="form-control no-border"
                   placeholder="Search" formControlName="search"
                   (input)="emitSearchEvent()">
            <div class="form-control-position pt-1">
                <i class="ft-search font-medium-2"></i>
            </div>
        </div>
        <div ngbDropdown class="display-inline-block">
            <button class="btn btn-primary" id="index-actions__new" ngbDropdownToggle><i class="ft-plus"></i>New</button>
            <div ngbDropdownMenu aria-labelledby="index-actions__new">
                <ng-content select="[index-actions-create]"></ng-content>
            </div>
        </div>
        <ng-content select="[index-actions-additional]"></ng-content>
    </div>
</div>
