import { HttpClient } from '@angular/common/http';
import { FormArray } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AttachmentModel } from './../../../_models/attachment.model';
import { MatchColumnsComponent } from './../match-columns/match-columns.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSettingService } from 'app/_services/user-setting.service';

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss']
})
export class UploadCsvComponent implements OnInit {
  public attachments: AttachmentModel[];
  public form: FormGroup;
  public _columns: any;
  set columns(value: any) {
    this._columns = value;
  }
  get columns() {
    return this._columns;
  }

  public _section: string = '';
  set section(value: string) {
    this._section = value;
  }
  get section() {
    return this._section;
  }

  public _dataLink: string;
  set dataLink(value: string) {
    this._dataLink = value;
  }
  get dataLink() {
    return this._dataLink;
  }

  public _submitLink: string;
  set submitLink(value: string) {
    this._submitLink = value;
  }
  get submitLink() {
    return this._submitLink;
  }

  public _postBody: any;
  set postBody(value: any) {
    this._postBody = value;
  }
  get postBody() {
    return this._postBody;
  }

  public _useSmallModal: boolean;
  set useSmallModal(value: boolean) {
    this._useSmallModal = value
  }
  get useSmallModal() {
    return this._useSmallModal;
  }

  constructor(public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public userSettingService: UserSettingService,
    public fb: FormBuilder,
    public http: HttpClient) { }

  ngOnInit() {
    this.form = this.fb.group({
      attachments: this.fb.array([])
    });
  }

  matchUploadData() {
    const postBody = {
      ...this.postBody, ...{
        attachment: {
          id: this.form.value.attachments[0].id
        }
      }
    };

    const modalRef = this.modalService.open(MatchColumnsComponent, {
      windowClass: "modal-md modal-xxl xt-full-screen-modal"
    }).componentInstance;

    modalRef.columns = this.columns;
    modalRef.dataLink = this.dataLink + this.form.value.attachments[0].id;
    modalRef.submitLink = this.submitLink;
    modalRef.postBody = postBody;
    modalRef.section = this.section;

    this.activeModal.close();
  }

  addArrayForAttachment(attachment: AttachmentModel) {
    const records = this.form.get('attachments') as FormArray;

    records.push(this.fb.group({
      id: [attachment.id]
    }));

    this.http.get(this.dataLink + attachment.id)
      .subscribe(
        result => {

        },
        error => {
          this.attachments = new Array();
          while (records.length !== 0) {
            records.removeAt(0)
          }
        }
      );
  }

  removeAttachment(attachment: AttachmentModel) {
    const records = this.form.get('attachments')['controls'];

    const index = records.findIndex(item => item.value.id === attachment.id);
    if (index !== -1) {
      const attachments = this.form.get('attachments') as FormArray;
      attachments.removeAt(index);
    }
  }
}
