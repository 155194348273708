<form id="upload-csv-modal">
  <div *ngIf="!useSmallModal" class="modal-header xt-model-fixed-header">
    <div class="full-width">
      <div class="row">
        <div class="col-md-2 pt-1">
          <h4 class="modal-title">
            <a (click)="activeModal.close('Close click')">
              <i class="fa fa-angle-left mr-1" aria-hidden="true"></i>
              <span *ngIf="section">{{ section }}</span>
            </a>
          </h4>
        </div>
        <div class="col-md-8 text-center">
          <div class="mt-1 text-bold-400">Import {{ section }}
          </div>
        </div>
        <div class="col-md-2 text-right">
          <button class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body mt-5" [ngClass]="{'mt-5' : !useSmallModal}">
    <div class="row justify-content-center">
      <div class="d-flex align-items-center justify-content-center pt-4 width-700">
        <div class="col-md-12 px-3">
          <h4 class="mb-3" *ngIf="section">Import {{ section }} with CSV file</h4>
          <div>
            <div class="row">
              <div class="col-md-12">
                <app-file-upload (filesAdded)="addArrayForAttachment($event)"
                                 (filesRemoved)="removeAttachment($event)"
                                 [existingAttachments]="attachments">
                </app-file-upload>
              </div>
            </div>
            <p class="text-muted my-2">All {{section}} images will need to be uploaded manually when using this import
              feature. To do this navigate to the {{section}} item and select upload image. Images embedded in CSV
              files will be ignored.</p>
          </div>
          <div class="xt-dialogue-btn text-right" *ngIf="form.value.attachments.length > 0">
            <button type="button" class="btn btn-link mr-2" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary btn-lg" (click)="matchUploadData()">
              <i [class]="formSubmitLoader ? userSettingService.getSpinnerClass() : 'fa fa-check-square-o'"></i>
              Review Data <i class="ft-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
