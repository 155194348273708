import { CharacterChangeContinuityModel } from './../../../_models/character-change-continuity.model';
import { CharacterChangeService } from './../../../_services/character-change.service';
import { CharacterService } from './../../../_services/character.service';
import { CharacterChangeModel } from './../../../_models/character-change.model';
import { CharacterModel } from './../../../_models/character.model';
import { QueueService } from './../../../_services/queue.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SceneModel } from 'app/_models/scene.model';
import { SceneService } from 'app/_services/scene.service';
import { UserSettingService } from 'app/_services/user-setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-change',
  templateUrl: './select-change.component.html',
  styleUrls: ['./select-change.component.scss']
})
export class SelectChangeComponent implements OnInit {
  public form: FormGroup;
  @Output() recordAdded = new EventEmitter<any>();
  public types: any = [
    { id: 'Look' },
    { id: 'Change' }
  ];
  public characters: CharacterModel[];
  public formSubmitLoader: boolean;
  public characterChanges: CharacterChangeModel[];
  public _type: string;
  set type(value: string) {
    this._type = value;
  }
  get type() {
    return this._type;
  }

  constructor(public fb: FormBuilder,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    public userSettingService: UserSettingService,
    public characterService: CharacterService,
    public characterChangeService: CharacterChangeService,
    public queueService: QueueService) { }

  ngOnInit() {
    this.characterService.getCharactersWithChanges({ type: this.type })
      .subscribe(
        result => {
          this.characters = result;
        }
      );

    this.form = this.fb.group({
      character: this.fb.group({
        id: [null, [Validators.required]]
      }),
      character_change: this.fb.group({
        id: [null]
      }),
      items: this.fb.array([])
    });
  }

  typeSelected() {
    this.characters = null;
    this.characterService.getCharactersWithChanges({ type: this.form.value.type })
      .subscribe(
        result => {
          this.characters = result;
        }
      );
  }

  characterSelected() {
    this.characterService.getChanges(this.form.value.character.id, this.type)
      .subscribe(
        result => {
          this.characterChanges = result;
        }
      );
  }

  onSubmit() {
    this.formSubmitLoader = true;
    let form = this.form.value;
    form.items = [...this.queueService.items];

    this.characterChangeService.assignItems(this.form.value.character_change.id, [...this.queueService.items])
      .subscribe(
        result => {
          const character = this.characters.find(item => item.id == this.form.value.character.id);
          const change = this.characterChanges.find(item => item.id == this.form.value.character_change.id);

          this.toastr.success("Success", "Items have been assigned to Character - " + character.display_text + " and " + this.type + " " + change.name);
          this.recordAdded.emit();
          this.activeModal.close();
        },
        error => {
          this.formSubmitLoader = false;
        }
      );
  }
}
