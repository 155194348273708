import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ItemModel } from '../_models/item.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ItemService {

    constructor(public http: HttpClient,
        public toastr: ToastrService) {
    }

    get(id: number) {
        return this.http.get('item/' + id)
            .map(
                (response: any) => {
                    const record: ItemModel = new ItemModel();
                    record.makeObjectFromJson(response);
                    return record;
                }
            );
    }

    store(form: any) {
        return this.http.post('item', { item: form });
    }

    update(id: number, form: any) {
        return this.http.post('item/' + id, { item: form, _method: 'PUT' });
    }

    checkInItem(orderId: number, form: any) {
        return this.http.post('order/' + orderId + '/check-in', { item: form })
            .map(
                (response: any) => {
                    const records: ItemModel[] = [];
                    for (const json of response) {
                        const item = new ItemModel();
                        item.makeObjectFromJson(json);
                        records.push(item)
                    }
                    return records;
                }
            );
    }

    delete(id: number) {
        return this.http.post('item/' + id, { _method: 'DELETE' });
    }

    getAllRecords() {
        return this.http.get('item')
            .map(
                (response: any) => {
                    const records: ItemModel[] = [];
                    for (const json of response) {
                        const record: ItemModel = new ItemModel();
                        record.makeObjectFromJson(json);
                        records.push(record);
                    }
                    return records;
                }
            );
    }

    getRecordsForSelect2Items(records: ItemModel[]) {
        const items: any[] = [];
        for (const record of records) {
            items.push(
                {
                    id: record.id,
                    text: record.name
                }
            );
        }
        return items;
    }

    getItemTypesArray() {
        return [
            { id: 'ABC', text: 'ABC' },
            { id: 'DEF', text: 'DEF' }
        ]
    }

    getCurrencyTypesArray() {
        return [
            { id: 'ABC', text: 'ABC' },
            { id: 'DEF', text: 'DEF' }
        ]
    }

    wrapItems(form) {
        return this.http.post('item-wrap', form);
    }

    flagItem(id: number) {
        return this.http.get('item/' + id + '/flag/asset');
    }

    unwrapItems(form: any) {
        return this.http.post('item-unwrap', form);
    }

    addItemToOrder(form: any): Observable<ItemModel> {
        return this.http.post('item', { item: form })
            .map(
                (response: any) => {
                    return <ItemModel>response;
                }
            );
    }

    lastUpdateTime() {
        return this.http.get('item/custom/last-update')
            .map(
                (response: any) => {
                    return response!.data;
                }
            );
    }

    deleteTag(id: number) {
        return this.http.get('item/custom/delete/tag/' + id);
    }

    createReport(form: any) {
        return this.http.post('item/custom/create-report', form);
    }

    exportCsv(form: any) {
        return this.http.post('item/custom/create-csv', form);
    }

    printLabels(orderId: number, itemId: number) {
        this.toastr.info('Please wait while we are making the labels.', 'Info');

        return this.http.post('item/custom/print/labels', {
            order_id: orderId,
            item_id: itemId
        }, { responseType: 'blob' })
            .map(
                (response: any) => {
                    const newBlob = new Blob([<any>response], { type: 'application/pdf' });
                    const blobUrl = URL.createObjectURL(newBlob);
                    // const url = window.URL.createObjectURL(newBlob);
                    // window.open(url);

                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);
                    iframe.contentWindow.print();

                    return response;
                }
            );
    }

    addAttachment(form: any, itemId: number) {
        return this.http.post('item/custom/add/attachment/' + itemId, {
            attachments: [form]
        });
    }

    updateTags(form: any) {
        return this.http.post('item/custom/update-tags', form);
    }
}
