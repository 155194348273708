import { HttpClient } from '@angular/common/http';
import { CharacterChangeSceneModel } from './../_models/character-change-scene.model';
import { Injectable } from '@angular/core';

@Injectable()
export class CharacterChangeContinuityService {

  constructor(public http: HttpClient) { }

  getRecordsForSelect2(records: CharacterChangeSceneModel[]) {
      const items: any[] = new Array();
      for(const record of records) {
        items.push({
          id: record.id,
          text: record.scene.scene_number
        });
      }
      return items;
  }

  store(form: any) {
    return this.http.post('character-change-continuity', form);
  }
}
