import { FormBuilder } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddPhotoComponent } from './../add-photo/add-photo.component';
import { EditPhotoComponent } from './../edit-photo/edit-photo.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit } from '@angular/core';
import { ItemService } from 'app/_services/item.service';
import { QueueService } from 'app/_services/queue.service';
var ItemDetailsComponent = /** @class */ (function () {
    function ItemDetailsComponent(modalService, activeModal, itemService, http, queueService, fb) {
        this.modalService = modalService;
        this.activeModal = activeModal;
        this.itemService = itemService;
        this.http = http;
        this.queueService = queueService;
        this.fb = fb;
        this.recordAdded = new EventEmitter();
    }
    Object.defineProperty(ItemDetailsComponent.prototype, "itemId", {
        get: function () {
            return this._itemId;
        },
        set: function (value) {
            this._itemId = value;
            this.getItem();
        },
        enumerable: true,
        configurable: true
    });
    ItemDetailsComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group({
            attachments: this.fb.array([])
        });
    };
    ItemDetailsComponent.prototype.getItem = function () {
        var _this = this;
        this.itemService.get(this.itemId)
            .subscribe(function (result) {
            _this.item = result;
            _this.activeImage = _this.item.pic;
            // if (this.item['attachments'] && this.item['attachments'].length > 0) {
            //   this.activeImage = this.item['attachments'][0].file_path;
            // } else {
            // }
        });
    };
    ItemDetailsComponent.prototype.deleteItemModal = function (id) {
        var _this = this;
        this.itemService.delete(id)
            .subscribe(function (result) {
            _this.recordAdded.emit();
            _this.activeModal.close();
        });
    };
    ItemDetailsComponent.prototype.editPhotoModal = function () {
        var modalRef = this.modalService.open(EditPhotoComponent, {
            windowClass: "modal-md modal-xxl small-modal"
        });
    };
    ItemDetailsComponent.prototype.uploadPhotoModal = function () {
        var modalRef = this.modalService.open(AddPhotoComponent, {
            windowClass: "modal-md modal-xxl small-modal"
        });
    };
    ItemDetailsComponent.prototype.deleteTag = function (id) {
        var _this = this;
        this.itemService.deleteTag(id)
            .subscribe(function (result) {
            _this.getItem();
        });
    };
    ItemDetailsComponent.prototype.getSelectedAttachments = function () {
        return this.item.attachments.filter(function (item) { return item.is_selected === true; });
    };
    ItemDetailsComponent.prototype.deleteAttachments = function () {
        var _this = this;
        return this.http.post('attachment/custom/delete', { attachments: this.getSelectedAttachments() })
            .subscribe(function (result) {
            _this.getItem();
        });
    };
    ItemDetailsComponent.prototype.showDeleteAll = function () {
        if (this.getSelectedAttachments().length > 0) {
            return true;
        }
        return false;
    };
    ItemDetailsComponent.prototype.addArrayForAttachment = function (attachment) {
        var _this = this;
        var records = this.form.get('attachments');
        records.push(this.fb.group({
            id: [attachment.id]
        }));
        this.itemService.addAttachment(attachment, this.item.id)
            .subscribe(function (result) {
            _this.recordAdded.emit();
            _this.item.attachments.push(attachment);
        });
    };
    ItemDetailsComponent.prototype.removeAttachment = function (attachment) {
        var records = this.form.get('attachments')['controls'];
        var index = records.findIndex(function (item) { return item.value.id === attachment.id; });
        if (index !== -1) {
            var attachments = this.form.get('attachments');
            attachments.removeAt(index);
        }
    };
    ItemDetailsComponent.prototype.printLabels = function () {
        this.itemService.printLabels(this.item.order_id, this.item.id)
            .subscribe(function (result) {
            var newBlob = new Blob([result], { type: "application/pdf" });
            var url = window.URL.createObjectURL(newBlob);
            window.open(url);
        });
    };
    ItemDetailsComponent.prototype.setThumbnail = function (attachment) {
        var _this = this;
        return this.http.post('item/custom/set-thumbnail-image', {
            item: {
                id: this.item.id
            },
            attachment: {
                id: attachment.id
            }
        }).subscribe(function (result) {
            _this.recordAdded.emit();
        });
    };
    ItemDetailsComponent.prototype.toggleLabelPrint = function () {
        this.http.get('item/custom/toggle/label/generated/' + this.item.id)
            .subscribe(function (result) {
        });
    };
    return ItemDetailsComponent;
}());
export { ItemDetailsComponent };
