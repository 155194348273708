<form [formGroup]="form" (ngSubmit)="onSubmit(form)">
  <div class="p-4">
      <div class="modal-header pt-0 no-border">
          <h5 class="modal-title">{{title || 'Upload files'}}</h5>
      </div>
      <div class="modal-body">
          <p class="text-muted" *ngIf="description">{{description}}</p>
          <app-file-upload (filesAdded)="addArrayForAttachment($event)"
                           (filesRemoved)="removeAttachment($event)"
                           [existingAttachments]="attachments">
          </app-file-upload>
      </div>
      <div class="modal-footer pb-0 no-border">
          <button type="button" class="btn btn-outline-secondary mr-1" (click)="activeModal.close('Close click')" [disabled]="formSubmitLoader">Cancel</button>
          <button type="submit" class="btn btn-primary"  [disabled]="formSubmitLoader || form.invalid">
            <i [class]="formSubmitLoader ? userSettingService.getSpinnerClass() : 'fa fa-check-square-o'"></i>
            {{uploadActionLabel || 'Add Photos'}}
          </button>
      </div>
  </div>
</form>
