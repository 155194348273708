import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-common-counter',
  templateUrl: './common-counter.component.html',
  styleUrls: ['./common-counter.component.scss']
})
export class CommonCounterComponent implements OnInit {
  private _control: AbstractControl = null;
  @Input('control')
  set control(control: any) {
    this._control = control;
  }
  get control() {
    return this._control;
  }

  private _quantity: number = null;
  @Input('quantity')
  set quantity(value: number) {
    this._quantity = value;
  }
  get quantity() {
    return this._quantity;
  }

  private _maxQuantity: number = null;
  @Input('maxQuantity')
  set maxQuantity(value: number) {
    this._maxQuantity = value;
  }
  get maxQuantity() {
    return this._maxQuantity;
  }

  private _minQuantity = 0;
  @Input('minQuantity')
  set minQuantity(value: number) {
    this._minQuantity = value;
  }
  get minQuantity() {
    return this._minQuantity;
  }

  @Output() quantityUpdated = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {

  }

  increase() {
    if (!this.maxQuantity || (this.control.value + 1) <= this.maxQuantity) {
      if (this.control) this.control.setValue(this.control.value + 1);
      if (this.quantity) this.quantity += 1;
      this.quantityUpdated.emit(this.quantity);
    }
  }

  decrease() {
    if ((this.control.value - 1) < this.minQuantity) return
    if (this.control) this.control.setValue(this.control.value - 1);
    if (this.quantity) this.quantity -= 1;

    this.quantityUpdated.emit(this.quantity);
  }
}
