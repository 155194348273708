import { AttachmentModel } from '../_models/attachment.model';
import { SceneModel } from '../_models/scene.model';
import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class SceneService {
  @Output() recordAdded;
  public selectedRecords: any[];

  constructor(public http: HttpClient) {
    this.recordAdded = new EventEmitter<any>();
    this.selectedRecords = [];
  }

  store(form: any) {
    return this.http.post('scene', { scene: form })
      .map(
        (response: any) => {
          this.recordAdded.emit();
          return response;
        }
      );
  }

  update(form: any, id: number) {
    return this.http.post('scene/' + id, { scene: form, _method: 'PUT' })
      .map(
        (response: any) => {
          this.recordAdded.emit();
          return response;
        }
      );
  }

  get(id: number) {
    return this.http.get('scene/' + id)
      .map(
        (response: any) => {
          const record = new SceneModel();
          record.makeObjectFromJson(response);
          return record;
        }
      );
  }

  getAllScenes() {
    return this.http.get('all/scene')
      .map(
        (response: any) => {
          const records: SceneModel[] = [];
          for (const json of response) {
            const record = new SceneModel();
            record.makeObjectFromJson(json);
            records.push(record);
          }
          return records;
        }
      );
  }

  getAllScenesForStripboard(stripboardId: number) {
    return this.http.get('stripboard/' + stripboardId + '/scene')
      .map(
        (response: any) => {
          const records: SceneModel[] = [];
          for (const json of response) {
            const record = new SceneModel();
            record.makeObjectFromJson(json);
            records.push(record);
          }
          return records;
        }
      );
  }

  getRecordsForSelect2Items(records: SceneModel[]) {
    const items: any[] = new Array();
    for (const record of records) {
      items.push(
        {
          id: record.id,
          text: record.display_text
        }
      );
    }
    return items;
  }

  delete(id: number) {
    return this.http.post('stripboard-color-scene/' + id, { _method: 'DELETE' })
      .map(
        (response: any) => {
          this.recordAdded.emit();
          return response;
        }
      );
  }

  omitScene(stripboardColorSceneId: number) {
    return this.http.get('scene/' + stripboardColorSceneId + '/omit-scene');
  }

  reinstateScene(stripboardColorSceneId: number) {
    return this.http.get('scene/' + stripboardColorSceneId + '/reinstate-scene');
  }

  deleteAll() {
    return this.http.post('scene/delete/all', this.selectedRecords)
      .map(
        (response: any) => {
          this.recordAdded.emit();
          this.selectedRecords = [];
          return response;
        }
      );
  }

  omitAll() {
    return this.http.post('scene/omit/all', this.selectedRecords)
      .map(
        (response: any) => {
          this.recordAdded.emit();
          this.selectedRecords = [];
          return response;
        }
      );
  }

  addPhotos(sceneId: number, form: any) {
    return this.http.post('scene-add-photos/' + sceneId, form);
  }

  deletePhotos(form: any) {
    return this.http.post('scene-delete-photos', form);
  }

  movePhotos(form: any) {
    return this.http.post('scene-move-photos', form);
  }

  copyPhotos(form: any) {
    return this.http.post('scene-copy-photos', form);
  }

  getPhotos(sceneId: number) {
    return this.http.get('scene-get-photos/' + sceneId)
      .map(
        (response: any) => {
          const records: AttachmentModel[] = [];
          for (const json of response) {
            const record: AttachmentModel = new AttachmentModel();
            if (json['attachment']) {
              record.makeObjectFromJson(json['attachment']);
              records.push(record);
            }
          }
          return records;
        }
      )
  }

  addHeroProps(sceneId: number, form: any) {
    return this.http.post('scene/' + sceneId + '/assign-hero-prop', form);
  }

  addContinuity(sceneId: number, form: any) {
    if (form.type === 'Set') {
      return this.http.post('scene/' + sceneId + '/assign-set-continuity', form);
    } else {
      return this.http.post('scene/' + sceneId + '/assign-continuity', form);
    }
  }

  addChange(form: any) {
    return this.http.post('scene/' + form.scene.id + '/assign-change', form);
  }
}
