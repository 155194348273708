import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListViewColumn} from '../../views/list-view/list-view.model';
import {FormGroup} from '@angular/forms';
import {IndexService} from '../../../_services/index.service';

@Component({
    selector: 'app-index-filter',
    templateUrl: './index-filter.component.html',
    styleUrls: ['./index-filter.component.scss']
})
export class IndexFilterComponent implements OnInit {

    @Input() filter: {field: string|null, filter: any }
    @Input() index: number
    @Input() listColumns: Array<ListViewColumn>
    @Input() presetColumn: ListViewColumn
    @Input() filterForm: FormGroup;
    @Input() filterOptions: any;

    public selectedColumn: ListViewColumn
    public costObject = {cost_min: null, cost_max: null, currency: null}
    public dateObject = {type: null, from: null, to: null}

    constructor(public indexService: IndexService) { }

    ngOnInit() {
        if (this.listColumns) {
            this.selectedColumn = this.listColumns.find(c => c.field === this.filter.field)
        } else if (this.presetColumn) {
            this.selectedColumn = this.presetColumn
        }
        if (this.selectedColumn && this.selectedColumn.filterType === 'dateRange') this.populateDateFields();
    }

    /**
     * Resolve the key to the final child when accessing multilevel objects ex. lvl1.lvl2.lvl3
     * @param path String object path to be resolved
     */
    resolveToLast(path) {
        path = path.split('.');
        return path[path.length - 1];
    }

    /**
     * Select the column to be filtered from the drop down menu
     * @param event
     */
    selectColumnToFilter(event) {
        this.selectedColumn = this.listColumns[event.target.value]
    }

    /**
     * On load, if a date filter exists, populate the date picker fields with existing values
     */
    populateDateFields () {
      this.dateObject.to = this.filter.filter.to
      this.dateObject.from = this.filter.filter.from
    }

    filterUpdate(filterValue, colField) {
        this.indexService.populateFilter({index: this.index, field: colField, value: filterValue})
    }

    populateCostObject(filterValue, filterField) {
        this.costObject[filterField] = filterValue
    }

    filterByCost(colField) {
      this.indexService.populateFilter({index: this.index, field: colField, value: this.costObject })
    }

    filterByDate(colField) {
        this.dateObject.type = [colField]
        this.indexService.populateFilter({index: this.index, field: colField, value: this.dateObject })
    }

    /**
     * Call index service to remove the current filter
     */
    removeCurrentFilter() {
        this.indexService.removeFilter(this.index)
    }


}
