import { QueueService } from 'app/_services/queue.service';
import { DomService } from 'app/_services/dom.service';
import { PasswordGuard } from './_guards/password.guard';
import { PermissionService } from './_services/permission.service';
import { UserService } from './_services/user.service';
import { AuthService } from 'app/_services/auth.service';
import { HttpConfigInterceptor } from './_interceptors/httpconfig.interceptor';
import { AuthGuard } from './_guards/auth.guard';

import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import * as $ from 'jquery';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ProductionGuard } from './_guards/production.guard';
import { DatePipe } from '@angular/common';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    ComingSoonComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgbModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    NgxPermissionsModule.forRoot(),
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    AuthService,
    AuthGuard,
    ProductionGuard,
    UserService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    PermissionService,
    PasswordGuard,
    DomService,
    QueueService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ComingSoonComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
