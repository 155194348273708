import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RoleModel} from "../_models/role.model";
import { Observable } from 'rxjs';

@Injectable()
export class DepartmentService {

    constructor(public http: HttpClient) {
    }

    get(id: number) {
        return this.http.get("department/" + id)
            .map(
                (response: any) => {
                    const record: RoleModel = new RoleModel();
                    record.makeObjectFromJson(response);
                    return record;
                }
            );
    }

    getAllRecords(): Observable<RoleModel[]> {
        return this.http.get("department")
            .map(
                (response: any) => {
                    return <RoleModel[]>response;
                }
            );
    }

    getRecordsForSelect2Items(records: RoleModel[]) {
        const departments: any[] = new Array();
        for (const record of records) {
            departments.push(
                {
                    id: record.id,
                    text: record.name
                }
            );
        }
        return departments;
    }

}
