/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../shared/footer/footer.component.ngfactory";
import * as i7 from "../../shared/footer/footer.component";
import * as i8 from "./full-layout.component";
import * as i9 from "../../_services/dom.service";
var styles_FullLayoutComponent = [i0.styles];
var RenderType_FullLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullLayoutComponent, data: {} });
export { RenderType_FullLayoutComponent as RenderType_FullLayoutComponent };
function View_FullLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavbarComponent, [i4.Router, i4.ActivatedRoute], null, null)], null, null); }
export function View_FullLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "wrapper"]], [[8, "dir", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullLayoutComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "main-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(10, 49152, null, 0, i7.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.domService.hideNavbar; _ck(_v, 2, 0, currVal_1); _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.direction; _ck(_v, 0, 0, currVal_0); }); }
export function View_FullLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-layout", [], null, null, null, View_FullLayoutComponent_0, RenderType_FullLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i8.FullLayoutComponent, [i9.DomService], null, null)], null, null); }
var FullLayoutComponentNgFactory = i1.ɵccf("app-full-layout", i8.FullLayoutComponent, View_FullLayoutComponent_Host_0, {}, {}, []);
export { FullLayoutComponentNgFactory as FullLayoutComponentNgFactory };
