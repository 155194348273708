import { DomService } from "app/_services/dom.service";
import { PermissionService } from "./../../_services/permission.service";
import { Component } from "@angular/core";

@Component({
  selector: "app-full-layout",
  templateUrl: "./full-layout.component.html",
  styleUrls: ["./full-layout.component.scss"]
})
export class FullLayoutComponent {
  options = {
    direction: "ltr"
  };

  constructor(public domService: DomService) {}
}
