import { StripboardService } from './stripboard.service';
import { Injectable } from '@angular/core';
import { StripboardColorModel } from '../_models/stripboard-color.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StripboardColorService {

  constructor(public http: HttpClient,
    public stripboardService: StripboardService) { }

  store(form: any) {
    return this.http.post('stripboard-color', { stripboard_color: form })
      .map(
        (response: any) => {
          const stripboardColor: StripboardColorModel = new StripboardColorModel();
          stripboardColor.makeObjectFromJson(response);
          return stripboardColor;
        }
      );
  }

  udpate(form: any, stripboadColorId: number) {
    return this.http.post('stripboard-color/' + stripboadColorId, { stripboard_color: form, _method: "PUT" })
      .map(
        (response: any) => {
          return <StripboardColorModel>response;
        }
      );
  }

  getRecordsForSelect2Items(records: StripboardColorModel[]) {
    const items: any[] = new Array();
    for (const record of records) {
      items.push(
        {
          id: record.id,
          text: record.color
        }
      );
    }
    return items;
  }

  delete(id: number) {
    return this.http.post('stripboard-color/' + id, { _method: "DELETE" })
      .map(
        (response: any) => {
          this.stripboardService.stripboardColorSelected = null;
          return response;
        }
      );
  }

  get(id: number) {
    return this.http.get("stripboard-color/" + id)
      .map(
        (response: any) => {
          return <StripboardColorModel>response;
        }
      );
  }
}
