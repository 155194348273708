import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class SceneNoteService {

  constructor(public http: HttpClient) { }

  delete(notes: any[]){
    return this.http.post("XYZ", notes);
  }
}
