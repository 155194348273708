/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-permission.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./no-permission.component";
import * as i3 from "@angular/router";
var styles_NoPermissionComponent = [i0.styles];
var RenderType_NoPermissionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoPermissionComponent, data: {} });
export { RenderType_NoPermissionComponent as RenderType_NoPermissionComponent };
export function View_NoPermissionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "d-flex justify-content-center align-items-center no-permission"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "../assets/img/elements/oh-no.png"], ["width", "80%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "font-weight-bold "]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You don't have permission to view this page"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "small", [["class", "text-muted font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sorry,it appears you do not have the necessary permission to view the page "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary text-white"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.router.navigate(["/production"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go Back"]))], null, null); }
export function View_NoPermissionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-permission", [], null, null, null, View_NoPermissionComponent_0, RenderType_NoPermissionComponent)), i1.ɵdid(1, 114688, null, 0, i2.NoPermissionComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoPermissionComponentNgFactory = i1.ɵccf("app-no-permission", i2.NoPermissionComponent, View_NoPermissionComponent_Host_0, {}, {}, []);
export { NoPermissionComponentNgFactory as NoPermissionComponentNgFactory };
