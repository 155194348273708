/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./index-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../../_pipes/display-date.pipe";
import * as i5 from "./index-title.component";
var styles_IndexTitleComponent = [i0.styles];
var RenderType_IndexTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IndexTitleComponent, data: {} });
export { RenderType_IndexTitleComponent as RenderType_IndexTitleComponent };
function View_IndexTitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["ngbDropdown", ""], ["placement", "bottom-right"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(2, 737280, null, 3, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _menuElement: 0 }), i1.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-sm btn-outline-secondary mb-0 dropdown-toggle"], ["id", "index-title__actions-dropdown"], ["ngbDropdownToggle", ""]], [[1, "aria-label", 0], [1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "ft-more-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[2, 0]], null, 3, "div", [["aria-labelledby", "index-title__actions-dropdown"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), i1.ɵncd(null, 0)], function (_ck, _v) { var currVal_1 = "bottom-right"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.title, " Actions"); var currVal_3 = i1.ɵnov(_v, 7).dropdown.isOpen(); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 11).dropdown.isOpen(); var currVal_6 = i1.ɵnov(_v, 11).placement; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_IndexTitleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DisplayDateTimePipe, [i3.DatePipe]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "index-title row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Last Updated: ", ""])), i1.ɵppd(7, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndexTitleComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.hasPageActions; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.lastUpdateTime ? "\u2026" : i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.lastUpdateTime))); _ck(_v, 6, 0, currVal_1); }); }
export function View_IndexTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-index-title", [], null, null, null, View_IndexTitleComponent_0, RenderType_IndexTitleComponent)), i1.ɵdid(1, 114688, null, 0, i5.IndexTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IndexTitleComponentNgFactory = i1.ɵccf("app-index-title", i5.IndexTitleComponent, View_IndexTitleComponent_Host_0, { title: "title", lastUpdateTime: "lastUpdateTime", permissionsString: "permissionsString", hasPageActions: "hasPageActions" }, {}, ["[index-title-options]"]);
export { IndexTitleComponentNgFactory as IndexTitleComponentNgFactory };
