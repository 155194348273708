import { PermissionService } from './../_services/permission.service';
import { Router } from '@angular/router';
import { ProductionService } from 'app/_services/production.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/production.service";
import * as i2 from "@angular/router";
import * as i3 from "../_services/permission.service";
var ProductionGuard = /** @class */ (function () {
    function ProductionGuard(productionService, router, permissionService) {
        this.productionService = productionService;
        this.router = router;
        this.permissionService = permissionService;
    }
    ProductionGuard.prototype.canActivate = function (next, state) {
        if (!this.permissionService.permissions) {
            this.router.navigate(['production/permissions'], {
                queryParams: {
                    redirect: state.url
                }
            });
            return false;
        }
        if (this.productionService.currentProductionValue) {
            return true;
        }
        this.router.navigate(['production']);
        return false;
    };
    ProductionGuard.ngInjectableDef = i0.defineInjectable({ factory: function ProductionGuard_Factory() { return new ProductionGuard(i0.inject(i1.ProductionService), i0.inject(i2.Router), i0.inject(i3.PermissionService)); }, token: ProductionGuard, providedIn: "root" });
    return ProductionGuard;
}());
export { ProductionGuard };
