import { Validators } from '@angular/forms';
import { SetService } from 'app/_services/set.service';
import { QueueService } from './../../../_services/queue.service';
import { FormBuilder } from '@angular/forms';
import { SetModel } from './../../../_models/set.model';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-set',
  templateUrl: './select-set.component.html',
  styleUrls: ['./select-set.component.scss']
})
export class SelectSetComponent implements OnInit {
  public form: FormGroup;
  public formSubmitLoader: boolean;
  public sets: SetModel[];
  @Output() recordAdded = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public queueService: QueueService,
    public setService: SetService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.form = this.fb.group({
      set: [null, [Validators.required]],
      items: this.fb.array([])
    });

    this.setService.getAllRecords()
      .subscribe(
        result => {
          this.sets = result;
        }
      );
  }

  onSubmit() {
    this.formSubmitLoader = true;
    let form = this.form.value;

    this.setService.assignItems(form.set, [...this.queueService.items])
      .subscribe(
        result => {
          this.recordAdded.emit();
          this.activeModal.close();
          const set = this.sets.find(item => item.id == this.form.value.set);

          this.toastr.success("Success", "Items have been assigned to " + set.name);
        },
        error => {
          this.formSubmitLoader = false;
        }
      );
  }
}
