import { QueueService } from './../../../_services/queue.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-wrap-item-confirm',
  templateUrl: './wrap-item-confirm.component.html',
  styleUrls: ['./wrap-item-confirm.component.scss']
})
export class WrapItemConfirmComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
    public queueService: QueueService) { }

  ngOnInit() {
  }

}
