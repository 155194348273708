import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {FormGroup} from '@angular/forms';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ListViewColumn} from '../../views/list-view/list-view.model';
import {IndexService} from '../../../_services/index.service';

@Component({
    selector: 'app-index-actions',
    templateUrl: './index-actions.component.html',
    styleUrls: ['./index-actions.component.scss']
})
export class IndexActionsComponent implements OnInit {

    @Input() hasSingleViewType: boolean

    @Input() hasParentViewType: boolean

    @Input() listColumns: Array<ListViewColumn>

    @Input() filterOptions: any

    @Input() searchForm: FormGroup;

    @Output() triggerSearchEvent = new EventEmitter<any>()

    public hasNoFilters = false

    public searchEventSubject: Subject<any> = new Subject();

    public filterTooltipText = 'All applied filters will appear here. To add a filter, click the add filter button below. Once applied, filters can be removed by clicking the cross icon next to the filter.'

    constructor(public indexService: IndexService, private modalService: NgbModal) { }

    ngOnInit() {
        // Debounce the search event to allow a user time to finish typing
        this.searchEventSubject
            .pipe(debounceTime(500))
            .subscribe(() => this.triggerSearchEvent.emit());

        // If all columns have the filterNone setting, disable filter actions
        this.hasNoFilters = this.listColumns.filter(col => col.filterType).length === 0;
    }


    /**
     * Call the index service to add an empty filter to the list
     */
    addEmptyFilter() {
        this.indexService.addEmptyFilter()
    }

    /**
     * Call the index service to remove all filters
     */
    clearAllFilter() {
        this.indexService.clearAllFilter()
    }

    /**
     * Fire off search event on parent index
     */
    emitSearchEvent () {
        this.searchEventSubject.next();
    }

    /**
     * Generate icon class based on current view mode
     */
    getViewModeClass() {
        return this.indexService.viewMode === 'List' ? 'fa fa-list-ul' : 'fa fa-th-large';
    }

    /**
     * Switch between list and grid on the datatable and save the selection to presets
     * @param mode List or Grid string
     */
    updateViewMode (mode: 'List'|'Grid') {
        this.indexService.updateViewMode(mode)
    }

    /**
     * Switch between displaying the child or parent objects
     * Currently only applies to the Order Datatable, with order (child) & multi vendor order (parent)
     * @param mode Child or Parent string
     */
    updateRelativeView (mode: 'Child'|'Parent') {
      this.indexService.switchRelativeView(mode)
    }

    /**
     * Toggle the display of columns on the table
     * @param $event
     * @param col The column being hidden/shown
     */
    updateColumnDisplay ($event, col: ListViewColumn) {
        this.indexService.toggleColumnDisplay({key: col.field, toBeHidden: !$event})
    }

    /**
     * Switch between presets
     * @param presetIndex Index of selected preset from array of AvailableIndexes
     */
    triggerSwitchCurrentPreset (presetIndex: number) {
        this.indexService.switchCurrentPreset(presetIndex)
    }

    /**
     * Return the default 'All Data' preset to it's original state
     */
    triggerResetDefaultPreset() {
        this.indexService.resetDefaultPreset()
    }

    /**
     *  Delete a selected preset
     * @param presetIndex Index of preset to be deleted from the array of AvailableIndexes
     */
    triggerDeletePreset(presetIndex: number) {
        this.indexService.deletePreset(presetIndex)
    }

    /**
     * Create a new preset
     * @param createPresetForm
     */
    createNewPreset(createPresetForm: FormGroup) {
        const {presetName} = createPresetForm.value;
        if (presetName) this.indexService.createPreset(presetName)
    }
}
