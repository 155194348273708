import { AttachmentModel } from './attachment.model';
import {BaseModel} from "./base.model";

export class GalleryModel extends BaseModel {
    public attachments: AttachmentModel[];

    constructor(public id: number = null,
                public name: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        //Attachments
        this.attachments = new Array();
        if(result["gallery_attachments"]){
            for(const json of result["gallery_attachments"]) {
                if(json["attachment"]){
                    const attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }
    }
}