import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ProductionModel } from 'app/_models/production.model';
import { Router } from '@angular/router';

@Injectable() export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private toastr: ToastrService,
        public router: Router) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');

        request = request.clone({ url: environment.url + request.url });
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        //uuid 
        {
            let production = localStorage.getItem('currentProduction');
            if (production) {
                if (JSON.parse(production).uuid) {
                    request = request.clone({ headers: request.headers.set('uuid', JSON.parse(production).uuid) });
                }
            }
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };

                if (error.status === 422) {
                    Object.keys(error.error.errors).forEach((key, value) => {
                        this.toastr.error(error.error.errors[key][0], 'Error');
                    });
                }

                if (error.status === 416) {
                    if (error.error.message) {
                        this.router.navigate(['production/multi-factor']);
                    }
                }

                if (error.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['/auth']);
                }

                if (error.status === 403) {
                    localStorage.removeItem('currentProduction');
                    this.router.navigate(['/production']);
                }

                return throwError(error);
            }));
    }
}