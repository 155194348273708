import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-preset-form',
  templateUrl: './create-preset-form.component.html',
  styleUrls: ['./create-preset-form.component.scss']
})
export class CreatePresetFormComponent {
    presetForm: FormGroup;
    @Output() returnNewPreset = new EventEmitter<FormGroup>();
    @Output() close = new EventEmitter<void>();

    constructor(private formBuilder: FormBuilder) {
      this.generatePresetForm();
    }

    generatePresetForm() {
        this.presetForm = this.formBuilder.group({
            presetName: null,
        });
    }

    public submitForm() {
        this.returnNewPreset.emit(this.presetForm)
        this.presetForm.patchValue({presetName: null})
    }

    public cancel(event) {
        event.preventDefault();
        this.presetForm.patchValue({presetName: null})
        this.close.emit()
    }
}
