var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize } from "rxjs/operators";
var IndexViewPreset = /** @class */ (function () {
    function IndexViewPreset() {
        /**
         * List of columns to be hidden on the index table
         */
        this.hiddenColumns = {};
    }
    return IndexViewPreset;
}());
export { IndexViewPreset };
export var defaultGridIndexPreset = {
    name: 'All Data',
    isDefault: true,
    modified: false,
    viewMode: 'Grid',
    relativeView: 'Child',
    hiddenColumns: {},
    filters: [],
    preDefinedFilters: []
};
export var defaultListIndexPreset = {
    name: 'All Data',
    isDefault: true,
    modified: false,
    viewMode: 'List',
    relativeView: 'Child',
    hiddenColumns: {},
    filters: [],
    preDefinedFilters: []
};
var IndexService = /** @class */ (function () {
    function IndexService(http) {
        this.http = http;
        this.staticPredefinedFilters = [];
        this.hiddenColumns = {};
        this.filters = [];
        this.preDefinedFilters = [];
        this.observableFilters = new BehaviorSubject(this.filters);
        this.observableViewMode = new BehaviorSubject(this.viewMode);
        this.observableRelativeVIew = new BehaviorSubject(this.relativeView);
    }
    /**
     * When an index page first load, populate the preset values
     * @param key String identifying the particular index type
     * @param isListOnly Flag the index page as only having a list layout
     * @param preDefinedFilter
     */
    IndexService.prototype.getInitialPreset = function (key, isListOnly, preDefinedFilter) {
        var _this = this;
        if (isListOnly === void 0) { isListOnly = false; }
        if (preDefinedFilter === void 0) { preDefinedFilter = []; }
        var availablePresets;
        var currentPresetIndex = 0;
        this.http.get('datatable-presets/get/' + key).pipe(finalize(function () {
            if (!availablePresets) {
                availablePresets = [isListOnly ? __assign({}, defaultListIndexPreset) : __assign({}, defaultGridIndexPreset)];
                availablePresets[0].preDefinedFilters = preDefinedFilter;
            }
            _this.availablePresets = availablePresets;
            _this.staticPredefinedFilters = preDefinedFilter;
            currentPresetIndex = localStorage.getItem(key + 'IndexCurrentPreset') || 0;
            if (currentPresetIndex > _this.availablePresets.length - 1)
                currentPresetIndex = 0;
            _this.refreshCurrentPreset(currentPresetIndex);
            _this.key = key;
            _this.defaultViewMode = isListOnly ? 'List' : 'Grid';
        })).subscribe(function (result) { return availablePresets = result; }, function (error) { });
        return of(this.currentPreset);
    };
    IndexService.prototype.createPreset = function (presetName) {
        var newPreset = this.defaultViewMode === 'List' ? __assign({}, defaultListIndexPreset) : __assign({}, defaultGridIndexPreset);
        newPreset.name = presetName;
        newPreset.isDefault = false;
        newPreset.preDefinedFilters = this.staticPredefinedFilters;
        this.availablePresets.push(newPreset);
        this.switchCurrentPreset(this.availablePresets.length - 1);
        this.saveChangesToPresets();
    };
    /**
     * Replace the current preset with another
     * @param presetIndex
     */
    IndexService.prototype.switchCurrentPreset = function (presetIndex) {
        this.refreshCurrentPreset(presetIndex);
        localStorage.setItem(this.key + 'IndexCurrentPreset', String(presetIndex));
    };
    /**
     * Set all values based on current preset
     * @param presetIndex
     */
    IndexService.prototype.refreshCurrentPreset = function (presetIndex) {
        var currentPreset = this.availablePresets[presetIndex];
        this.currentPreset = currentPreset;
        this.currentPresetIndex = presetIndex;
        this.viewMode = currentPreset.viewMode;
        this.relativeView = currentPreset.relativeView;
        this.hiddenColumns = currentPreset.hiddenColumns;
        this.filters = currentPreset.filters;
        this.preDefinedFilters = this.staticPredefinedFilters.slice();
        this.updateObservableFilters();
        this.updateObservableViewMode();
    };
    /**
     * Switch between list and grid on the datatable and save the selection to presets
     * @param mode List or Grid string
     */
    IndexService.prototype.updateViewMode = function (mode) {
        this.currentPreset.viewMode = mode;
        this.viewMode = mode;
        this.updateObservableViewMode();
        this.saveChangesToPresets();
    };
    /**
     * Switch between displaying the child or parent objects
     * @param mode Parent or Child string
     */
    IndexService.prototype.switchRelativeView = function (mode) {
        this.currentPreset.relativeView = mode;
        this.relativeView = mode;
        this.updateObservableRelativeView();
        this.saveChangesToPresets();
    };
    /**
     * Toggle the display of columns on the table
     * @param result Object with column key and display state
     */
    IndexService.prototype.toggleColumnDisplay = function (result) {
        var _a;
        var hiddenColumns = this.currentPreset.hiddenColumns || {};
        var newHiddenColumn = (_a = {}, _a[result.key] = result.toBeHidden, _a);
        this.currentPreset.hiddenColumns = __assign({}, hiddenColumns, newHiddenColumn);
        this.hiddenColumns = this.currentPreset.hiddenColumns;
        this.saveChangesToPresets();
    };
    /**
     * Add an empty filter to the list
     * colField Include the column field if setting the filter from a specific column
     */
    IndexService.prototype.addEmptyFilter = function (colField) {
        if (!this.filters)
            this.filters = [];
        this.filters.push({ field: colField ? colField : null, filter: null });
    };
    /**
     * Populate a filter entry when a filter value has been set
     * @param result
     */
    IndexService.prototype.populateFilter = function (result) {
        this.filters[result.index] = { field: result.field, filter: result.value };
        this.saveChangesToPresets();
        this.updateObservableFilters();
    };
    /**
     * Update a predefined filter entry when a filter value has been updated
     * @param result
     */
    IndexService.prototype.populatePreDefinedFilter = function (result) {
        this.preDefinedFilters[result.index] = { field: result.field, filter: result.value };
        this.saveChangesToPresets();
        this.updateObservableFilters();
    };
    /**
     * Remove a specific filter
     * @param filterIndex
     */
    IndexService.prototype.removeFilter = function (filterIndex) {
        var isNotEmptyFilter = !!this.filters[filterIndex].field;
        this.filters.splice(filterIndex);
        if (isNotEmptyFilter) {
            this.saveChangesToPresets();
            this.updateObservableFilters();
        }
    };
    /**
     * Remove all filters
     */
    IndexService.prototype.clearAllFilter = function () {
        this.filters = [];
        this.currentPreset.filters = [];
        this.saveChangesToPresets();
        this.updateObservableFilters();
    };
    /**
     * After filters have been updated, modify the subscribed object that the domain index will be listening to
     */
    IndexService.prototype.updateObservableFilters = function () {
        var combinedFilters = this.filters.concat(this.preDefinedFilters);
        this.observableFilters.next(combinedFilters);
    };
    IndexService.prototype.updateObservableViewMode = function () {
        this.observableViewMode.next(this.viewMode);
    };
    IndexService.prototype.updateObservableRelativeView = function () {
        this.observableRelativeVIew.next(this.relativeView);
    };
    /**
     * On modifying a preset (ex. switching view mode), save the preset to local storage / API
     */
    IndexService.prototype.saveChangesToPresets = function () {
        if (this.currentPreset.isDefault)
            this.currentPreset.modified = true;
        this.http.post('datatable-presets', {
            type: this.key,
            datatable_presets: this.availablePresets
        }).subscribe(function () { }, function (error) {
        });
    };
    /**
     * Return the default 'All Data' preset to it's original state
     */
    IndexService.prototype.resetDefaultPreset = function () {
        this.availablePresets[0] = this.defaultViewMode === 'List' ? __assign({}, defaultListIndexPreset) : __assign({}, defaultGridIndexPreset);
        this.refreshCurrentPreset(0);
        this.http.post('datatable-presets', {
            type: this.key,
            datatable_presets: this.availablePresets
        }).subscribe(function () { }, function (error) { });
        var presetAsString = JSON.stringify(this.availablePresets);
        localStorage.setItem(this.key + 'IndexPresets', presetAsString);
    };
    /**
     * Delete a selected preset
     * @param presetIndex Index of preset to be deleted from the array of AvailableIndexes
     */
    IndexService.prototype.deletePreset = function (presetIndex) {
        var presetToDelete = this.availablePresets[presetIndex];
        // Switch preset if deleting the current view
        if (presetToDelete === this.currentPreset) {
            this.switchCurrentPreset(0);
        }
        // Remove the preset from the array
        this.availablePresets.splice(presetIndex, 1);
        // Save the array with the remaining presets to local storage
        var presetAsString = JSON.stringify(this.availablePresets);
        localStorage.setItem(this.key + 'IndexPresets', presetAsString);
        // Save the presets to the DB
        this.saveChangesToPresets();
    };
    return IndexService;
}());
export { IndexService };
