var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
var NoteModel = /** @class */ (function (_super) {
    __extends(NoteModel, _super);
    function NoteModel(id, type_id, note, type, created_at_display, updated_at, role_id) {
        if (id === void 0) { id = null; }
        if (type_id === void 0) { type_id = null; }
        if (note === void 0) { note = null; }
        if (type === void 0) { type = null; }
        if (created_at_display === void 0) { created_at_display = null; }
        if (updated_at === void 0) { updated_at = null; }
        if (role_id === void 0) { role_id = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.type_id = type_id;
        _this.note = note;
        _this.type = type;
        _this.created_at_display = created_at_display;
        _this.updated_at = updated_at;
        _this.role_id = role_id;
        return _this;
    }
    return NoteModel;
}(BaseModel));
export { NoteModel };
