import { RoleModel } from './role.model';
import { BaseModel } from './base.model';
import { NoteModel } from './note.model';
import { TagModel } from './tag.model';
import { CharacterModel } from './character.model';
import { SceneModel } from './scene.model';
import { SetModel } from './set.model';
import { AttachmentModel } from './attachment.model';

export enum InventoryStatus {
  Active = 'Active',
  Wrapped = 'Wrapped'
}

// ToDo: Standardise the object attributes required for the different drop downs and selects
export const inventoryStatusOptions = [
  {value: InventoryStatus.Active, name: InventoryStatus.Active, id: InventoryStatus.Active, text: InventoryStatus.Active},
  {value: InventoryStatus.Wrapped, name: InventoryStatus.Wrapped, id: InventoryStatus.Wrapped, text: InventoryStatus.Wrapped},
]


export class ItemModel extends BaseModel {
    public notes: NoteModel[];
    public tags: TagModel[];
    public attachments: AttachmentModel[];
    public characters: CharacterModel[];
    public scenes: SceneModel[];
    public sets: SetModel[];
    public role: RoleModel;

    constructor(public id: number = null,
        public created_at: string = null,
        public name: string = null,
        public size: string = null,
        public location: string = null,
        public transaction_number: string = null,
        public doc_number: string = null,
        public unit_cost_value: number = null,
        public is_established: boolean = false,
        public asset_number: string = null,
        public studio_ref: string = null,
        public type: string = null,
        public unit_cost_currency: string = null,
        public asset_type: string = null,
        public stock_number: string = null,
        public quantity: number = null,
        public replacement_value: number = null,
        public replacement_value_currency: string = null,
        public status: string = null,
        public pic: string = null,
        public thumbnail: string = null,
        public max_check_in: number = null,
        public checked_in: number = null,
        public max_return: number = null,
        public order_item_status: string = null,
        public wrapped_quantity: number = null,
        public max_wrapped_quantity: number = null,
        public max_wrapper_quantity: number = null,
        public inventory_status: InventoryStatus = null,
        public inventory_status_quantity: number = null,
        public account_code: string = null,
        public additional_account_code: string = null,
        public vendor: string = null,
        public vendor_reference: string = null,
        public note: string = null,
        public order_method: string = null,
        public item_type: string = null,
        public line_number: number = null,
        public order_by: string = null,
        public order_id: number = null,
        public returned: number = null,
        public combine_account_code: string = null,
        public is_print_generated: boolean = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        if (result['notes']) {
            this.notes = new Array();
            for (const note of result['notes']) {
                const record = new NoteModel();
                record.makeObjectFromJson(note);
                this.notes.push(record)
            }
        }

        if (result['department']) {
            this.role = new RoleModel();
            this.role.makeObjectFromJson(result['department']);
        }

        if (result['tags']) {
            this.tags = new Array();
            for (const tag of result['tags']) {
                const record = new TagModel();
                record.makeObjectFromJson(tag);
                this.tags.push(record)
            }
        }

        if (result['item_attachments']) {
            this.attachments = new Array();
            for (const itemAttachment of result['item_attachments']) {
                if (itemAttachment['attachment']) {
                    const attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(itemAttachment['attachment']);
                    this.attachments.push(attachment);
                }
            }
        }

        if (result['characters']) {
            this.characters = new Array();
            for (const character of result['characters']) {
                const record = new CharacterModel();
                record.makeObjectFromJson(character);
                this.characters.push(record)
            }
        }
        if (result['scenes']) {
            this.scenes = new Array();
            for (const scene of result['scenes']) {
                const record = new SceneModel();
                record.makeObjectFromJson(scene);
                this.scenes.push(record);
            }
        }
        if (result['sets']) {
            this.scenes = new Array();
            for (const set of result['sets']) {
                const record = new SetModel();
                record.makeObjectFromJson(set);
                this.sets.push(record);
            }
        }

    }

    getStatus() {
        if (this.order_item_status) {
            return this.order_item_status;
        } else {
            return 'AwaitingDelivery';
        }
    }
}
