import { OnInit, EventEmitter } from '@angular/core';
var CommonCounterComponent = /** @class */ (function () {
    function CommonCounterComponent() {
        this._control = null;
        this._quantity = null;
        this._maxQuantity = null;
        this._minQuantity = 0;
        this.quantityUpdated = new EventEmitter();
    }
    Object.defineProperty(CommonCounterComponent.prototype, "control", {
        get: function () {
            return this._control;
        },
        set: function (control) {
            this._control = control;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonCounterComponent.prototype, "quantity", {
        get: function () {
            return this._quantity;
        },
        set: function (value) {
            this._quantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonCounterComponent.prototype, "maxQuantity", {
        get: function () {
            return this._maxQuantity;
        },
        set: function (value) {
            this._maxQuantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonCounterComponent.prototype, "minQuantity", {
        get: function () {
            return this._minQuantity;
        },
        set: function (value) {
            this._minQuantity = value;
        },
        enumerable: true,
        configurable: true
    });
    CommonCounterComponent.prototype.ngOnInit = function () {
    };
    CommonCounterComponent.prototype.increase = function () {
        if (!this.maxQuantity || (this.control.value + 1) <= this.maxQuantity) {
            if (this.control)
                this.control.setValue(this.control.value + 1);
            if (this.quantity)
                this.quantity += 1;
            this.quantityUpdated.emit(this.quantity);
        }
    };
    CommonCounterComponent.prototype.decrease = function () {
        if ((this.control.value - 1) < this.minQuantity)
            return;
        if (this.control)
            this.control.setValue(this.control.value - 1);
        if (this.quantity)
            this.quantity -= 1;
        this.quantityUpdated.emit(this.quantity);
    };
    return CommonCounterComponent;
}());
export { CommonCounterComponent };
