import { UserModel } from 'app/_models/user.model';
import { BaseModel } from "./base.model";

export class NoteModel extends BaseModel {
    public user: UserModel;
    constructor(public id: number = null,
        public type_id: number = null,
        public note: string = null,
        public type: string = null,
        public created_at_display: string = null,
        public updated_at: string = null,
        public role_id: number = null) {
        super();
    }
}