import { ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from "@angular/core";
import * as i0 from "@angular/core";
var DomService = /** @class */ (function () {
    function DomService(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
        this.hideNavbar = false;
    }
    DomService.prototype.appendComponentToBody = function (component) {
        // Create a component reference from the component
        var componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);
        this.componentRef = componentRef;
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);
        // Get DOM element from component
        var domElem = componentRef.hostView
            .rootNodes[0];
        // Append DOM element to the body
        document.body.appendChild(domElem);
        return componentRef;
        // Wait some time and remove it from the component tree and from the DOM
        // setTimeout(() => {
        //   this.appRef.detachView(componentRef.hostView);
        //   componentRef.destroy();
        // }, 3000);
    };
    DomService.prototype.clear = function () {
        var _this = this;
        setTimeout(function () {
            _this.appRef.detachView(_this.componentRef.hostView);
            _this.componentRef.destroy();
        }, 300);
    };
    DomService.ngInjectableDef = i0.defineInjectable({ factory: function DomService_Factory() { return new DomService(i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.ApplicationRef), i0.inject(i0.INJECTOR)); }, token: DomService, providedIn: "root" });
    return DomService;
}());
export { DomService };
