var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from "./base.model";
import { StripboardColorModel } from "./stripboard-color.model";
var StripboardModel = /** @class */ (function (_super) {
    __extends(StripboardModel, _super);
    function StripboardModel(id, name, is_episode_script, script_name, episode_number, updated_at, display_name, display_text, profile_text, last_updated) {
        if (id === void 0) { id = null; }
        if (name === void 0) { name = null; }
        if (is_episode_script === void 0) { is_episode_script = null; }
        if (script_name === void 0) { script_name = null; }
        if (episode_number === void 0) { episode_number = null; }
        if (updated_at === void 0) { updated_at = null; }
        if (display_name === void 0) { display_name = null; }
        if (display_text === void 0) { display_text = null; }
        if (profile_text === void 0) { profile_text = null; }
        if (last_updated === void 0) { last_updated = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.name = name;
        _this.is_episode_script = is_episode_script;
        _this.script_name = script_name;
        _this.episode_number = episode_number;
        _this.updated_at = updated_at;
        _this.display_name = display_name;
        _this.display_text = display_text;
        _this.profile_text = profile_text;
        _this.last_updated = last_updated;
        return _this;
    }
    StripboardModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Stripboard colors
        this.stripboard_colors = new Array();
        if (result["stripboard_colors"]) {
            for (var _i = 0, _a = result["stripboard_colors"]; _i < _a.length; _i++) {
                var json = _a[_i];
                var record = new StripboardColorModel();
                record.makeObjectFromJson(json);
                this.stripboard_colors.push(record);
            }
        }
        //Recent stripboard color
        if (result["recent_stripboard_color"]) {
            this.recent_stripboard_color = new StripboardColorModel();
            this.recent_stripboard_color.makeObjectFromJson(result["recent_stripboard_color"]);
        }
    };
    StripboardModel.prototype.getRecentStripboardColorId = function () {
        if (this.recent_stripboard_color) {
            return this.recent_stripboard_color.id;
        }
        return null;
    };
    return StripboardModel;
}(BaseModel));
export { StripboardModel };
