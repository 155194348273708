import { UserModel } from './user.model';
import { BaseModel } from "./base.model";

export class AttachmentModel extends BaseModel {
    public user: UserModel;

    constructor(public id: number = null,
        public file_path: string = null,
        public file_name: string = null,
        public created_by: string = null,
        public created_at: string = null,
        public is_selected: boolean = false,
        public type: string = null,
        public thumbnail: string = null,
        public download_path: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        //User
        if (result["user"]) {
            this.user = new UserModel();
            this.user.makeObjectFromJson(result["user"]);
        }
    }
}