import { EventEmitter } from '@angular/core';
import { CharacterService } from './../../../_services/character.service';
import { QueueService } from '../../../_services/queue.service';
import { FormBuilder, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';
var AssignItemCharacterComponent = /** @class */ (function () {
    function AssignItemCharacterComponent(activeModal, fb, queueService, characterService, toastr) {
        this.activeModal = activeModal;
        this.fb = fb;
        this.queueService = queueService;
        this.characterService = characterService;
        this.toastr = toastr;
        this.recordAdded = new EventEmitter();
    }
    AssignItemCharacterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            character: [null, [Validators.required]],
            scenes: [null, [Validators.required]],
            items: this.fb.array([])
        });
        this.characterService.getAllRecords()
            .subscribe(function (result) {
            _this.characters = result;
        });
    };
    AssignItemCharacterComponent.prototype.characterSelected = function () {
        var _this = this;
        this.scenes = new Array();
        if (this.form.value.character) {
            this.characterService.getScenes(this.form.value.character)
                .subscribe(function (result) {
                _this.scenes = result;
            });
        }
    };
    AssignItemCharacterComponent.prototype.onSubmit = function () {
        var _this = this;
        this.formSubmitLoader = true;
        var form = this.form.value;
        form.items = this.queueService.items.slice();
        this.characterService.assignHeroProps(this.form.value.character, form)
            .subscribe(function (result) {
            var character = _this.characters.find(function (item) { return item.id == _this.form.value.character; });
            var scene = "";
            var _loop_1 = function (sceneId) {
                var _scene = _this.scenes.find(function (item) { return item.id == sceneId; });
                scene = scene + _scene.display_text + " ";
            };
            for (var _i = 0, _a = _this.form.value.scenes; _i < _a.length; _i++) {
                var sceneId = _a[_i];
                _loop_1(sceneId);
            }
            _this.toastr.success("Success", "Items have been assigned to Character [" + character.display_text + "/" + scene + "]");
            _this.recordAdded.emit();
            _this.activeModal.close();
        }, function (error) {
            _this.formSubmitLoader = false;
        });
    };
    return AssignItemCharacterComponent;
}());
export { AssignItemCharacterComponent };
