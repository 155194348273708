import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public permissions: string[];

  constructor(public ngxPermissionsService: NgxPermissionsService,
    public ngxRolesService: NgxRolesService,
    public http: HttpClient) {
    // this.permissions = [
    //   "Stripboard",
    //   "Inventory",
    //   "Order",
    //   "Set",
    //   "Character",
    //   "ManageUser",
    //   "ProductionAdmin"
    // ];
    // this.ngxPermissionsService.loadPermissions(this.permissions);
  }

  getPermission() {
    return this.http.get('permission')
      .map(
        (response: any) => {   
          this.permissions = [...<any>response.permissions];   
          this.ngxPermissionsService.flushPermissions();
          this.ngxPermissionsService.loadPermissions(this.permissions);  
          return response;
        }
      );
  }
}
