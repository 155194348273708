var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HeroPropModel } from './hero-prop.model';
import { CharacterChangeModel } from './character-change.model';
import { MeasurementModel } from './measurement.model';
import { BaseModel } from "./base.model";
var CharacterModel = /** @class */ (function (_super) {
    __extends(CharacterModel, _super);
    function CharacterModel(id, production_id, character_name, character_number, type, actor_name, measurement_type, pic, display_text, scenes_display_text, story_days) {
        if (id === void 0) { id = null; }
        if (production_id === void 0) { production_id = null; }
        if (character_name === void 0) { character_name = null; }
        if (character_number === void 0) { character_number = null; }
        if (type === void 0) { type = null; }
        if (actor_name === void 0) { actor_name = null; }
        if (measurement_type === void 0) { measurement_type = null; }
        if (pic === void 0) { pic = null; }
        if (display_text === void 0) { display_text = null; }
        if (scenes_display_text === void 0) { scenes_display_text = null; }
        if (story_days === void 0) { story_days = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.production_id = production_id;
        _this.character_name = character_name;
        _this.character_number = character_number;
        _this.type = type;
        _this.actor_name = actor_name;
        _this.measurement_type = measurement_type;
        _this.pic = pic;
        _this.display_text = display_text;
        _this.scenes_display_text = scenes_display_text;
        _this.story_days = story_days;
        return _this;
    }
    CharacterModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Measurements
        if (result["measurements"]) {
            this.measurements = new Array();
            for (var _i = 0, _a = result["measurements"]; _i < _a.length; _i++) {
                var json = _a[_i];
                var measurement = new MeasurementModel();
                measurement.makeObjectFromJson(json);
                this.measurements.push(measurement);
            }
        }
        //Change
        this.changes = new Array();
        if (result["changes"]) {
            for (var _b = 0, _c = result["changes"]; _b < _c.length; _b++) {
                var json = _c[_b];
                var change = new CharacterChangeModel();
                change.makeObjectFromJson(json);
                this.changes.push(change);
            }
        }
        //Hero props
        this.hero_pros = new Array();
        if (result["hero_props"]) {
            for (var _d = 0, _e = result["hero_props"]; _d < _e.length; _d++) {
                var json = _e[_d];
                if (json["item"]) {
                    var item = new HeroPropModel();
                    item.makeObjectFromJson(json);
                    this.hero_pros.push(item);
                }
            }
        }
    };
    return CharacterModel;
}(BaseModel));
export { CharacterModel };
