import { AttachmentModel } from 'app/_models/attachment.model';
import { GalleryModel } from './gallery.model';
import { BaseModel } from "./base.model";
import { SetSceneModel } from './set-scene.model';

export enum SetTypes {
  Exterior = 'Exterior',
  Interior = 'Interior'
}

// ToDo: Standardise the object attributes required for the different drop downs and selects
export const setTypeOptions = [
  {value: SetTypes.Exterior, name: SetTypes.Exterior, id: SetTypes.Exterior, text: SetTypes.Exterior},
  {value: SetTypes.Interior, name: SetTypes.Interior, id: SetTypes.Interior, text: SetTypes.Interior},
]


export class SetModel extends BaseModel {
    public galleries: GalleryModel[];
    public set_scenes: SetSceneModel[];
    public attachments: AttachmentModel[];

    constructor(public id: number = null,
        public name: string = null,
        public location: string = null,
        public type: SetTypes = null,
        public display_text: string = null,
        public last_updated: string = null,
        public pic: string = null,
        public story_days: string = null,
        public scene_numbers: string = null) {
        super();
    }

    makeObjectFromJson(result: any) {
        super.makeObjectFromJson(result);

        //Gallery
        if (result["set_galleries"]) {
            this.galleries = new Array();
            for (const json of result["set_galleries"]) {
                if (json["gallery"]) {
                    const gallery = new GalleryModel();
                    gallery.makeObjectFromJson(json["gallery"]);
                    this.galleries.push(gallery);
                }
            }
        }

        //Set scenes
        this.set_scenes = new Array();
        if (result["set_scenes"]) {
            for (const json of result["set_scenes"]) {
                const setScene = new SetSceneModel();
                setScene.makeObjectFromJson(json);
                this.set_scenes.push(setScene);
            }
        }

        // Attachments
        this.attachments = new Array();
        if (result["set_attachments"]) {
            for (const json of result["set_attachments"]) {
                if (json["attachment"]) {
                    const attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json["attachment"]);
                    this.attachments.push(attachment);
                }
            }
        }
    }
}
