// ng build -c=staging
export const environment = {
  production: false,
  debug: false,
  defaultServerError: 'Server issue',
  url: 'https://api.devboxset.com/api/',
  defaultValidationMessage: 'Something wrong, please check.',
  defaultPage: '/production',
  defaultStringLength: 255,
  defaultDatatableLimit: 10000,
  defaultFlashMessageTimeout: 500000,
  attachmentUploadUrl: "https://api.devboxset.com/api/" + "attachment",
  permissionPage: '/no-permission'
};
