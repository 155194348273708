import { AuthService } from './auth.service';
import { ProductionModel } from "../_models/production.model";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
var ProductionService = /** @class */ (function () {
    function ProductionService(http, authService, router, route) {
        this.http = http;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.currentProductionSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentProduction')));
        this.currentProduction = this.currentProductionSubject.asObservable();
    }
    Object.defineProperty(ProductionService.prototype, "currentProductionValue", {
        get: function () {
            return this.currentProductionSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductionService.prototype, "production", {
        get: function () {
            return this.currentProductionSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    ProductionService.prototype.select = function (production) {
        var _this = this;
        localStorage.setItem("production", "" + production.id);
        this.get(production.uuid)
            .subscribe(function (result) {
            var production = new ProductionModel();
            production.makeObjectFromJson(result);
            _this.currentProductionSubject.next(result);
        });
    };
    ProductionService.prototype.get = function (uuid) {
        return this.http.get("production/" + uuid)
            .map(function (response) {
            var record = new ProductionModel();
            record.makeObjectFromJson(response);
            return record;
        });
    };
    ProductionService.prototype.store = function (form) {
        var _this = this;
        return this.http.post("production", { production: form })
            .map(function (response) {
            localStorage.setItem('currentProduction', JSON.stringify(response));
            var production = new ProductionModel();
            production.makeObjectFromJson(response);
            _this.currentProductionSubject.next(response);
            if (production.is_multi_factor_required) {
                if (_this.authService.currentUserValue.google2fa_enable !== true) {
                    _this.router.navigate(['multi-factor'], { relativeTo: _this.route.parent });
                }
                else {
                    _this.router.navigate(['stripboard']);
                }
            }
            else {
                _this.router.navigate(['stripboard']);
            }
            return production;
        });
    };
    ProductionService.prototype.update = function (form) {
        return this.http.post("production/" + this.currentProductionValue.uuid, form);
    };
    ProductionService.prototype.delete = function (id) {
        return this.http.post("production/" + id, { _method: "DELETE" });
    };
    ProductionService.prototype.getAllRecords = function () {
        var _this = this;
        this.loadingMessages = ['Loading Account Details.'];
        this.loading = true;
        return this.http.get("production")
            .map(function (response) {
            _this.loading = false;
            return response;
        });
    };
    ProductionService.prototype.getRecordsForSelect2Items = function (records) {
        var productions = new Array();
        for (var _i = 0, records_1 = records; _i < records_1.length; _i++) {
            var record = records_1[_i];
            productions.push({
                id: record.id,
                text: record.name
            });
        }
        return productions;
    };
    ProductionService.prototype.getCountries = function () {
        return ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua &amp; Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas",
            "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia &amp; Herzegovina", "Botswana", "Brazil", "British Virgin Islands",
            "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica",
            "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea",
            "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
            "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India",
            "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia",
            "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania",
            "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia",
            "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
            "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre &amp; Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
            "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea", "Spain", "Sri Lanka", "St Kitts &amp; Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan",
            "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad &amp; Tobago", "Tunisia",
            "Turkey", "Turkmenistan", "Turks &amp; Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay",
            "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"];
    };
    ProductionService.prototype.getDefaultRoles = function () {
    };
    ProductionService.prototype.increaseCount = function (parameter) {
        var production = localStorage.getItem('currentProduction');
        if (production) {
            var productionObject = JSON.parse(production);
            productionObject[parameter + '_count'] += productionObject[parameter + '_count'];
            localStorage.setItem('currentProduction', JSON.stringify(productionObject));
        }
    };
    ProductionService.prototype.leaveProduction = function () {
        return this.http.get('production-leave')
            .map(function (response) {
            localStorage.removeItem('currentProduction');
        });
    };
    ProductionService.prototype.deleteProduction = function () {
        return this.http.get('production/' + this.currentProductionValue.uuid)
            .map(function (response) {
            localStorage.removeItem('currentProduction');
        });
    };
    ProductionService.prototype.addCurrency = function (currencyCode) {
        return this.http.post('production/custom/currency', {
            currencies: [
                { currency_code: currencyCode }
            ]
        });
    };
    ProductionService.prototype.updateCurrency = function (form) {
        return this.http.post('production/custom/currency', form);
    };
    ProductionService.prototype.addAssetType = function (form) {
        return this.http.post('production/custom/asset-type', form);
    };
    ProductionService.prototype.deleteCurrency = function (currency) {
        return this.http.post('production/custom/currency/' + currency, {
            _method: "DELETE"
        });
    };
    ProductionService.prototype.deleteAssetType = function (id) {
        return this.http.post('production/custom/asset-type/' + id, { _method: "DELETE" });
    };
    ProductionService.prototype.getCurrencies = function () {
        return this.http.get('production/custom/currency')
            .map(function (response) {
            return response;
        });
    };
    ProductionService.prototype.getAssets = function () {
        return this.http.get('production/custom/asset-type')
            .map(function (response) {
            return response;
        });
    };
    ProductionService.prototype.getStorageLocations = function () {
        return this.http.get('production/custom/storage-location')
            .map(function (response) {
            return response;
        });
    };
    ProductionService.prototype.getMultiFactor = function () {
        return this.http.get('production/custom/multi-factor');
    };
    ProductionService.prototype.updateMultiFactor = function (form) {
        return this.http.post('production/custom/multi-factor', {
            is_multi_factor_required: form
        });
    };
    ProductionService.prototype.closeProduction = function () {
        return this.http.post('production/custom/closed', {
            is_closed: true
        });
    };
    return ProductionService;
}());
export { ProductionService };
