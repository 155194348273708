import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {finalize} from "rxjs/operators";

export class IndexViewPreset {
  /**
   * Key & Display name of preset
   */
  name: string
  /**
   * Flag the preset as being the default for the index
   */
  isDefault?: boolean
  /**
   * Flag the preset as being modified (Only checked when isDefault is true)
   */
  modified?: boolean
  /**
   * Flag the current view mode
   */
  viewMode: 'List'|'Grid'
  /**
   * Flag whether viewing parent of child objects
   */
  relativeView: 'Parent'|'Child'
  /**
   * List of columns to be hidden on the index table
   */
  hiddenColumns: Record<string, boolean> = {}
  /**
   * Array of customisable filters
   */
  filters: Array<{field: string|null, filter: any }>
  /**
   * Array of predefined filters for the index domain
   */
  preDefinedFilters?: Array<{field: string|null, filter: any }>
}

export const defaultGridIndexPreset: IndexViewPreset = {
    name: 'All Data',
    isDefault: true,
    modified: false,
    viewMode: 'Grid',
    relativeView: 'Child',
    hiddenColumns: {},
    filters: [],
    preDefinedFilters: []
}

export const defaultListIndexPreset: IndexViewPreset = {
    name: 'All Data',
    isDefault: true,
    modified: false,
    viewMode: 'List',
    relativeView: 'Child',
    hiddenColumns: {},
    filters: [],
    preDefinedFilters: []
}

@Injectable()
export class IndexService {
    key: string
    defaultViewMode: 'Grid'|'List'
    defaultRelativeView: 'Parent'|'Child'
    staticPredefinedFilters: Array<{field: string|null, filter: any }> = []

    availablePresets: Array<IndexViewPreset>
    currentPreset: IndexViewPreset
    currentPresetIndex: number
    viewMode: 'Grid'|'List'
    relativeView: 'Parent'|'Child'
    hiddenColumns: {[key: string]: boolean} = {}
    filters: Array<{field: string|null, filter: any }> = []
    preDefinedFilters: Array<{field: string|null, filter: any }> = []

    observableFilters = new BehaviorSubject<Array<{field: string|null, filter: any }>>(this.filters);
    observableViewMode = new BehaviorSubject<'Grid'|'List'>(this.viewMode);
    observableRelativeVIew = new BehaviorSubject<'Parent'|'Child'>(this.relativeView);

    constructor(public http: HttpClient) { }

    /**
     * When an index page first load, populate the preset values
     * @param key String identifying the particular index type
     * @param isListOnly Flag the index page as only having a list layout
     * @param preDefinedFilter
     */
    getInitialPreset(key: string, isListOnly: boolean = false, preDefinedFilter: Array<{field: string|null, filter: any }> = []): Observable<IndexViewPreset> {
        let availablePresets
        let currentPresetIndex: string | number = 0

        this.http.get('datatable-presets/get/' + key).pipe(
            finalize(() => {
                if (!availablePresets) {
                    availablePresets = [isListOnly ? {...defaultListIndexPreset} : {...defaultGridIndexPreset}]
                    availablePresets[0].preDefinedFilters = preDefinedFilter
                }
                this.availablePresets = availablePresets
                this.staticPredefinedFilters = preDefinedFilter

                currentPresetIndex = localStorage.getItem(key + 'IndexCurrentPreset') || 0
                if (currentPresetIndex > this.availablePresets.length - 1) currentPresetIndex = 0

                this.refreshCurrentPreset(currentPresetIndex)
                this.key = key
                this.defaultViewMode = isListOnly ? 'List' : 'Grid'
            })
        ).subscribe(
            result => availablePresets = result,
            error => {}
        )

        return of(this.currentPreset)
    }

    createPreset(presetName: string) {
      const newPreset = this.defaultViewMode === 'List' ? {...defaultListIndexPreset} : {...defaultGridIndexPreset}
      newPreset.name = presetName
      newPreset.isDefault = false
      newPreset.preDefinedFilters = this.staticPredefinedFilters
      this.availablePresets.push(newPreset)
      this.switchCurrentPreset(this.availablePresets.length - 1)
      this.saveChangesToPresets()
    }

    /**
     * Replace the current preset with another
     * @param presetIndex
     */
    switchCurrentPreset(presetIndex: number) {
        this.refreshCurrentPreset(presetIndex)
        localStorage.setItem(this.key + 'IndexCurrentPreset', String(presetIndex));
    }

    /**
     * Set all values based on current preset
     * @param presetIndex
     */
    refreshCurrentPreset(presetIndex) {
        const currentPreset = this.availablePresets[presetIndex]
        this.currentPreset = currentPreset
        this.currentPresetIndex = presetIndex
        this.viewMode = currentPreset.viewMode
        this.relativeView = currentPreset.relativeView
        this.hiddenColumns = currentPreset.hiddenColumns
        this.filters = currentPreset.filters
        this.preDefinedFilters = [...this.staticPredefinedFilters]

        this.updateObservableFilters()
        this.updateObservableViewMode()
    }

    /**
     * Switch between list and grid on the datatable and save the selection to presets
     * @param mode List or Grid string
     */
    updateViewMode (mode: 'List'|'Grid') {
        this.currentPreset.viewMode = mode
        this.viewMode = mode
        this.updateObservableViewMode()
        this.saveChangesToPresets()
    }

    /**
     * Switch between displaying the child or parent objects
     * @param mode Parent or Child string
     */
    switchRelativeView (mode: 'Parent'|'Child') {
        this.currentPreset.relativeView = mode
        this.relativeView = mode
        this.updateObservableRelativeView()
        this.saveChangesToPresets()
    }

    /**
     * Toggle the display of columns on the table
     * @param result Object with column key and display state
     */
    toggleColumnDisplay (result: {key: string, toBeHidden: boolean}) {
        const hiddenColumns = this.currentPreset.hiddenColumns || {}
        const newHiddenColumn = {[result.key]: result.toBeHidden}
        this.currentPreset.hiddenColumns = {...hiddenColumns, ...newHiddenColumn}
        this.hiddenColumns = this.currentPreset.hiddenColumns
        this.saveChangesToPresets();
    }

    /**
     * Add an empty filter to the list
     * colField Include the column field if setting the filter from a specific column
     */
    addEmptyFilter(colField?: string) {
        if (!this.filters) this.filters = []
        this.filters.push({field: colField ? colField : null, filter: null})
    }

    /**
     * Populate a filter entry when a filter value has been set
     * @param result
     */
    populateFilter(result: {index: number, field: string, value: any}) {
        this.filters[result.index] = {field: result.field, filter: result.value}
        this.saveChangesToPresets()
        this.updateObservableFilters()
    }

    /**
     * Update a predefined filter entry when a filter value has been updated
     * @param result
     */
    populatePreDefinedFilter(result: {index: number, field: string, value: any}) {
      this.preDefinedFilters[result.index] = {field: result.field, filter: result.value}
      this.saveChangesToPresets()
      this.updateObservableFilters()
    }

    /**
     * Remove a specific filter
     * @param filterIndex
     */
    removeFilter(filterIndex: number) {
        const isNotEmptyFilter = !!this.filters[filterIndex].field
        this.filters.splice(filterIndex)
        if (isNotEmptyFilter) {
            this.saveChangesToPresets()
            this.updateObservableFilters()
        }
    }

    /**
     * Remove all filters
     */
    clearAllFilter() {
      this.filters = []
      this.currentPreset.filters = []
      this.saveChangesToPresets()
      this.updateObservableFilters()
    }

    /**
     * After filters have been updated, modify the subscribed object that the domain index will be listening to
     */
    updateObservableFilters() {
      const combinedFilters = [...this.filters, ...this.preDefinedFilters]
      this.observableFilters.next(combinedFilters)
    }

    updateObservableViewMode() {
      this.observableViewMode.next(this.viewMode)
    }

    updateObservableRelativeView() {
      this.observableRelativeVIew.next(this.relativeView)
    }

    /**
     * On modifying a preset (ex. switching view mode), save the preset to local storage / API
     */
    saveChangesToPresets () {
        if (this.currentPreset.isDefault) this.currentPreset.modified = true

        this.http.post('datatable-presets', {
          type: this.key,
          datatable_presets: this.availablePresets
        }).subscribe(
          () => {},
          error => {
        })
    }

    /**
     * Return the default 'All Data' preset to it's original state
     */
    resetDefaultPreset() {
        this.availablePresets[0] = this.defaultViewMode === 'List' ? {...defaultListIndexPreset} : {...defaultGridIndexPreset}

        this.refreshCurrentPreset(0)

        this.http.post('datatable-presets', {
            type: this.key,
            datatable_presets: this.availablePresets
        }).subscribe(
            () => {},
            error => {})

        const presetAsString = JSON.stringify(this.availablePresets)
        localStorage.setItem(this.key + 'IndexPresets', presetAsString);
    }

    /**
     * Delete a selected preset
     * @param presetIndex Index of preset to be deleted from the array of AvailableIndexes
     */
    deletePreset(presetIndex: number) {
        const presetToDelete = this.availablePresets[presetIndex]
        // Switch preset if deleting the current view
        if (presetToDelete === this.currentPreset) {
            this.switchCurrentPreset(0);
        }
        // Remove the preset from the array
        this.availablePresets.splice(presetIndex, 1)
        // Save the array with the remaining presets to local storage
        const presetAsString = JSON.stringify(this.availablePresets)
        localStorage.setItem(this.key + 'IndexPresets', presetAsString);
        // Save the presets to the DB
        this.saveChangesToPresets();
    }

}
