/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-csv.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../file-upload/file-upload.component.ngfactory";
import * as i5 from "../../file-upload/file-upload.component";
import * as i6 from "./upload-csv.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../_services/user-setting.service";
import * as i9 from "@angular/common/http";
var styles_UploadCsvComponent = [i0.styles];
var RenderType_UploadCsvComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadCsvComponent, data: {} });
export { RenderType_UploadCsvComponent as RenderType_UploadCsvComponent };
function View_UploadCsvComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section; _ck(_v, 1, 0, currVal_0); }); }
function View_UploadCsvComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal-header xt-model-fixed-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-md-2 pt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-left mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadCsvComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-md-8 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "mt-1 text-bold-400"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["Import ", " "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-md-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section; _ck(_v, 8, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.section; _ck(_v, 11, 0, currVal_1); }); }
function View_UploadCsvComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Import ", " with CSV file"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section; _ck(_v, 1, 0, currVal_0); }); }
function View_UploadCsvComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "xt-dialogue-btn text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-link mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.matchUploadData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Review Data "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "ft-chevron-right"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.formSubmitLoader ? _co.userSettingService.getSpinnerClass() : "fa fa-check-square-o"); _ck(_v, 4, 0, currVal_0); }); }
export function View_UploadCsvComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "form", [["id", "upload-csv-modal"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 4210688, null, 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadCsvComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 16, "div", [["class", "modal-body mt-5"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "mt-5": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 13, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 12, "div", [["class", "d-flex align-items-center justify-content-center pt-4 width-700"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 11, "div", [["class", "col-md-12 px-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadCsvComponent_3)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-file-upload", [], null, [[null, "filesAdded"], [null, "filesRemoved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesAdded" === en)) {
        var pd_0 = (_co.addArrayForAttachment($event) !== false);
        ad = (pd_0 && ad);
    } if (("filesRemoved" === en)) {
        var pd_1 = (_co.removeAttachment($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_FileUploadComponent_0, i4.RenderType_FileUploadComponent)), i1.ɵdid(19, 114688, null, 0, i5.FileUploadComponent, [], { existingAttachments: [0, "existingAttachments"] }, { filesAdded: "filesAdded", filesRemoved: "filesRemoved" }), (_l()(), i1.ɵeld(20, 0, null, null, 1, "p", [["class", "text-muted my-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["All ", " images will need to be uploaded manually when using this import feature. To do this navigate to the ", " item and select upload image. Images embedded in CSV files will be ignored."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadCsvComponent_4)), i1.ɵdid(23, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = !_co.useSmallModal; _ck(_v, 6, 0, currVal_7); var currVal_8 = "modal-body mt-5"; var currVal_9 = _ck(_v, 9, 0, !_co.useSmallModal); _ck(_v, 8, 0, currVal_8, currVal_9); var currVal_10 = _co.section; _ck(_v, 14, 0, currVal_10); var currVal_11 = _co.attachments; _ck(_v, 19, 0, currVal_11); var currVal_14 = (_co.form.value.attachments.length > 0); _ck(_v, 23, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_12 = _co.section; var currVal_13 = _co.section; _ck(_v, 21, 0, currVal_12, currVal_13); }); }
export function View_UploadCsvComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-csv", [], null, null, null, View_UploadCsvComponent_0, RenderType_UploadCsvComponent)), i1.ɵdid(1, 114688, null, 0, i6.UploadCsvComponent, [i7.NgbActiveModal, i7.NgbModal, i8.UserSettingService, i3.FormBuilder, i9.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadCsvComponentNgFactory = i1.ɵccf("app-upload-csv", i6.UploadCsvComponent, View_UploadCsvComponent_Host_0, {}, {}, []);
export { UploadCsvComponentNgFactory as UploadCsvComponentNgFactory };
