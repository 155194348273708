var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StripboardColorModel } from './stripboard-color.model';
import { SceneModel } from './scene.model';
import { BaseModel } from "./base.model";
import { StripboardModel } from './stripboard.model';
var StripboardColorSceneModel = /** @class */ (function (_super) {
    __extends(StripboardColorSceneModel, _super);
    function StripboardColorSceneModel(id, is_omitted, display_scene_number) {
        if (id === void 0) { id = null; }
        if (is_omitted === void 0) { is_omitted = null; }
        if (display_scene_number === void 0) { display_scene_number = null; }
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.is_omitted = is_omitted;
        _this.display_scene_number = display_scene_number;
        return _this;
    }
    StripboardColorSceneModel.prototype.makeObjectFromJson = function (result) {
        _super.prototype.makeObjectFromJson.call(this, result);
        //Scene
        if (result["scene"]) {
            this.scene = new SceneModel();
            this.scene.makeObjectFromJson(result["scene"]);
        }
        //Stripboard Color
        if (result["stripboard_color"]) {
            this.stripboard_color = new StripboardColorModel();
            this.stripboard_color.makeObjectFromJson(result["stripboard_color"]);
            if (result["stripboard_color"]["stripboard"]) {
                this.stripboard_color.stripboard = new StripboardModel();
                this.stripboard_color.stripboard.makeObjectFromJson(result["stripboard_color"]["stripboard"]);
            }
        }
    };
    return StripboardColorSceneModel;
}(BaseModel));
export { StripboardColorSceneModel };
