import { UserModel } from './../_models/user.model';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { RoleModel } from 'app/_models/role.model';
import { Observable } from 'rxjs';
import { WebsiteUserModel } from 'app/_models/website-user.model';

@Injectable()
export class RoleService {
    public memberTypes: any[] = [
        { id: "DepartmentMember", text: "Department Member" },
        { id: "DepartmentAdmin", text: "Department Admin" },
        { id: "ProductionAdmin", text: "Production Admin" }
    ];
    public permissions: any = [
        { 'id': 1, 'name': 'Stripboard', 'permission': false },
        { 'id': 2, 'name': 'Scene Profile Page', 'permission': false, 'parent_id': 1 },
        { 'id': 3, 'name': 'Continuity Tab', 'permission': false, 'parent_id': 2 },
        { 'id': 4, 'name': 'Order', 'permission': false },
        { 'id': 5, 'name': 'Inventory', 'permission': false },
        { 'id': 6, 'name': 'Characters', 'permission': false },
        { 'id': 7, 'name': 'Character Profile Page', 'permission': false, 'parent_id': 6 },
        { 'id': 8, 'name': 'Hero Props Tab', 'permission': false, 'parent_id': 7 },
        { 'id': 9, 'name': 'Changes Tab', 'permission': false, 'parent_id': 7 },
        { 'id': 10, 'name': 'Moodboard Tab', 'permission': false, 'parent_id': 9 },
        { 'id': 11, 'name': 'Drawings Tab', 'permission': false, 'parent_id': 9 },
        { 'id': 12, 'name': 'Fitting Images Tab', 'permission': false, 'parent_id': 9 },
        { 'id': 13, 'name': 'Items Tab', 'permission': false, 'parent_id': 9 },
        { 'id': 14, 'name': 'Continuity Tab', 'permission': false, 'parent_id': 9 },
        { 'id': 15, 'name': 'Looks Tab', 'permission': false, 'parent_id': 7 },
        { 'id': 16, 'name': 'Moodboard Tab', 'permission': false, 'parent_id': 15 },
        { 'id': 17, 'name': 'Drawings Tab', 'permission': false, 'parent_id': 15 },
        { 'id': 18, 'name': 'Fitting Images Tab', 'permission': false, 'parent_id': 15 },
        { 'id': 19, 'name': 'Sets', 'permission': false },
        { 'id': 20, 'name': 'Set Profile Page', 'permission': false, 'parent_id': 19 },
        { 'id': 21, 'name': 'Moodboard Tab', 'permission': false, 'parent_id': 20 },
        { 'id': 22, 'name': 'Drawings Tab', 'permission': false, 'parent_id': 20 },
        { 'id': 23, 'name': 'Images Tab', 'permission': false, 'parent_id': 20 },
        { 'id': 24, 'name': 'Items Tab', 'permission': false, 'parent_id': 20 },
        { 'id': 25, 'name': 'Continuity Tab', 'permission': false, 'parent_id': 20 },
        { 'id': 26, 'name': 'Look', 'permission': false, 'parent_id': 3 },
        { 'id': 27, 'name': 'Change', 'permission': false, 'parent_id': 3 },
        { 'id': 28, 'name': 'Set', 'permission': false, 'parent_id': 3 },
        { 'id': 29, 'name': 'Changes', 'permission': false, 'parent_id': 2 },
        { 'id': 30, 'name': 'Looks', 'permission': false, 'parent_id': 2 },
        { 'id': 31, 'name': 'Hero Props', 'permission': false, 'parent_id': 2 }
    ];
    constructor(public http: HttpClient) {
    }

    getDefault() {
        return this.http.get("role/get/default");
    }

    getAllRecords(): Observable<RoleModel[]> {
        return this.http.get('datatable/role')
            .map(
                (response: any) => {
                    return <RoleModel[]>response;
                }
            );
    }

    get(id: number): Observable<RoleModel> {
        return this.http.get('role/' + id)
            .map(
                (response: any) => {
                    return <RoleModel>response;
                }
            );
    }

    update(id: number, form: any) {
        return this.http.post('role/' + id, { role: form, _method: "PUT" });
    }

    store(form: any) {
        return this.http.post('role', form);
    }

    getUsers(type: string, id: number): Observable<WebsiteUserModel[]> {
        return this.http.post('role/custom/get/users', {
            type: type,
            id: id
        }).map(
            (response: any) => {
                return <WebsiteUserModel[]>response;
            }
        );
    }

    updatePermissions(id: number, form: any) {
        return this.http.post('role/custom/assign-permission/' + id, {
            permissions: form
        });
    }

    deleteDepartment(id: number) {
        return this.http.post('role/' + id, {
            _method: "DELETE"
        });
    }

    myDepartments() {
        return this.http.get('role/custom/my-departments')
            .map(
                (response: any) => {
                    return <RoleModel[]>response;
                }
            );
    }
}
