import { OrderAttachmentModel } from './order-attachment.model';
import { BaseModel } from './base.model';
import { ItemModel } from './item.model';
import { OrderDeliveryNoteModel } from './order-delivery-note.model';
import { AttachmentModel } from './attachment.model';
import { DepartmentModel } from './department.model';
import { VendorModel } from './vendor.model';

export enum OrderStatus {
  CheckedIn = 'CheckedIn',
  PartiallyCheckedIn = 'PartiallyCheckedIn',
  Returned = 'Returned',
  PartiallyReturned = 'PartiallyReturned',
  ReturnOverdue = 'ReturnOverdue',
  AwaitingDelivery = 'AwaitingDelivery',
  Deleted = 'Deleted',
}

export const orderStatusOptions = [
  {value: OrderStatus.CheckedIn, name: 'Checked In'},
  {value: OrderStatus.PartiallyCheckedIn, name: 'Partially Checked In'},
  {value: OrderStatus.Returned, name: 'Returned'},
  {value: OrderStatus.PartiallyReturned, name: 'Partially Returned'},
  {value: OrderStatus.ReturnOverdue, name: 'Return Overdue'},
  {value: OrderStatus.AwaitingDelivery, name: 'Awaiting Delivery'},
  {value: OrderStatus.Deleted, name: 'Deleted'},
]

export enum OrderMethods {
  PurchaseOrder = 'PurchaseOrder',
  PettyCash = 'PettyCash',
  CreditCard = 'CreditCard',
  FOC = 'FOC',
}

export enum OrderMethodsReadable {
  PurchaseOrder = 'Purchase Order',
  PettyCash = 'Petty Cash',
  CreditCard = 'Credit Card',
  FOC = 'FOC',
}

// ToDo: Standardise the object attributes required for the different drop downs and selects
export const orderMethodOptions = [
  {value: OrderMethods.PurchaseOrder, name: 'Purchase Order', id: OrderMethods.PurchaseOrder, text: 'Purchase Order'},
  {value: OrderMethods.PettyCash, name: 'Petty Cash', id: OrderMethods.PettyCash, text: 'Petty Cash'},
  {value: OrderMethods.CreditCard, name: 'Credit Card', id: OrderMethods.CreditCard, text: 'Credit Card'}
]

// ToDo: Standardise the object attributes required for the different drop downs and selects
export const orderTypeOptions = [
  {value: 'Purchase', name: 'Purchase'},
  {value: 'Rental', name: 'Rental'}
]

/**
 * The parent object for multi-vendor orders
 *
 */
export class MultiVendorOrderModel extends BaseModel {
  public department: DepartmentModel;
  // Attachments within the form
  public attachments: AttachmentModel[];
  // Attachments as returned by the API
  public multi_vendor_order_attachments: OrderAttachmentModel[];

  public orders: OrderModel[];

  constructor(
    public id: number = null,
    public doc_number: string = null,
    public ordered_by: string = null,
    public credit_card_id: string = null,
    public order_date: string = null,
    public delivery_date: string = null,
    public type: string = null,
    public currency_code: string = null,
    public total_amount: number = null,
    public department_id: string = null
  ) {
    super();
  }

  makeObjectFromJson(result) {
    super.makeObjectFromJson(result);

    // Attachments
    this.attachments = new Array();
    if (result['order_attachments']) {
      for (const json of result['order_attachments']) {
        if (json['attachment']) {
          const attachment = new AttachmentModel();
          attachment.makeObjectFromJson(json['attachment']);
          this.attachments.push(attachment);
        }
      }
    }

    // Department
    if (result['department']) {
      this.department = new DepartmentModel();
      this.department.makeObjectFromJson(result['department']);
    }
  }
}

export class OrderModel extends BaseModel {
    public items: ItemModel[];
    public vendors: VendorModel[];
    public order_delivery_notes: OrderDeliveryNoteModel[];
    public attachments: AttachmentModel[];
    public department: DepartmentModel;
    public order_attachments: OrderAttachmentModel[];

    constructor(
        public id: number = null,
        public multi_vendor_order_id: number = null,
        public production_id: number = null,
        public doc_number: string = null,
        public vendor: string = null,
        public vendor_reference_number: string = null,
        public order_by: string = null,
        public credit_card_id: string = null,
        public order_date: string = null,
        public delivery_date: string = null,
        public rental_start_date: string = null,
        public rental_return_date: string = null,
        public account_code: string = null,
        public additional_account_code: string = null,
        public type: string = null,
        public status: OrderStatus = null,
        public currency_code: string = null,
        public order_total: number = null,
        public additional_costs: number = null,
        public sub_total: number = null,
        public asset_type: string = null,
        public order_type: OrderMethods = null,
        public vat: number = null,
        public notes: string = null) {
        super();
    }

    makeObjectFromJson(result) {
        super.makeObjectFromJson(result);

        // Items
        this.items = new Array();
        if (result['items']) {
            for (const json of result['items']) {
                const item = new ItemModel();
                item.makeObjectFromJson(json);
                this.items.push(item);
            }
        }

        // Order delivery notes
        this.order_delivery_notes = new Array();
        if (result['order_delivery_notes']) {
            for (const json of result['order_delivery_notes']) {
                const record = new OrderDeliveryNoteModel();
                record.makeObjectFromJson(json);
                this.order_delivery_notes.push(record);
            }
        }

        // Attachments
        this.attachments = new Array();
        if (result['order_attachments']) {
            for (const json of result['order_attachments']) {
                if (json['attachment']) {
                    const attachment = new AttachmentModel();
                    attachment.makeObjectFromJson(json['attachment']);
                    this.attachments.push(attachment);
                }
            }
        }

        // Department
        if (result['department']) {
            this.department = new DepartmentModel();
            this.department.makeObjectFromJson(result['department']);
        }
    }
}
