<div class="modal-body" *ngIf="item">
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 left">
                    <div class="row">
                        <div class="col-md-12 edit-img text-center pr-0">
                            <img [src]="activeImage" class="img-responsive xt-fixed-img">
                            <span class="icon-left"><i class="ft-chevron-left white text-block-700"></i></span>
                            <span class="icon-right"><i class="ft-chevron-right white text-block-700"></i></span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-4 pt-2" *ngFor="let attachment of item.attachments; let i = index">
                            <div class="img"><img (click)="activeImage = attachment.file_path"
                                    [src]="attachment.thumbnail" class="img-thumbnail"></div>
                            <div class="overlay xt-show-overloay" [class.active]="attachment.is_selected === true">
                                <div class="float-left text-left ml-3 mt-4">
                                    <p-checkbox name="groupname" binary="true" [(ngModel)]="attachment.is_selected">
                                    </p-checkbox>
                                </div>
                                <div ngbDropdown placement="bottom-right" class="text-right ml-3 mt-3 mr-3 float-right">
                                    <button class=" btn btn-sm ml-1 mt-1 btn-outline-secondary" id="dropdownBasic1"
                                        ngbDropdownToggle><i class="ft-more-horizontal"></i></button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <!-- <button class="dropdown-item" type="button">Delete</button> -->
                                        <button class="dropdown-item" (click)="setThumbnail(attachment)">
                                            Set Thumbnail</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 pr-0">
                            <app-file-upload [hideImages]="true" (filesAdded)="addArrayForAttachment($event)">
                            </app-file-upload>
                        </div>
                    </div>

                    <!-- <div class="row mt-2">
                        <div class="col-md-2 pr-0 pt-4" *ngFor="let attachment of item.attachments">
                            <div class="card-img" style="text-align: center !important;">
                                <img class="img-fluid height-100" [src]="attachment.thumbnail"
                                    (click)="activeImage = attachment.file_path">
                                <div ngbDropdown placement="bottom-right" style="display: inline-block;">
                                    <button class="btn btn-sm btn-outline-secondary ml-1 mt-2" id="dropdownBasic1"
                                        ngbDropdownToggle><i class="ft-more-horizontal"></i></button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button class="dropdown-item" (click)="setThumbnail(attachment)">Set
                                            Thumbnail</button>
                                    </div>
                                </div>
                                <button class="btn btn-primary" type="button"
                                    (click)="attachment.is_selected = !attachment.is_selected">{{ (attachment.is_selected === true) ? 'Unselect' : 'Select' }}</button>
                            </div>
                        </div>
                        <div class="col-md-2 pr-0">
                            <app-file-upload [hideImages]="true" (filesAdded)="addArrayForAttachment($event)">
                            </app-file-upload>
                        </div>
                    </div> -->
                    <div class="row pt-2">
                        <div class="col-2">
                            <button type="button" class="btn btn-primary" *ngIf="showDeleteAll()"
                                (click)="deleteAttachments()">Delete</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">
                    <div>
                        <div class="row">
                            <div class="col-md-8">
                                <h2 [innerHTML]="item.name"></h2>
                                <p class="xt-large-book-intro" [innerHTML]="item.asset_number">

                                </p>
                            </div>
                            <div class="col-md-4 text-right">
                                <i class="ft-x font-large-2" (click)="activeModal.close('Close click')"></i>
                            </div>
                        </div>
                        <div class="xt-input mb-4">
                            <div class="border-bottom mb-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Department" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                                <input class="form-control border-0" placeholder="Size" type="text">
                                            </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                                <input class="form-control border-0" placeholder="Qty" type="text">
                                            </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-4">
                                            <fieldset class="form-group" *ngIf="item.role && item.role.name">
                                                    <input class="form-control border-0" placeholder="{{ item.role.name }}" type="text">
                                                </fieldset>
                                        </div>
                                    <div class="col-md-4">
                                            <fieldset class="form-group">
                                                    <input class="form-control border-0" placeholder="{{ item.size }}" type="text">
                                                </fieldset>
                                        </div>
                                    <div class="col-md-4">
                                            <fieldset class="form-group" *ngIf="item.order_id !== null">
                                                    <input class="form-control border-0" placeholder="{{ item.checked_in }}" type="text">
                                                </fieldset>
                                                <fieldset class="form-group" *ngIf="item.order_id === null">
                                                        <input class="form-control border-0" placeholder="{{ item.quantity }}" type="text">
                                                    </fieldset>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="xt-input mb-4">
                            <div class="border-bottom mb-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Storage Location" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Item Type" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Doc No. / Line No." type="text">
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.location }}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.item_type }}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.doc_number }} / {{ item.line_number }}" type="text">
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="xt-input mb-4">
                            <div class="border-bottom mb-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Vendor / Vendor Reference" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Asset Type" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Asset Number" type="text">
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.vendor }} / {{ item.vendor_reference}}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.asset_type }}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.asset_number }}" type="text">
                                        </fieldset>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="xt-input mb-4">
                            <div class="border-bottom mb-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Account Code" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Additional Account Code" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Status" type="text">
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.account_code }}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.additional_account_code }}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.inventory_status }}" type="text">
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="xt-input mb-4">
                            <div class="border-bottom mb-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Cost" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Notes" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="Tags" type="text">
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{ item.unit_cost_value | currency: item.unit_cost_currency }}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4">
                                        <fieldset class="form-group">
                                            <input class="form-control border-0" placeholder="{{item.note}}" type="text">
                                        </fieldset>
                                    </div>
                                    <div class="col-md-4 display-inline" *ngIf="item.tags">
                                        <a class="btn xt-tags-btn btn-sm display-inline" *ngFor="let tag of item.tags">
                                            {{ tag.tag }} <span class="ft-x" (click)="deleteTag(tag.id)"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="ui-g" style="width:250px;margin-bottom:10px">
                                    <div class="ui-g-12">
                                        <p-checkbox binary="true" label="Is Label Printed"
                                            [(ngModel)]="item.is_print_generated" (click)="toggleLabelPrint()">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary mr-2"
                            *ngIf="item.order_id !== null && (item.order_item_status === 'PartiallyCheckedIn' || item.order_item_status === 'CheckedIn')"
                            (click)="printLabels()">Print Labels</button>
                        <button type="button" class="btn mr-2 btn-primary"
                            (click)="queueService.addToQueue(this.item);activeModal.close()">Add to queue</button>
                        <button type="button" class="btn btn-red btn-raised"
                            (click)="deleteItemModal(item.id)">Delete</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
